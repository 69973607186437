import React, { useState } from 'react';
import { Image } from 'primereact/image';

import logo from '../../../assets/images/dental_batch_white_logo.png';
import instaIcon from '../../../assets/images/insta_icon.svg';
import fbIcon from '../../../assets/images/fb_icon.svg';
import linkedinIcon from '../../../assets/images/linkedin_icon.svg';
import tiktokIcon from '../../../assets/images/tiktok_icon.svg';
import twitterIcon from '../../../assets/images/twitter1.png';
import Pricing from '../../dialog/pricing/Pricing';
import { Link } from 'react-router-dom';

const FooterSection = (props) => {
    const [visiblePricing, setVisiblePricing] = useState(false);

    return (
        <>
            <div className="layout-footer pb-5">
                <div className="wrapper flex flex-wrap" style={{ background: '#121212' }}>
                    <div className="col-12 sm:col-12 lg:col-7 md:col-7">
                        <div className="flex flex-column align-items-start mt-8 ml-2 md:ml-3 md:text-center">
                            <Image src={logo} alt="Logo" />
                            <div className="footer-plateform-text mt-4">The Modern Job Search Platform</div>

                            <div className="flex justify-content-start gap-4 mt-6 mb-5">
                                <a href="https://www.instagram.com/joinbatchusa/" target="_blank">
                                    <Image src={instaIcon} alt="Logo" />
                                </a>
                                <a href="https://www.facebook.com/joinbatchusa" target="_blank">
                                    <Image src={fbIcon} alt="Logo" />
                                </a>
                                <a href="https://www.linkedin.com/company/joinbatch" target="_blank">
                                    <Image src={linkedinIcon} alt="Logo" />
                                </a>
                                <a href="https://www.tiktok.com/@joinbatchusa?_t=8go5eBrJTad&_r=1" target="_blank">
                                    <Image src={tiktokIcon} alt="Logo" />
                                </a>
                                <a href="https://twitter.com/joinbatchusa" target="_blank">
                                    <Image src={twitterIcon} alt="Logo" imageStyle={{ maxWidth: '27px' }} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 sm:col-12 lg:col-5 md:col-5">
                        <div className="flex flex-column sm:flex-row md:mt-8">
                            <div className="col-6 sm:col-6 lg:col-6 md:col-6">
                                <div className="footer-section-title mt-3">Services</div>
                                <div className="footer-plateform-text mt-4 cursor-pointer" onClick={() => setVisiblePricing(true)}>
                                    Pricing
                                </div>
                                <div className="footer-plateform-text mt-3">What people are saying</div>
                            </div>

                            <div className="col-6 sm:col-6 lg:col-6 md:col-6 sm:text-right md:text-left">
                                <div className="footer-section-title mt-3">About Us</div>
                                <div className="footer-plateform-text mt-4">Our Mission</div>
                                <div className="footer-plateform-text mt-3">Our Team</div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-content-between align-item-center" style={{ maxWidth: '1250px', width: '100%' }}>
                        <div className="foooter-text ml-3 mt-2">© 2023 Batch. All Right Reserved.</div>
                        <div className="foooter-text ml-3 mt-2">
                            <Link to="/privacy">Privacy Policy</Link> / <Link to="/terms">Batch Terms</Link>
                        </div>
                    </div>
                </div>
            </div>

            <Pricing visible={visiblePricing} setVisible={setVisiblePricing} setPriceLogin={props?.setPriceLogin} />
        </>
    );
};

export default FooterSection;
