import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './header.css';
import editIcon from '../../../assets/images/edit_icon_1.svg';
import SidebarComponent from '../sidebar/Sidebar';
import { Password } from 'primereact/password';

import { Sidebar } from 'primereact/sidebar';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Menu } from 'primereact/menu';

import logo from '../../../assets/images/dental_batch_blue_logo.png';
import employer from '../../../assets/images/tooth.png';
import candidate from '../../../assets/images/batch_avatar.png';

import defaultUser from '../../../assets/images/default_user_image.svg';
import profileIcon from '../../../assets/images/profile_drop_down_icon.svg';
import { BASE_URL, getLoggedUser, getUserToken, imageDisplay, sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';
import { ToastContext } from '../../../context/ToastContext';
import { Dialog } from 'primereact/dialog';

const Header = ({}) => {
    const menuLeft = useRef(null);
    const [visible, setVisible] = useState(false);
    const [profileChange, setProfileChange] = useState(false);
    const userProfile = getLoggedUser();
    const toast = useContext(ToastContext);
    const userToken = getUserToken();
    const [value, setValue] = useState(userProfile?.name);

    const navigate = useNavigate();
    const [password, setpassword] = useState('');
    const [newPassword, setNewpassword] = useState('');

    const items = [
        {
            label: 'Settings',
            command: () => {
                navigate('/user/settings');
            }
        },
        {
            label: 'Profile',
            command: () => {
                if (userProfile?.role == 'candidate') navigate('/candidate/profile');
                else navigate('/employer/profile');
            }
        },
        {
            label: 'Logout',
            command: () => {
                navigate('/employer/logout');
            }
        }
    ];

    const updateProfile = async () => {
        let userDate = {};
        const data = {};
        if (value !== userProfile?.name) userDate.name = value;
        if (password !== '') data.oldPassword = password;
        if (newPassword !== '') {
            data.password = newPassword;
            data.passType = 'update';
        }
        if (data.password && data.oldPassword) {
            let response = await sendRequest(`${BASE_URL}api/v1/user/updatePassword`, {
                method: 'post',
                payload: data,
                headers: {
                    'x-access-token': userToken
                }
            });
            if (response.status === 200) {
                setValue(value);
                if (value === userProfile?.name) showSuccessToast(toast, 'Success', response?.data?.desc);
                setProfileChange(false);
            } else showErrorToast(toast, 'Error', response?.data?.desc);
        }

        let response = await sendRequest(`${BASE_URL}api/v1/user/updateProfile`, {
            method: 'post',
            payload: userDate,
            headers: {
                'x-access-token': userToken
            }
        });

        if (response.status === 200) {
            if (value !== userProfile?.name) showSuccessToast(toast, 'Success', response?.data?.desc);
            setValue(response?.data?.name);
            setProfileChange(false);
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    return (
        <header className="layout-topbar">
            <div className="grid w-full align-items-center justify-content-between">
                <div className="col-0 sm:col-3 md:col-2 lg:col-3 xl:col-2 logo-container">
                    <div className="text-center">
                        <Image src={logo} alt="Logo" className="app-header-logo" />
                    </div>
                </div>
                <div className="col-6 sm:col-5 md:col-4 lg:col-5 xl:col-6">
                    <div className="flex justify-content-start align-items-center">
                        <div className="flex justify-content-center">
                            <Sidebar visible={visible} onHide={() => setVisible(false)}>
                                <div className="flex justify-content-between align-items-center mt-2">
                                    <div>
                                        <Image src={logo} alt="Logo" />
                                    </div>
                                    <div
                                        onClick={() => {
                                            setVisible(false);
                                        }}
                                    >
                                        <i className="pi pi-times"></i>
                                    </div>
                                </div>

                                <SidebarComponent />
                            </Sidebar>
                            <Button type="button" className="p-link layout-topbar-menu-button layout-topbar-button side-bar-icon" onClick={() => setVisible(true)}>
                                <i className="pi pi-bars"></i>
                            </Button>
                        </div>
                        <div className="user-heading ml-3 lg:ml-5 xl:ml-3">
                            {userProfile?.role === 'recruiter' ? (
                                <>
                                    <span className="p-input-icon-right">
                                        <InputText className="border-0" value={value} />
                                    </span>
                                </>
                            ) : (
                                userProfile?.name || 'Hello Matt'
                            )}

                            {userProfile?.role === 'recruiter' && (
                                <Image src={editIcon} onClick={() => setProfileChange(true)} alt="Logo" className="headedit pt-2 mr-3 cursor-pointer" />
                            )}
                            {userProfile?.role === 'employer' && (
                                <span className="dashboard-user-text" style={{ color: '#7d43ff' }}>
                                    ( Admin )
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-6 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                    <div className="flex justify-content-end gap-3 align-items-center lg:pr-3 xl:pr-4 ">
                        {userProfile?.role !== 'admin' && userProfile?.role !== 'recruiter' && (
                            <>
                                <Image
                                    src={
                                        userProfile?.role === 'candidate' && userProfile?.profileImage
                                            ? imageDisplay(userProfile?.profileImage)
                                            : userProfile?.role === 'candidate' && !userProfile?.profileImage
                                            ? candidate
                                            : userProfile?.role === 'employer' && userProfile?.preferences?.employer?.companyLogo
                                            ? imageDisplay(userProfile?.preferences?.employer?.companyLogo)
                                            : employer
                                    }
                                    className="default-user-image"
                                    alt="user"
                                    width="48px"
                                    height="48px"
                                />

                                <Image src={profileIcon} onClick={(event) => menuLeft.current.toggle(event)} className="profile-drop-down" alt="user" />
                                <Menu model={items} popup ref={menuLeft} id="popup_menu_left" className="profile-menu" />
                            </>
                        )}
                    </div>
                </div>
                <Dialog visible={profileChange} style={{ width: '50vw' }} modal={false} className="dialog-box signup-view" onHide={() => setProfileChange(false)}>
                    <div className="p-5 pt-0">
                        <>
                            <h2 className="pending-candidates-heading-card m-0 mb-3">Account Settings</h2>
                            <div className="single-candidate-container py-3 px-3">
                                <div className="flex flex-column w-12 mt-2 mb-3">
                                    <label htmlFor="name" className="setting-label mb-2">
                                        Name
                                    </label>
                                    <InputText
                                        id="name"
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                        placeholder="name"
                                        inputClassName="employee-signup-input border-0 w-full"
                                    />
                                </div>

                                <div className="flex flex-column w-12 mt-2 mb-3">
                                    <label htmlFor="email" className="setting-label mb-2">
                                        Email
                                    </label>
                                    <InputText
                                        readOnly
                                        id="email"
                                        value={userProfile?.email}
                                        placeholder="email"
                                        inputClassName="employee-signup-input border-0 w-full"
                                    />
                                </div>
                                <div className="flex flex-column w-12 mt-2">
                                    <label htmlFor="password" className="setting-label mb-2">
                                        Old Password
                                    </label>
                                    <Password
                                        id="password"
                                        value={password}
                                        onChange={(e) => setpassword(e.target.value)}
                                        placeholder="*********"
                                        promptLabel="password"
                                        inputClassName="employee-signup-input border-0 w-full"
                                        feedback={false}
                                        toggleMask
                                    />
                                </div>
                                <div className="flex flex-column w-12 mt-3">
                                    <label htmlFor="password" className="setting-label mb-2">
                                        New Password
                                    </label>
                                    <Password
                                        id="password"
                                        value={newPassword}
                                        onChange={(e) => setNewpassword(e.target.value)}
                                        placeholder="*********"
                                        promptLabel="password"
                                        weakLabel="Too simple"
                                        mediumLabel="Average complexity"
                                        strongLabel="Complex password"
                                        inputClassName="employee-signup-input border-0 w-full"
                                        feedback={false}
                                        toggleMask
                                    />
                                </div>

                                <Button type="button" className="onboarding-btn w-max px-6 my-4" onClick={() => updateProfile()}>
                                    Update
                                </Button>
                            </div>
                        </>
                    </div>
                </Dialog>
            </div>
        </header>
    );
};
export default Header;
