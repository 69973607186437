import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID || 'G-FX0RWFZC40';

const PageViewTracker = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', GOOGLE_ANALYTICS_MEASUREMENT_ID, {
                page_path: location.pathname + location.search
            });
        }
    }, [location]);

    return null;
};

export default PageViewTracker;
