import React, { useState, useEffect } from 'react';

const EmployerOnboardingScreenFive = ({ employerData, setEmployerData }) => {
    const [selectedOfficeSetting, setselectedOfficeSetting] = useState({});
    const [selectedOfficeSpecialities, setselectedOfficeSpecialities] = useState([]);

    const officeSetting = ['Corporate', 'Private', 'Family'];
    const officeSpecialities = ['General', 'Pediatric', 'Orthodontic', 'Cosmetic', 'Periodontal', 'Endodontic', 'Oral & Maxillofacial Surgery', 'Prosthodontic'];

    const specialitiesHandler = (speciality) => {
        const updateSelectedOfficeSpecialities = selectedOfficeSpecialities.find((el) => el === speciality);
        const selectedOfficeSpecialitiesNames = selectedOfficeSpecialities.map((el) => el);

        if (updateSelectedOfficeSpecialities) {
            const selectedOfficeSpecialitiesfilter = selectedOfficeSpecialities.filter((el) => el !== speciality);
            const selectedOfficeSpecialitiesfilterNames = selectedOfficeSpecialitiesfilter.map((el) => el);
            setselectedOfficeSpecialities(selectedOfficeSpecialitiesfilter);
            setEmployerData({ ...employerData, officeSpecialties: selectedOfficeSpecialitiesfilterNames });
        } else {
            setselectedOfficeSpecialities([...selectedOfficeSpecialities, speciality]);
            setEmployerData({ ...employerData, officeSpecialties: [...selectedOfficeSpecialitiesNames, speciality] });
        }
    };

    const checkSpecialities = (name) => {
        const indexOfEmployee = selectedOfficeSpecialities.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (employerData.bestDescribeOffice && employerData.officeSpecialties) {
            setselectedOfficeSpecialities(employerData.officeSpecialties);
            setselectedOfficeSetting({ setting: employerData.bestDescribeOffice });
        }
    }, []);

    return (
        <div>
            <p className="employer-questions-heading mt-5">Which of these best describes your office setting?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {officeSetting?.map((setting, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 ${
                            selectedOfficeSetting.setting === setting ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                        }`}
                        onClick={() => {
                            setselectedOfficeSetting({ setting });
                            setEmployerData({ ...employerData, bestDescribeOffice: setting });
                        }}
                    >
                        <div className={`${selectedOfficeSetting.setting === setting ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {setting}
                        </div>
                    </div>
                ))}
            </div>

            <p className="employer-questions-heading mt-5">Which of these specialties best describes your office?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {officeSpecialities?.map((speciality, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3  ${
                            checkSpecialities(speciality) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                        }`}
                        onClick={() => specialitiesHandler(speciality)}
                    >
                        <div className={`${checkSpecialities(speciality) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {speciality}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmployerOnboardingScreenFive;
