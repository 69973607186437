import React, { useState, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';

const EmployerOnboardingScreenSix = ({ employerData, setEmployerData }) => {
    const [companyUrl, setcompanyUrl] = useState('');

    useEffect(() => {
        if (employerData.companyUrl) {
            setcompanyUrl(employerData.companyUrl);
        }
    }, []);
    return (
        <div>
            <p className="employer-questions-heading mt-5">Enter your company’s webpage?</p>
            <InputText
                placeholder="https://www."
                className="border-0 add-button-container-input w-full mt-3 mb-3"
                value={companyUrl}
                onChange={(e) => {
                    setcompanyUrl(e.target.value);
                    setEmployerData({ ...employerData, companyUrl: e.target.value });
                }}
            />
        </div>
    );
};

export default EmployerOnboardingScreenSix;
