import React from 'react';
import { Image } from 'primereact/image';
import location from '../../assets/images/location_icon_2.svg';
import { imageDisplay } from '../../helpers/utils';

const RecentJobsCard = (props) => {
    return (
        <div className="flex flex-column p-4 my-2 w-full job-card">
            <Image className="m-1 w-full full-img" src={props?.officeImage} alt="Logo" />
            <div className="grid mt-2">
                <div className="col-2">
                    <Image src={props?.profileImage} className="job-card-profile-image" alt="Logo" width="52px" height="52px" imageStyle={{ borderRadius: '38px' }} />
                </div>
                <div className="col-10">
                    <div className="">
                        <h1 className="job-card-city-heading mt-0">{props?.jobIndustry}</h1>
                        <h2 className="job-card-title-heading mt-2">
                            <span className="mr-2">
                                <Image src={location} alt="Logo" />
                            </span>
                            {props?.address}
                        </h2>
                        <h2 className="job-card-salary w-max mt-3 text-center"> {props?.salary}</h2>
                    </div>
                    <div className="flex justify-content-between mt-3">
                        <div className="job-card-last-online ">{props?.lastActive}</div>
                        <div className="job-card-response ">{props?.response}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentJobsCard;
