import React, { useEffect, useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

import { ToastContext } from '../../../context/ToastContext';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';

import crossIcon from '../../../assets/images/cross_black_icon.svg';

const EditJobSkills = ({ visible, setVisible, jobData, setJobData, jobId, setJobDetail }) => {
    const [selectedSkills, setselectedSkills] = useState([]);
    const [skills, setSkills] = useState([]);

    const positionSkills = {
        'Front Desk': [
            'Greet and welcome patients',
            'Assist patients with filling out forms',
            'Prepare patient charts and daily schedules',
            'Answer phones, photocopy, filing, faxing',
            'Insurance Billing/Verification',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Order Dental/Office Supplies',
            'Respond to Emails',
            'Manage Collections',
            'Sterilize and Disinfect Dental instruments',
            'Set Up Room and Break Down Patient Rooms'
        ],
        'Administrative Assistant': [
            'Greet and welcome patients',
            'Assist patients with filling out forms',
            'Prepare patient charts and daily schedules',
            'Answer phones, photocopy, filing, faxing',
            'Insurance Billing/Verification',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Order Dental/Office Supplies',
            'Respond to Emails',
            'Manage Collections',
            'Sterilize and Disinfect Dental instruments',
            'Set Up Room and Break Down Patient Rooms'
        ],
        'Treatment Coordinator': [
            'Greet and welcome patients',
            'Assist patients with filling out forms',
            'Prepare patient charts and daily schedules',
            'Answer phones, photocopy, filing, faxing',
            'Insurance Billing/Verification',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Order Dental/Office Supplies',
            'Respond to Emails',
            'Manage Collections',
            'Sterilize and Disinfect Dental instruments',
            'Set Up Room and Break Down Patient Rooms'
        ],
        Receptionist: [
            'Greet and welcome patients',
            'Assist patients with filling out forms',
            'Prepare patient charts and daily schedules',
            'Answer phones, photocopy, filing, faxing',
            'Insurance Billing/Verification',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Order Dental/Office Supplies',
            'Respond to Emails',
            'Manage Collections',
            'Sterilize and Disinfect Dental instruments',
            'Set Up Room and Break Down Patient Rooms'
        ],
        'Billing Coordinator': [
            'Insurance Billing/Verification',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Respond to patient emails',
            'Following up on non-paid insurance claims',
            'Sterilize and Disinfect Dental instruments',
            'Set Up Room and Break Down Room patient rooms',
            'Explaining insurance benefits to patients',
            'Posting payments made by patients',
            'Managing late payments and overdue accounts',
            'Processing insurance payments and refunds',
            'Uploading completed insurance verification forms',
            'Answer phones, photocopy, filing, faxing'
        ],
        'Office Manager': [
            'Insurance Billing/Verification',
            'Manage office payroll',
            'General Marketing',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Order Dental/Office supplies',
            'Respond to emails',
            'Following up on non-paid insurance claims',
            'Sterilize and Disinfect Dental instruments',
            'Set Up and Break Down Patient Rooms',
            'Explaining insurance benefits to patients',
            'Generating reports for all activities',
            'Posting payments made by patients',
            'Managing late payments and overdue accounts',
            'Processing insurance payments and refunds',
            'Upload completed insurance verification forms',
            'Staff Employees',
            'Recruit and Interview potential candidates',
            'Manage all internal office procedures and practices',
            'Maintain staff schedules',
            'Maintain general ledger',
            'Maintain billing system'
        ],
        'Marketing Coordinator': [
            'Perform competitive analysis and market research to identify industry trends',
            'Create marketing materials and content',
            'Drive social media strategy',
            'Determine and manage advertising budget',
            'Manage marketing campaigns',
            'Ensure brand compliance across internal and external stakeholders',
            'Familiarity with hubspot or similar marketing softwares',
            'Schedule and attend relevant community events and trade shows',
            'Grow the patient base',
            'Create digital marketing ads',
            'Familiar with SEO/SEM',
            'Track Marketing Budget monthly',
            'Track marketing pieces and effectiveness',
            'Website Managment',
            'A/B testing various marketing campaigns',
            'Manage marketing staff',
            'Present and sell treatment plans',
            ' Collect and post payments'
        ],
        'Marketing Manager': [
            'Perform competitive analysis and market research to identify industry trends',
            'Create marketing materials and content',
            'Drive social media strategy',
            'Determine and manage advertising budget',
            'Manage marketing campaigns',
            'Ensure brand compliance across internal and external stakeholders',
            'Familiarity with hubspot or similar marketing softwares',
            'Schedule and attend relevant community events and trade shows',
            'Grow the patient base',
            'Create digital marketing ads',
            'Familiar with SEO/SEM',
            'Track Marketing Budget monthly',
            'Track marketing pieces and effectiveness',
            'Website Managment',
            'A/B testing various marketing campaigns',
            'Manage marketing staff',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        'Marketing Specialist': [
            'Perform competitive analysis and market research to identify industry trends',
            'Create marketing materials and content',
            'Drive social media strategy',
            'Determine and manage advertising budget',
            'Manage marketing campaigns',
            'Ensure brand compliance across internal and external stakeholders',
            'Familiarity with hubspot or similar marketing softwares',
            'Schedule and attend relevant community events and trade shows',
            'Grow the patient base',
            'Create digital marketing ads',
            'Familiar with SEO/SEM',
            'Track Marketing Budget monthly',
            'Track marketing pieces and effectiveness',
            'Website Managment',
            'A/B testing various marketing campaigns',
            'Manage marketing staff',
            'Present and sell treatment plans',
            ' Collect and post payments'
        ],
        'Orthodontic Assistant': [
            'Administer X-Rays',
            'Photograph patients’ teeth',
            'Take Dental molds of patients’ teeth for fabrication of customized mouthguards and retainers',
            'Remove and replace wires, coil springs, donuts, and elastic bands',
            'Record treatment information in patient records',
            'Insurance Billing/Verifcation',
            'Chairside Assistance',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Order Dental/Office Supplies',
            'Respond to patient emails',
            'Manage collections',
            'Sterilize and Disinfect Dental instruments',
            'Coronal Polishing',
            'Set Up and Break Down Patient Rooms',
            'Patient Education',
            'Ultrasonic Scaling',
            'Dental Prophylaxis',
            'Four-Handed Dentistry'
        ],
        'Dental Assistant': [
            'Prepare patients for dental work',
            'Set up instrument trays, prepare materials, and assist with dental procedures',
            'Record treatment information in patient records',
            'Assist dental hygienists with procedures',
            'Administer X-Rays',
            'Collect and record medical and dental histories and patient vital signs',
            'Provide postoperative instructions as directed by the dentist',
            'Form preliminary impressions for study casts and occlusal registrations for mounting study casts',
            'Fabricate temporary restorations and custom impressions from preliminary impressions',
            'Insurance Billing/Verification',
            'Chairside Assistance',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Order Dental/Office Supplies',
            'Photograph patients’ teeth',
            'Respond to patient emails',
            'Manage collections',
            'Sterilize and Disinfect Dental instruments',
            'Coronal Polishing',
            'Set Up and Break Down Patient Rooms',
            'Patient Education',
            'Ultrasonic Scaling',
            'Dental Prophylaxis',
            'Four-Handed Dentistry'
        ],
        'Registered Dental Assistant': [
            'Prepare patients for dental work',
            'Set up instrument trays, prepare materials, and assist with dental procedures',
            'Record treatment information in patient records',
            'Assist dental hygienists with procedures',
            'Administer X-Rays',
            'Collect and record medical and dental histories and patient vital signs',
            'Provide postoperative instructions as directed by the dentist',
            'Form preliminary impressions for study casts and occlusal registrations for mounting study casts',
            'Fabricate temporary restorations and custom impressions from preliminary impressions',
            'Insurance Billing/Verification',
            'Chairside Assistance',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Order Dental/Office Supplies',
            'Photograph patients’ teeth',
            'Respond to patient emails',
            'Manage collections',
            'Sterilize and Disinfect Dental instruments',
            'Coronal Polishing',
            'Set Up and Break Down Patient Rooms',
            'Patient Education',
            'Ultrasonic Scaling',
            'Dental Prophylaxis',
            'Four-Handed Dentistry'
        ],
        'Dental Hygienist': [
            'Clean accretions, calcareous deposits, and stains from teeth and beneath margins of gums',
            'Look for indications of oral cancer',
            'Note and review patient medical history',
            'Examine and locate periodontal recessed gums and other signs of gum disease',
            'Record dental decay and disease for diagnosis and treatment by dentists',
            'Administer X-Rays',
            'Examine gums for signs of disease, sores, and other dental health issues',
            'Maintain a dental hygiene program for patients',
            'Sterilize and Disinfect Dental instruments',
            'Apply fluorides and other dental decay prevention agents',
            'Root Planning',
            'Prepare fillings',
            'Patient Education',
            'Set up and Break down of patient rooms',
            'Photograph patients’ teeth',
            'Insurance Billing/Verification',
            'Ultrasonic Scaling',
            'Dental Prophylaxis',
            'Four-Handed Dentistry'
        ]
    };

    const skillsHandler = (skill) => {
        const updateSkills = selectedSkills.find((el) => el === skill);
        if (updateSkills) {
            const selectedSkillsfilter = selectedSkills.filter((el) => el !== skill);

            setselectedSkills(selectedSkillsfilter);
            const updateSkills = selectedSkillsfilter.map((el) => el);
            setJobData({ ...jobData, skills: updateSkills });
        } else {
            setselectedSkills([...selectedSkills, skill]);
            setJobData({ ...jobData, skills: [...jobData?.skills, skill] });
        }
    };

    const checkskills = (name) => {
        const indexOfEmployee = selectedSkills.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    const toast = useContext(ToastContext);
    const userToken = getUserToken();

    const jobInfoUpdate = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/job/update/${jobId}`, {
            method: 'post',
            payload: jobData,
            headers: {
                'x-access-token': userToken
            }
        });
        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            setJobDetail(response.data.data || []);
            setVisible(false);
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    const skillsAdded = (position) => {
        if (position in positionSkills) {
            const responsibilities = positionSkills[position];
            setSkills(responsibilities);
        }
    };

    useEffect(() => {
        setselectedSkills([...jobData.skills]);
        skillsAdded(jobData?.position);
    }, [jobData.skills]);

    return (
        <Dialog visible={visible} position={'center'} className="dental-tools-edit" onHide={() => setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Skills</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => setVisible(false)}
                    />
                </div>

                <p className="employer-questions-heading mt-5">Candidates “must-have” these skills in order to meet the minimum qualifications for this job</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {skills?.length &&
                        skills?.map((skill, index) => (
                            <div
                                key={index}
                                className={`flex justify-content-center align-items-center gap-3 ${
                                    checkskills(skill) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                                }`}
                                onClick={() => skillsHandler(skill)}
                            >
                                <div className={`${checkskills(skill) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>{skill}</div>
                            </div>
                        ))}
                </div>

                <hr className="line-horizontal mt-5" />

                <Button type="button" className="onboarding-btn w-max px-6 mt-5" onClick={jobInfoUpdate}>
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditJobSkills;
