import React, { useEffect, useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';

import { ToastContext } from '../../../context/ToastContext';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';

import crossIcon from '../../../assets/images/cross_black_icon.svg';
import deleteIcon from '../../../assets/images/delete_icon.svg';

const EditJobInterviewProcess = ({ visible, setVisible, jobData, setJobData, jobId, setJobDetail }) => {
    const [selectedStages, setSelectedStages] = useState([]);
    const [addMoreStatus, setaddMoreStatus] = useState(false);
    const [process, setprocess] = useState();

    const [newInterviewStages, setNewInterviewStages] = useState(['Phone Interview', 'Video Interview', 'Group Interview', 'Onsite Interview', 'Working Interview']);
    const [stages, setstages] = useState(['Stage 1']);

    const toast = useContext(ToastContext);
    const userToken = getUserToken();

    const jobInfoUpdate = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/job/update/${jobId}`, {
            method: 'post',
            payload: jobData,
            headers: {
                'x-access-token': userToken
            }
        });
        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            setJobDetail(response.data.data || []);
            setVisible(false);
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    const handleRadioChange = (value, index) => {
        if (jobData.interviewProcess.length === index) {
            setSelectedStages([...selectedStages, value]);
            setJobData({ ...jobData, interviewProcess: [...jobData.interviewProcess, value] });
        } else {
            let updateValue = jobData.interviewProcess;
            updateValue[index] = value;

            let updateStage = selectedStages;
            updateStage[index] = value;

            setSelectedStages(updateStage);
            setJobData({ ...jobData, interviewProcess: updateValue });
        }
    };

    const isProcessChecked = (value, index) => {
        return selectedStages[index] === value;
    };

    const handleDelete = (index) => {
        let updateStage = stages;
        updateStage.splice(index, 1);
        setstages(updateStage);

        let updateSelectedStage = selectedStages;
        updateSelectedStage.splice(index, 1);
        setSelectedStages(updateSelectedStage);

        setJobData({ ...jobData, interviewProcess: updateSelectedStage });
    };

    useEffect(() => {
        if (jobData.interviewProcess.length > 0) {
            setSelectedStages(jobData.interviewProcess);
            const newStages = [];
            jobData?.interviewProcess?.forEach((element, index) => {
                newStages.push(`Stage ${index + 1}`);
            });
            setstages(newStages);
        }
    }, []);

    return (
        <Dialog visible={visible} position={'center'} className="dental-tools-edit" onHide={() => setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Interview Process</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => setVisible(false)}
                    />
                </div>

                <p className="employer-questions-heading mt-5">What is your interview process?</p>

                <div
                    style={{
                        background: '#FFFFFF',
                        borderRadius: '6px'
                    }}
                    className="mt-5"
                >
                    {stages.map((stage, index1) => (
                        <>
                            <div className="flex justify-content-between mt-1 mx-4">
                                <h4 className="employer-questions-heading " style={{ fontSize: '1rem' }}>
                                    {stage}
                                </h4>
                                {index1 > 0 && (
                                    <Image
                                        src={deleteIcon}
                                        alt="cross icon"
                                        width="25px"
                                        height="25px"
                                        className="cursor-pointer flex justify-content-end"
                                        style={{ padding: '10px' }}
                                        onClick={() => {
                                            handleDelete(index1);
                                        }}
                                    />
                                )}
                            </div>

                            <div
                                className="flex flex-wrap justify-content-start align-items-center gap-3 mb-4 mx-4 p-3"
                                style={{
                                    background: '#F7FAFC',
                                    borderRadius: '5px'
                                }}
                            >
                                {newInterviewStages.map((stage, index) => (
                                    <div key={index} className={`flex justify-content-center align-items-center gap-2 cursor-pointer`}>
                                        <Checkbox
                                            className="interview-stage-checkbox"
                                            onChange={() => handleRadioChange(stage, index1)}
                                            checked={isProcessChecked(stage, index1)}
                                        />
                                        <div className={'available-questions-options-text'}>{stage}</div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ))}

                    <div className="flex justify-content-end mt-1 mx-4">
                        <h4 className="add-more-text cursor-pointer" style={{ fontSize: '1rem' }} onClick={() => setstages([...stages, `Stage ${stages.length + 1}`])}>
                            Add More Stages
                        </h4>
                    </div>
                </div>

                <hr className="line-horizontal mt-5" />

                <Button type="button" className="onboarding-btn w-max px-6 mt-5" onClick={jobInfoUpdate}>
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditJobInterviewProcess;
