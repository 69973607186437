import React, { useState } from 'react';
import './dashboard-container.css';
import { Outlet } from 'react-router-dom';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';

function DashboardContainer() {
    const [sidebarToggle, setsidebarToggle] = useState(false);

    const sidebarHandler = () => {
        setsidebarToggle((wasOpened) => !wasOpened);
    };

    return (
        <div className={`layout-wrapper layout-static ${sidebarToggle ? 'layout-static-inactive layout-mobile-active' : ''}`}>
            <Header sidebarHandler={sidebarHandler} />
            <Sidebar setsidebarToggle={setsidebarToggle} />
            <div className="layout-mask" onClick={sidebarHandler}></div>
            <div className="layout-main-container">
                <Outlet />
            </div>
        </div>
    );
}

export default DashboardContainer;
