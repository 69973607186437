import React, { useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

import crossIcon from '../../../assets/images/cross_black_icon.svg';
import defaultLogo from '../../../assets/images/tooth.png';
import { BASE_URL, getUserToken, imageDisplay, sendRequest } from '../../../helpers/utils';
import { ToastContext } from '../../../context/ToastContext';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const EditEmployerBasic = (props) => {
    const [file, setFile] = useState(null);
    const [logoImage, setlogoImage] = useState('');
    const [fileName, setFileName] = useState('');
    const toast = useContext(ToastContext);
    const locations = useLocation();
    const userToken = getUserToken();

    const onFileUpload = (event) => {
        setFile(event.files[0]);
        setFileName(event.files[0].name);
    };

    const updateProfile = async () => {
        if (props?.location == '' || props?.companyName == '') {
            showErrorToast(toast, 'Error', 'Company name & Location is required');
        }
        const formDataToSend = new FormData();

        formDataToSend.append('files', file);

        // Make the Axios POST request
        const profileImageResponse = await axios.post(`${BASE_URL}api/v1/media/upload`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const data = {
            name: props?.companyName,
            preferences: {
                employer: {
                    companyLogo: profileImageResponse?.data?.data[0]?.path,
                    officeAddresses: [props?.location],
                    socialAccounts: props?.socialLinks
                }
            },
            ...(locations?.state?.employerId ? { updateUser: locations?.state?.employerId } : {})
        };

        let response = await sendRequest(`${BASE_URL}api/v1/user/updateProfile`, {
            method: 'post',
            payload: data,
            headers: {
                'x-access-token': userToken
            }
        });

        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            props?.userProfile();
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };
    return (
        <Dialog visible={props?.visible} position={'center'} className="dental-tools-edit" onHide={() => props?.setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Employer</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => props?.setVisible(false)}
                    />
                </div>
                <p className="employer-questions-heading mt-5 text-center">Change company logo</p>

                <div className="flex justify-content-center">
                    <div className="flex align-item-center justify-content-start flex-column mt-3 mb-2">
                        <Image src={logoImage ? logoImage : props?.logo ? imageDisplay(props?.logo) : defaultLogo} alt="Logo" className="employee-company-logo" />

                        <FileUpload
                            name="demo"
                            mode="basic"
                            chooseLabel="Upload Photo"
                            uploadLabel="Upload"
                            cancelLabel="Cancel"
                            className={classNames({ 'p-mr-2 mt-3': true })}
                            onSelect={(e) => {
                                onFileUpload(e);
                                setlogoImage(e.files[0].objectURL);
                            }}
                        >
                            {fileName && <span className="p-d-block p-mt-2">Selected file: {fileName}</span>}
                        </FileUpload>
                    </div>
                </div>

                <div className="grid mt-3">
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <label htmlFor="username" className="employee-signup-input-label mb-2">
                            Company Name
                        </label>
                        <InputText
                            id="username"
                            className="employee-signup-input border-0"
                            placeholder="company name"
                            value={props?.companyName}
                            onChange={(e) => props?.setcompanyName(e.target.value)}
                            aria-describedby="username-help"
                        />
                        {props?.companyName === '' && (
                            <p className="my-1" style={{ color: 'red', fontSize: '13px' }}>
                                Company Name is required
                            </p>
                        )}
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <label htmlFor="password" className="employee-signup-input-label mb-2">
                            Location
                        </label>
                        <InputText
                            id="username"
                            className="employee-signup-input border-0"
                            placeholder="location"
                            value={props?.location}
                            onChange={(e) => props?.setlocation(e.target.value)}
                            aria-describedby="username-help"
                        />
                        {props?.location === '' && (
                            <p className="my-1" style={{ color: 'red', fontSize: '13px' }}>
                                Location is required
                            </p>
                        )}
                    </div>
                </div>
                <h6>Social Links</h6>
                <div className="grid mt-3">
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <InputText
                            type="url"
                            className="employee-signup-input w-100"
                            placeholder="instagram url"
                            value={props?.socialLinks?.Instagram}
                            onChange={(e) => props?.setSocialLinks({ ...props?.socialLinks, Instagram: e.target.value })}
                        />
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <InputText
                            type="url"
                            className="employee-signup-input w-100"
                            placeholder="facebook url"
                            value={props?.socialLinks?.Facebook}
                            onChange={(e) => props?.setSocialLinks({ ...props?.socialLinks, Facebook: e.target.value })}
                        />
                    </div>
                </div>
                <div className="grid mt-3">
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <InputText
                            type="url"
                            className="employee-signup-input w-100"
                            placeholder="Snapchat url"
                            value={props?.socialLinks?.Snapchat}
                            onChange={(e) => props?.setSocialLinks({ ...props?.socialLinks, Snapchat: e.target.value })}
                        />
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <InputText
                            className="employee-signup-input w-100"
                            placeholder="linkedin url"
                            value={props?.socialLinks?.LinkedIn}
                            onChange={(e) => props?.setSocialLinks({ ...props?.socialLinks, LinkedIn: e.target.value })}
                        />
                    </div>
                </div>
                <div className="grid mt-3">
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <InputText
                            className="employee-signup-input w-100"
                            placeholder="twitter url"
                            value={props?.socialLinks?.Twitter}
                            onChange={(e) => props?.setSocialLinks({ ...props?.socialLinks, Twitter: e.target.value })}
                        />
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <InputText
                            className="employee-signup-input w-100"
                            placeholder="tiktok url"
                            value={props?.socialLinks?.TikTok}
                            onChange={(e) => props?.setSocialLinks({ ...props?.socialLinks, TikTok: e.target.value })}
                        />
                    </div>
                </div>
                <div className="grid mt-3">
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <InputText
                            className="employee-signup-input w-100"
                            placeholder="yelp url"
                            value={props?.socialLinks?.Yelp}
                            onChange={(e) => props?.setSocialLinks({ ...props?.socialLinks, Yelp: e.target.value })}
                        />
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <InputText
                            className="employee-signup-input w-100"
                            placeholder="google url"
                            value={props?.socialLinks?.Google}
                            onChange={(e) => props?.setSocialLinks({ ...props?.socialLinks, Google: e.target.value })}
                        />
                    </div>
                </div>

                <hr className="line-horizontal mt-5" />

                <Button
                    type="button"
                    className="onboarding-btn w-max px-6 mt-5"
                    disabled={props?.location == '' || props?.companyName == ''}
                    onClick={() => {
                        updateProfile();
                        props?.setVisible(false);
                    }}
                >
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditEmployerBasic;
