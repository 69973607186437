import React from 'react';
import './sidebar.css';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import home from '../../../assets/images/home_blue_icon.svg';
import dashboard from '../../../assets/images/dashboard_blue_icon.svg';
import search from '../../../assets/images/search_blue_icon.svg';
import price from '../../../assets/images/dollar-sign.svg';
import message from '../../../assets/images/message_blue_icon.svg';
import batchMatch from '../../../assets/images/batch_match_blue_icon.svg';
import interviewTracker from '../../../assets/images/interview_tracker_blue_icon.svg';
import officeLocationIcon from '../../../assets/images/office_location_icon.svg';

import pendingCandidates from '../../../assets/images/pending_candidates_blue_icon.svg';
import jobPost from '../../../assets/images/job_post_blue_icon.svg';
import profile from '../../../assets/images/profile_blue_icon.svg';
import logoutIcon from '../../../assets/images/logout_icon.svg';

const Sidebar = ({ setsidebarToggle }) => {
    const { currentUser } = useSelector((state) => state.user);
    const { role } = currentUser;

    const candidateMenuData = [
        {
            name: 'Home',
            icon: home,
            link: '/home'
        },
        {
            name: 'Search Jobs',
            icon: search,
            link: '/search/jobs'
        },
        {
            name: 'Messages',
            icon: message,
            link: '/employer/messages'
        },
        {
            name: 'Batch Match',
            icon: batchMatch,
            link: '/candidate/batchMatch'
        },
        {
            name: 'Interview Tracker',
            icon: interviewTracker,
            link: '/candidate/interviewTrackers'
        },
        {
            name: 'Pending Requests',
            icon: pendingCandidates,
            link: '/candidate/pendingJobs'
        },
        {
            name: 'Profile',
            icon: profile,
            link: '/candidate/profile'
        },
        {
            name: 'Logout',
            icon: logoutIcon,
            link: '/employer/logout'
        }
    ];
    const adminMenuData = [
        {
            name: 'Home',
            icon: home,
            link: '/home'
        },
        {
            name: 'Dashboard',
            icon: dashboard,
            link: '/'
        },
        {
            name: 'Candidates',
            icon: pendingCandidates,
            link: '/admin/candidates'
        },
        {
            name: 'Employers',
            icon: interviewTracker,
            link: '/admin/employers'
        },
        {
            name: 'Payments',
            icon: price,
            link: '/admin/payments'
        },
        {
            name: 'Logout',
            icon: logoutIcon,
            link: '/employer/logout'
        }
    ];

    const sidebarMenuData = [
        {
            name: 'Home',
            icon: home,
            link: '/home'
        },
        {
            name: 'Dashboard',
            icon: dashboard,
            link: '/'
        },
        {
            name: 'Search Candidates',
            icon: search,
            link: '/search/candidates'
        },
        {
            name: 'Messages',
            icon: message,
            link: '/employer/messages'
        },
        {
            name: 'Batch Match',
            icon: batchMatch,
            link: '/employer/batchMatch'
        },
        {
            name: 'Interview Tracker',
            icon: interviewTracker,
            link: '/employer/interviewTrackers'
        },

        {
            name: 'Office Locations',
            icon: officeLocationIcon,
            link: '/employer/officeLocations'
        },

        {
            name: 'Pending Candidates',
            icon: pendingCandidates,
            link: '/employer/pendingCandidates'
        },
        {
            name: 'Job Postings',
            icon: jobPost,
            link: '/employer/jobPost'
        },
        {
            name: 'Profile',
            icon: profile,
            link: '/employer/profile'
        },
        {
            name: 'Logout',
            icon: logoutIcon,
            link: '/employer/logout'
        }
    ];
    return (
        <>
            <div className="layout-sidebar">
                <ul className="layout-menu">
                    <p className="dashboard-user-text">
                        {role === 'employer' || role === 'recruiter' ? 'Employer Panel' : role === 'admin' ? 'Admin Panel' : 'Candidate Panel'}
                    </p>
                    {role === 'employer' || role === 'recruiter'
                        ? sidebarMenuData.map((element, index) => {
                              return (
                                  <li className="w-full" key={index} onClick={() => setsidebarToggle(false)}>
                                      <NavLink className={({ isActive }) => (isActive ? 'p-ripple active-route' : 'p-ripple')} to={element.link}>
                                          <img src={element.icon} alt="dental sidebar icons" className="layout-menuitem-icon mr-1" />
                                          {element.name}
                                      </NavLink>
                                  </li>
                              );
                          })
                        : role === 'admin'
                        ? adminMenuData.map((element, index) => {
                              return (
                                  <li className="w-full" key={index} onClick={() => setsidebarToggle(false)}>
                                      <NavLink className={({ isActive }) => (isActive ? 'p-ripple active-route' : 'p-ripple')} to={element.link}>
                                          <img src={element.icon} alt="dental sidebar icons" className="layout-menuitem-icon mr-1" />
                                          {element.name}
                                      </NavLink>
                                  </li>
                              );
                          })
                        : candidateMenuData.map((element, index) => {
                              return (
                                  <li className="w-full" key={index} onClick={() => setsidebarToggle(false)}>
                                      <NavLink className={({ isActive }) => (isActive ? 'p-ripple active-route' : 'p-ripple')} to={element.link}>
                                          <img src={element.icon} alt="dental sidebar icons" className="layout-menuitem-icon mr-1" />
                                          {element.name}
                                      </NavLink>
                                  </li>
                              );
                          })}
                </ul>
            </div>
        </>
    );
};
export default Sidebar;
