import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

const CandidateOccupationScreenTwo = ({ candidateData, setCandidateData, positionSoftwares }) => {
    const [selectedOfficeSpecialities, setselectedOfficeSpecialities] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [suggestList, setSuggestList] = useState([]);
    const [officeSpecialities, setOfficeSpecialities] = useState(positionSoftwares.slice(0, 22));
    const [allSpecialities, setAllSpecialities] = useState(positionSoftwares.slice(22));

    const search = (event) => {
        const filterData = allSpecialities.filter((aS) => aS.toLocaleLowerCase().match(event.query.toLocaleLowerCase()));
        setSuggestList(filterData);
    };

    const specialitiesHandler = (speciality) => {
        const updateSelectedOfficeSpecialities = selectedOfficeSpecialities.find((el) => el === speciality);

        const existsInCandidate = candidateData.softwares.find((el) => el === speciality);

        if (updateSelectedOfficeSpecialities && existsInCandidate) {
            const selectedOfficeSpecialitiesfilter = selectedOfficeSpecialities.filter((el) => el !== speciality);

            setselectedOfficeSpecialities(selectedOfficeSpecialitiesfilter);
            const filteredSoftwares = candidateData.softwareExperience.filter((software) => software.name !== speciality);

            setCandidateData({
                ...candidateData,
                softwareExperience: [...filteredSoftwares],
                softwares: [...selectedOfficeSpecialitiesfilter]
            });
        } else {
            setselectedOfficeSpecialities([...selectedOfficeSpecialities, speciality]);
            setCandidateData({
                ...candidateData,
                softwareExperience: [
                    ...candidateData.softwareExperience,
                    {
                        name: speciality,
                        experience: 0
                    }
                ],
                softwares: [...candidateData.softwares, speciality]
            });
        }
    };

    const checkSpecialities = (name) => {
        const indexOfEmployee = selectedOfficeSpecialities.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    const addOfficeSpecialities = (value) => {
        const existObject = officeSpecialities.find((el) => el === value);
        if (!existObject && existObject !== '' && value !== '') {
            setOfficeSpecialities([...officeSpecialities, value]);
            setSearchValue('');
            const updatedSpecialities = allSpecialities.filter((item) => item !== value);
            setAllSpecialities(updatedSpecialities);
        }
    };

    useEffect(() => {
        if (candidateData?.softwares) {
            setOfficeSpecialities((prevSpeciality) => {
                const updatedSpeciality = [...prevSpeciality];
                for (let item of candidateData?.softwares) {
                    if (!updatedSpeciality.includes(item)) {
                        updatedSpeciality.push(item);
                    }
                }
                setselectedOfficeSpecialities(candidateData?.softwares);
                return updatedSpeciality;
            });
        }
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">Which software do you have experience using?</p>
            <h1 className="job-posting-select-all-text mt-2 mb-0 cursor-pointer">Select all that apply</h1>
            <div className="flex flex-wrap align-item-center justify-content-between add-button-container mt-3 mb-2 w-full sm:w-10">
                <AutoComplete
                    value={searchValue}
                    suggestions={suggestList}
                    completeMethod={search}
                    onChange={(e) => setSearchValue(e.value)}
                    className="auto-complete-add-button-input w-10"
                    placeholder="Search Software"
                />

                <div
                    className="flex align-item-center justify-content-center add-button-2 w-2 cursor-pointer w-max mr-2"
                    onClick={() => addOfficeSpecialities(searchValue)}
                >
                    <div className="add-button-text-2">Add</div>
                </div>
            </div>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {officeSpecialities?.map((speciality, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            checkSpecialities(speciality) ? 'available-questions-options-select' : 'available-questions-options'
                        }`}
                        onClick={() => specialitiesHandler(speciality)}
                    >
                        <div className={`${checkSpecialities(speciality) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {speciality}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOccupationScreenTwo;
