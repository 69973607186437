import React, { useState, useEffect } from 'react';
import { Image } from 'primereact/image';

import work from '../../../assets/images/work_env_image.svg';
import career from '../../../assets/images/career_image.svg';
import flexable from '../../../assets/images/flexable_hour_image.svg';
import diverseWorkforce from '../../../assets/images/diverse_workforce_image.svg';
import modernOffice from '../../../assets/images/modern_office_image.svg';
import ephathaticLeader from '../../../assets/images/ephathatic_leader_image.svg';
import recongnition from '../../../assets/images/recongnition_image.svg';
import funOffice from '../../../assets/images/fun_office_image.svg';
import compansation from '../../../assets/images/compansation_image.svg';

const EmployerOnboardingScreenTwo = ({ employerData, setEmployerData }) => {
    const [selectedLikes, setselectedLikes] = useState([]);

    const employeeLikes = [
        {
            name: 'Work environment',
            image: work
        },
        {
            name: 'Career advancement',
            image: career
        },
        {
            name: 'Flexible work hours',
            image: flexable
        },
        {
            name: 'Diverse workforce',
            image: diverseWorkforce
        },
        {
            name: 'Modern & updated office',
            image: modernOffice
        },
        {
            name: 'Empathetic Leadership',
            image: ephathaticLeader
        },
        ,
        {
            name: 'Recognition & rewards',
            image: recongnition
        },
        {
            name: 'Fun office environment',
            image: funOffice
        },
        {
            name: 'Compensation',
            image: compansation
        }
    ];

    const LikesHandler = (name) => {
        const filterLike = employeeLikes?.find((like) => like?.name === name);
        const selectedLikesNames = selectedLikes?.map((item) => item.name);
        const updateSelectedLikes = selectedLikes?.find((like) => like?.name === name);

        if (updateSelectedLikes) {
            const selectedLikesfilter = selectedLikes?.filter((like) => like?.name !== name);
            const selectedLikesfilterNames = selectedLikesfilter?.map((item) => item.name);
            setselectedLikes(selectedLikesfilter);
            setEmployerData({ ...employerData, employerLoveAbout: selectedLikesfilterNames });
        } else {
            if (selectedLikes.length < 6) {
                setselectedLikes([...selectedLikes, filterLike]);
                setEmployerData({ ...employerData, employerLoveAbout: [...selectedLikesNames, filterLike.name] });
            }
        }
    };

    const checkEmployeesLikes = (name) => {
        const indexOfEmployee = selectedLikes.findIndex((employee) => employee?.name === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (employerData.employerLoveAbout.length) {
            const filteredEmployeeLikes = employeeLikes.filter((like) => employerData.employerLoveAbout.includes(like.name));
            setselectedLikes(filteredEmployeeLikes);
        }
    }, []);

    return (
        <div>
            <p className="employer-questions-heading mt-5">Select up to 6 things your employees love about your company</p>
            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {employeeLikes?.map((likes, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 ${
                            checkEmployeesLikes(likes.name) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                        }`}
                        onClick={() => LikesHandler(likes.name)}
                    >
                        <Image src={likes.image} alt="Logo" className={`${(checkEmployeesLikes(likes.name) && 'benefit-image-select') || 'benefit-image'}`} />

                        <div className={`${(checkEmployeesLikes(likes.name) && 'available-questions-options-select-text') || 'available-questions-options-text'}`}>
                            {likes.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmployerOnboardingScreenTwo;
