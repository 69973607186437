import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenEight = ({
    candidateData,
    setCandidateData,
    setselectedPostionSkill,
    positionData,
    setpositionSkills,
    setpositionSoftwares,
    setpositionIndustryTools
}) => {
    const [selectedPostion, setselectedPostion] = useState();
    const [selectedOccupation, setselectedOccupation] = useState();

    const [currentOccupation, setcurrentOccupation] = useState('');
    const [occupation, setOccupation] = useState('');
    const [positions, setPositions] = useState([
        'Front Desk',
        'Treatment Coordinator',
        'Receptionist',
        'Billing Coordinator',
        'Office Manager',
        'Marketing Coordinator',
        'Marketing Manager',
        'Marketing Specialist',
        'Orthodontic Assistant',
        'Dental Assistant',
        'Registered Dental Assistant',
        'Dental Hygienist'
    ]);

    useEffect(() => {
        setselectedPostion(candidateData?.currentJobOccupation);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">What is your current occupation?</p>

            <div className="flex  align-item-center justify-content-between add-button-container w-9 mt-3 mb-2">
                <InputText
                    placeholder="current job occupation"
                    value={currentOccupation}
                    onChange={(e) => {
                        setcurrentOccupation(e.target.value);
                    }}
                    className="border-0 add-button-container-input"
                />

                <div
                    className="add-button-2 cursor-pointer mr-2"
                    onClick={() => {
                        if (currentOccupation !== '') {
                            setOccupation(currentOccupation);
                            setcurrentOccupation('');
                        }
                    }}
                >
                    <div className="add-button-text-2">Add</div>
                </div>
            </div>

            <div
                style={{ width: 'fit-content' }}
                className={`mt-3 ${
                    (selectedOccupation === occupation && 'available-questions-options-select cursor-pointer') || ' available-questions-options cursor-pointer'
                }`}
                onClick={() => {
                    if (selectedOccupation === occupation) {
                        setselectedOccupation('');
                        setCandidateData({ ...candidateData, currentJob: '' });
                    } else {
                        setselectedOccupation(occupation);
                        setCandidateData({ ...candidateData, currentJob: occupation });
                    }
                }}
            >
                <div className={`text-center ${selectedOccupation === occupation ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                    {occupation}
                </div>
            </div>

            <p className="employer-questions-heading mt-5">Which role are you looking for?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {positions?.map((position, index) => (
                    <div
                        key={index}
                        className={`${
                            (selectedPostion === position && 'available-questions-options-select cursor-pointer') ||
                            'flex justify-content-center align-items-center available-questions-options gap-3 cursor-pointer'
                        }`}
                        onClick={() => {
                            setselectedPostion(position);
                            setCandidateData({ ...candidateData, currentJobOccupation: position });

                            const positionItem = positionData?.find((item) => item.name === position);

                            setpositionSkills(positionItem.skills);
                            setpositionSoftwares(positionItem.softwares);
                            setpositionIndustryTools(positionItem.industryTools);
                        }}
                    >
                        <div className={`${selectedPostion === position ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {position}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenEight;
