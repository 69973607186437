import React, { useEffect, useState } from 'react';

import profile1 from '../../../assets/images/candidate_image_1.svg';
import candidate from '../../../assets/images/batch_avatar.png';

import { BASE_URL, calculateLastActive, getLoggedUser, imageDisplay, sendRequest } from '../../../helpers/utils';
import RecentCandidateCard from '../../cards/RecentCandidateCard';
import { useNavigate } from 'react-router-dom';

const RecentCandidateSection = () => {
    const [popCandidates, setPopCandidates] = useState('');
    const navigate = useNavigate();
    const userProfile = getLoggedUser();

    const recentCandidates = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/user/popularCandidates`, {
            method: 'get'
        });

        if (response.status === 200) {
            setPopCandidates(response.data.data || []);
        }
    };

    useEffect(() => {
        recentCandidates();
    }, []);

    const recentJobs = {
        candidateImage: profile1,
        designation: 'Dental Asistant',
        location: 'New York',
        response: '100% Responsive'
    };

    return (
        <div className="w-full flex flex-column mt-6">
            <div className="secondary-heading mt-6">Most Popular Candidates On Batch</div>

            <div className="grid mt-5 mx-0 mb-0">
                {popCandidates?.length > 0 &&
                    popCandidates?.slice(0, 6).map((job, index) => (
                        <div
                            key={index}
                            className="col-12 lg:col-3 md:col-4"
                            onClick={() => (Object.keys(userProfile).length > 0 ? navigate(`/search/candidates/profile/${job?._id}`) : {})}
                        >
                            <RecentCandidateCard
                                key={index}
                                candidateImage={job?.profileImage ? imageDisplay(job?.profileImage) : candidate}
                                designation={job?.position || 'Dental Asistant'}
                                location={job?.location || 'New York'}
                                response={job?.responseRate.toFixed(1) + '%' + ' Responsive' || ''}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default RecentCandidateSection;
