import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';

const CandidateOnboardingScreenFourteen = ({ candidateData, setCandidateData }) => {
    const [link, setlink] = useState('');

    useEffect(() => {
        setlink(candidateData?.linkedInProfileLink);
    }, []);
    return (
        <>
            <p className="employer-questions-heading mt-5">Do you have a LinkedIn profile?</p>

            <InputText
                value={link}
                onChange={(e) => {
                    setlink(e.target.value);
                    setCandidateData({ ...candidateData, linkedInProfileLink: e.target.value });
                }}
                placeholder="https://linkedin.com/"
                className="border-0 add-button-container-input w-full mt-3 mb-3"
            />
        </>
    );
};

export default CandidateOnboardingScreenFourteen;
