import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';

import { Button } from 'primereact/button';
import 'rc-slider/assets/index.css';
import { showErrorToast } from '../../../helpers/toast-helper';
import { ToastContext } from '../../../context/ToastContext';
import { InputNumber } from 'primereact/inputnumber';

const CandidateOnboardingScreenFifteen = ({ candidateData, setCandidateData, setCurrentStep, currentStep }) => {
    const [selectedOpinion, setselectedOpinion] = useState('Yes');
    const [selectedTime, setSelectedTime] = useState({
        label: 'Hourly'
    });
    const toast = useContext(ToastContext);

    const [salValue, setSalValue] = useState({
        min: 1,
        max: 1000
    });

    const [state, setState] = useState({
        lowerBound: 1,
        upperBound: 1000,
        value: [20, 500]
    });
    const [error, setError] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const times = [
        {
            label: 'Hourly'
        },
        {
            label: 'Daily'
        },
        {
            label: 'Monthly'
        },
        {
            label: 'Yearly'
        }
    ];

    useEffect(() => {
        setCandidateData({
            ...candidateData,
            salaryExpectations: { type: 'Hourly', max: 1000, min: 1 }
        });
        setIsValid(true);
    }, []);
    useEffect(() => {
        const MinValue = selectedTime?.label === 'Hourly' ? 1 : selectedTime?.label === 'Daily' ? 1 : selectedTime?.label === 'Monthly' ? 500 : 1500;
        const MaxValue = selectedTime?.label === 'Hourly' ? 1000 : selectedTime?.label === 'Daily' ? 1000 : selectedTime?.label === 'Monthly' ? 100000 : 500000;
        setSalValue({ min: MinValue, max: MaxValue });
        setState({ lowerBound: MinValue, upperBound: MaxValue, value: [MinValue, MaxValue] });
        setCandidateData({
            ...candidateData,
            salaryExpectations: { type: selectedTime?.label, max: MaxValue, min: MinValue }
        });
    }, [selectedTime]);

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    useEffect(() => {
        setselectedOpinion(candidateData?.salaryShowOnYourProfile ? 'Yes' : 'No');
    }, []);

    const onLowerBoundChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            lowerBound: +e.target.value
        }));
        const { lowerBound, upperBound } = state;

        const MinValue = selectedTime?.label === 'Hourly' ? 1 : selectedTime?.label === 'Daily' ? 1 : selectedTime?.label === 'Monthly' ? 500 : 1500;
        const MaxValue = selectedTime?.label === 'Hourly' ? 1000 : selectedTime?.label === 'Daily' ? 1000 : selectedTime?.label === 'Monthly' ? 100000 : 500000;
        if (e.target.value >= MinValue && upperBound <= MaxValue) {
            setCandidateData({
                ...candidateData,
                salaryExpectations: { type: selectedTime?.label, max: upperBound, min: lowerBound }
            });
            setIsValid(true);
            setError(false);
        } else {
            showErrorToast(toast, 'Value Not Valid', '');
            setError(true);
        }
    };

    const onUpperBoundChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            upperBound: +e.target.value
        }));

        const { lowerBound, upperBound } = state;

        const MinValue = selectedTime?.label === 'Hourly' ? 1 : selectedTime?.label === 'Daily' ? 1 : selectedTime?.label === 'Monthly' ? 500 : 1500;
        const MaxValue = selectedTime?.label === 'Hourly' ? 1000 : selectedTime?.label === 'Daily' ? 1000 : selectedTime?.label === 'Monthly' ? 100000 : 500000;

        if (lowerBound >= MinValue && e.target.value <= MaxValue) {
            setCandidateData({
                ...candidateData,
                salaryExpectations: { type: selectedTime?.label, max: e.target.value, min: lowerBound }
            });
            setIsValid(true);
        } else {
            showErrorToast(toast, 'Value Not Valid', '');
        }
    };
    return (
        <>
            <p className="employer-questions-heading mt-5">What are your salary expectations?</p>

            <div className="flex flex-wrap justify-content-start gap-2 mt-5">
                <Dropdown
                    options={times}
                    value={selectedTime}
                    onChange={(e) => {
                        setSelectedTime(e.value);
                        setCandidateData({ ...candidateData, salaryExpectations: { type: e.value.label } });
                    }}
                    optionLabel="label"
                    placeholder="Hourly"
                    className={`circular-slider-dropdown border-0 h-max pl-4 pr-1`}
                    panelClassName="circular-slider-panel"
                />
                <>
                    <div>
                        <label style={{ minWidth: '150px', display: 'inline-block' }}>Minimum: </label>
                        <InputNumber
                            prefix="$"
                            showButtons
                            min={salValue?.min}
                            max={salValue?.max}
                            value={Math.ceil(state.lowerBound)}
                            onValueChange={onLowerBoundChange}
                        />
                        <br />
                        <div className="mt-2">
                            <label style={{ minWidth: '150px', display: 'inline-block' }}>Maximum: </label>
                            <InputNumber
                                prefix="$"
                                showButtons
                                min={salValue?.min}
                                max={salValue?.max}
                                value={Math.floor(state.upperBound)}
                                onValueChange={onUpperBoundChange}
                            />
                        </div>
                    </div>
                </>
            </div>

            {error && <div className="error-message mt-1">{salValue?.min > salValue?.max && <p className="error">maximum value must be greater then minimum</p>}</div>}

            <hr className="line-horizontal mt-5 " />

            <div className="flex flex-wrap gap-3 mt-4">
                <Button type="button" className="onboarding-btn  w-max" onClick={handleNext} disabled={!isValid}>
                    Next
                </Button>
            </div>
        </>
    );
};

export default CandidateOnboardingScreenFifteen;
