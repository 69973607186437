import React, { useState, useEffect } from 'react';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';

import * as yup from 'yup';
import { Formik } from 'formik';

import addIcon from '../../../assets/images/add_image.svg';
import deleteIcon from '../../../assets/images/deleteIcon.svg';

const EmployerOnboardingScreenEight = ({ employerData, setEmployerData }) => {
    const initialValues = {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
    };
    const [address, setaddress] = useState([]);

    const schema = yup.object().shape({
        address1: yup.string().required('Street address required!'),
        address2: yup.string(),
        city: yup.string().required('City Name is required!'),
        state: yup.string().required('State is required!'),
        zipCode: yup.string().required('Zip Code is required!'),
        country: yup.string().required('Country is required!')
    });

    const deleteAddress = (value) => {
        const filterAddresss = address.filter((el) => el.address !== value);
        const filterAddressName = filterAddresss.map((el) => el.address);
        setaddress([...filterAddresss]);
        setEmployerData({ ...employerData, officeAddresses: filterAddressName });
    };

    const handleSubmit = async (values) => {
        const newAddress = `${values.address1 ? values.address1 + '. ' : ''}${values.address2 ? values.address2 + ', ' : ''}${values.city ? values.city + ', ' : ''}${
            values.state ? values.state + ', ' : ''
        }${values.zipCode ? values.zipCode + ', ' : ''}${values.country ? values.country : ''}`;

        setaddress([...address, { address: newAddress.trim() }]);

        const formattedAddress = [values.address1, values.address2, values.city, values.state, values.zipCode, values.country].filter(Boolean).join(', ');

        setEmployerData((prevData) => ({
            ...prevData,
            officeAddresses: [...prevData.officeAddresses, formattedAddress]
        }));

        // Reset values
        Object.keys(values).forEach((key) => {
            values[key] = '';
        });
    };

    useEffect(() => {
        if (employerData.officeAddresses) {
            const arrayOfObjects = employerData.officeAddresses.map((url) => ({ address: url }));
            setaddress(arrayOfObjects);
        }
    }, []);

    return (
        <div>
            <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <p className="employer-questions-heading mt-5">Add your office address</p>

                        <div className="add-button-container mt-3 mb-2 xl:w-10" style={{ display: 'block' }}>
                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 xl:col-12 flex flex-column">
                                <label htmlFor="address1" className="employee-signup-input-label mb-2">
                                    Address 1
                                </label>
                                <InputText
                                    id="address1"
                                    type="text"
                                    name="address1"
                                    className="employee-signup-input border-0"
                                    placeholder="street address"
                                    value={values.address1}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.address1 && errors.address1 && <p className="error">{errors.address1}</p>}</div>
                            </div>

                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 xl:col-12 flex flex-column">
                                <label htmlFor="address2" className="employee-signup-input-label mb-2">
                                    Address 2
                                </label>
                                <InputText
                                    id="address2"
                                    type="text"
                                    name="address2"
                                    className="employee-signup-input border-0"
                                    placeholder="Suite/Apt/Unit number"
                                    value={values.address2}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.address2 && errors.address2 && <p className="error">{errors.address2}</p>}</div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 xl:col-12 flex flex-column">
                                <label htmlFor="city" className="employee-signup-input-label mb-2">
                                    City
                                </label>
                                <InputText
                                    id="city"
                                    type="text"
                                    name="city"
                                    className="employee-signup-input border-0"
                                    placeholder="City"
                                    value={values.city}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.city && errors.city && <p className="error">{errors.city}</p>}</div>
                            </div>

                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 xl:col-12 flex flex-column">
                                <label htmlFor="state" className="employee-signup-input-label mb-2">
                                    State
                                </label>
                                <InputText
                                    id="state"
                                    type="text"
                                    name="state"
                                    className="employee-signup-input border-0"
                                    placeholder="State"
                                    value={values.state}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.state && errors.state && <p className="error">{errors.state}</p>}</div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 xl:col-12 flex flex-column">
                                <label htmlFor="zipCode" className="employee-signup-input-label mb-2">
                                    Zip Code
                                </label>
                                <InputText
                                    id="zipCode"
                                    type="text"
                                    name="zipCode"
                                    className="employee-signup-input border-0"
                                    placeholder="Zip code"
                                    value={values.zipCode}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.zipCode && errors.zipCode && <p className="error">{errors.zipCode}</p>}</div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 xl:col-12 flex flex-column">
                                <label htmlFor="country" className="employee-signup-input-label mb-2">
                                    Country
                                </label>
                                <InputText
                                    id="country"
                                    type="text"
                                    name="country"
                                    className="employee-signup-input border-0"
                                    placeholder="Country"
                                    value={values.country}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.country && errors.country && <p className="error">{errors.country}</p>}</div>
                            </div>

                            <div className="flex align-item-center justify-content-around add-button w-2 cursor-pointer mr-2 mt-2" onClick={handleSubmit}>
                                <Image src={addIcon} alt="add" />
                                <div className="add-button-text">Add</div>
                            </div>
                        </div>

                        {address?.map((addres, index) => (
                            <div key={index} className="flex flex-nowrap align-items-center justify-content-start w-full mt-3">
                                <div className="onboarding-btn-3 px-3 py-2 cursor-pointer">{index + 1}</div>
                                <div className="add-button-container justify-content-between px-3 py-2 w-full xl:w-9 ml-3">
                                    <div className="social-active-link">{addres?.address}</div>

                                    <Image src={deleteIcon} className="cursor-pointer add-button-container" alt="add" onClick={() => deleteAddress(addres?.address)} />
                                </div>
                            </div>
                        ))}
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default EmployerOnboardingScreenEight;
