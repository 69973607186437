import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';

import * as yup from 'yup';
import { Formik } from 'formik';

import { loginSuccess } from '../../../redux/userSlice';
import { ToastContext } from '../../../context/ToastContext';

import { sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';
import { initializeAnalytics, sendPageView } from '../../../G4A-Package';

const LoginScreen = (props) => {
    const [loading, setLoading] = useState(false);

    const toast = useContext(ToastContext);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Yup Form Schema Validitions
    const schema = yup.object().shape({
        email: yup.string().email('Must be valid Email').required('Email is required!'),
        password: yup.string().required('Password is required!')
    });

    // Form Intial Values - If its New Form
    const initialValues = {
        email: '',
        password: ''
    };

    const handleSubmit = async (values) => {
        setLoading(true);

        const { email, password } = values;
        const login = await sendRequest(`${process.env.REACT_APP_API_URL}api/v1/user/login`, { payload: { email, password } });

        if (login.status === 200) {
            const { data: userDetails, token } = login.data;
            dispatch(loginSuccess(userDetails));
            localStorage.setItem('token', token);
            localStorage.setItem('userProfile', JSON.stringify(userDetails));

            showSuccessToast(toast, 'Login', login?.data?.message);

            if (userDetails.role === 'candidate') {
                navigate('/search/jobs');
            } else if (['employer', 'recruiter', 'admin'].includes(userDetails.role)) {
                // navigate('/');
                window.location.href = '/';
            }
        } else {
            showErrorToast(toast, 'Login', login?.data?.desc);
        }

        setLoading(false);
    };

    useEffect(() => {
        initializeAnalytics();
        sendPageView(window.location.pathname, 'User Login');
    }, []);

    return (
        <>
            <h1 className="login-welcome-text">Welcome Back!</h1>
            <p className="employer-questions-heading mt-2">Login here and continue your experience with Batch!</p>

            <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className="grid mt-5">
                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 flex flex-column">
                                <label htmlFor="username" className="employee-signup-input-label mb-2">
                                    Email
                                </label>
                                <InputText
                                    id="email"
                                    type="email"
                                    className="employee-signup-input border-0 w-full xl:w-10"
                                    placeholder="sampl@gmail.com"
                                    aria-describedby="username-help"
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                <div className="error-message mt-1">{touched.email && errors.email && <p className="error">{errors.email}</p>}</div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 flex flex-column candidate-onboarding">
                                <label htmlFor="password" className="employee-signup-input-label mb-2">
                                    Password
                                </label>
                                <Password
                                    id="password"
                                    type="password"
                                    inputId="password"
                                    placeholder="*********"
                                    promptLabel="Choose a password"
                                    inputClassName="employee-signup-input border-0 w-10 xl:w-10"
                                    onChange={handleChange}
                                    value={values.password}
                                    feedback={false}
                                    toggleMask
                                />
                                <div className="error-message mt-1">{touched.password && errors.password && <p className="error">{errors.password}</p>}</div>
                            </div>
                            <div className="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-10  flex justify-content-end">
                                <h5
                                    className="forgot-password-text cursor-pointer"
                                    onClick={() => {
                                        props?.setCurrentStep(props?.currentStep + 1);
                                    }}
                                >
                                    Forget Password
                                </h5>
                            </div>
                        </div>
                        <hr className="line-horizontal mt-3 " />
                        <Button type="submit" className="onboarding-btn mt-4 w-max">
                            Login
                        </Button>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default LoginScreen;
