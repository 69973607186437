import React, { useEffect, useState } from 'react';

const CandidateOccupationScreenOne = ({ candidateData, setCandidateData, positionSkills }) => {
    const [selectedOfficeSpecialities, setselectedOfficeSpecialities] = useState([]);

    const officeSpecialities = positionSkills || [
        'Greet and welcome patients',
        'Assist patients with filling out forms',
        'Prepare patient charts and daily schedules',
        'Answer phones, photocopy, filing, faxing',
        'Insurance Billing/Verification',
        'Collect copayments',
        'Schedule appointments',
        'Check-In/Check out Patients',
        'Manage Patient Records',
        'Order Dental/Office Supplies',
        'Respond to Emails',
        'Manage Collections',
        'Sterilize and Disinfect Dental instruments',
        'Set Up Room and Break Down Patient Rooms',
        'Present and sell treatment plans',
        'Collect and post payments'
    ];

    const specialitiesHandler = (speciality) => {
        const updateSelectedOfficeSpecialities = selectedOfficeSpecialities.find((el) => el === speciality);
        if (updateSelectedOfficeSpecialities) {
            const selectedOfficeSpecialitiesfilter = selectedOfficeSpecialities.filter((el) => el !== speciality);
            setselectedOfficeSpecialities(selectedOfficeSpecialitiesfilter);
            setCandidateData({ ...candidateData, skills: selectedOfficeSpecialitiesfilter.map((el) => el) });
        } else {
            setselectedOfficeSpecialities([...selectedOfficeSpecialities, speciality]);
            setCandidateData({ ...candidateData, skills: [...candidateData.skills, speciality] });
        }
    };

    const checkSpecialities = (name) => {
        const indexOfEmployee = selectedOfficeSpecialities.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setselectedOfficeSpecialities([...candidateData?.skills]);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">List Your Skills</p>
            <h1 className="job-posting-select-all-text mt-2 mb-0 cursor-pointer">Select all that apply</h1>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {officeSpecialities?.map((speciality, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            checkSpecialities(speciality) ? 'available-questions-options-select' : 'available-questions-options'
                        }`}
                        onClick={() => specialitiesHandler(speciality)}
                    >
                        <div className={`${checkSpecialities(speciality) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {speciality}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOccupationScreenOne;
