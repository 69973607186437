import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';

import saveJobIcon from '../../../assets/images/save_job_icon.svg';
import deleteIcon from '../../../assets/images/delete_icon.svg';

import JobPostingPreview from '../../../pages/job_post/JobPostingPreview';
import { Dialog } from 'primereact/dialog';
import { ToastContext } from '../../../context/ToastContext';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';

const JobPostScreenTwelve = ({ jobData, setJobData, setCurrentStep, currentStep, setVisible }) => {
    const [savedJob, setsavedJob] = useState(false);
    const [visibleAddLocation, setVisibleAddLocation] = useState(false);

    const [selectedStages, setSelectedStages] = useState([]);
    const [stages, setstages] = useState(['Stage 1']);
    const [newInterviewStages, setNewInterviewStages] = useState(['Phone Interview', 'Video Interview', 'Group Interview', 'Onsite Interview', 'Working Interview']);
    const navigate = useNavigate();
    const toast = useContext(ToastContext);
    const userToken = getUserToken();

    const handleRadioChange = (value, index) => {
        if (jobData.interviewProcess.length === index) {
            setSelectedStages([...selectedStages, value]);
            setJobData({ ...jobData, interviewProcess: [...jobData.interviewProcess, value] });
        } else {
            let updateValue = jobData.interviewProcess;
            updateValue[index] = value;

            let updateStage = selectedStages;
            updateStage[index] = value;

            setSelectedStages(updateStage);
            setJobData({ ...jobData, interviewProcess: updateValue });
        }
    };

    const isProcessChecked = (value, index) => {
        return selectedStages[index] === value;
    };

    const handleDelete = (index) => {
        let updateStage = stages;
        updateStage.splice(index, 1);
        setstages(updateStage);

        let updateSelectedStage = selectedStages;
        updateSelectedStage.splice(index, 1);
        setSelectedStages(updateSelectedStage);

        setJobData({ ...jobData, interviewProcess: updateSelectedStage });
    };

    useEffect(() => {
        if (jobData.interviewProcess.length > 0) {
            setSelectedStages(jobData.interviewProcess);
            const newStages = [];
            jobData?.interviewProcess?.forEach((element, index) => {
                newStages.push(`Stage ${index + 1}`);
            });
            setstages(newStages);
        }
    }, []);

    const handleDraftJobPost = async () => {
        const jobDataPost = {
            ...jobData,
            status: 'pending'
        };
        const response = await sendRequest(`${BASE_URL}api/v1/job/create`, {
            method: 'post',
            payload: jobDataPost,
            headers: {
                'x-access-token': userToken
            }
        });
        if (response.status === 200) {
            showSuccessToast(toast, 'Job Drafted', response?.data?.decs);
            setsavedJob(!savedJob);
            setVisible(false);
            navigate('/employer/jobPost');
        } else showErrorToast(toast, 'SomeThing went wrong', response?.data?.message);
    };

    return (
        <>
            <p className="employer-questions-heading mt-5">What is your interview process?</p>
            <div
                style={{
                    background: '#FFFFFF',
                    borderRadius: '6px'
                }}
                className="mt-5"
            >
                {stages.map((stage, index1) => (
                    <>
                        <div className="flex justify-content-between mt-1 mx-4" key={index1}>
                            <h4 className="employer-questions-heading " style={{ fontSize: '1rem' }}>
                                {stage}
                            </h4>
                            {index1 > 0 && (
                                <Image
                                    src={deleteIcon}
                                    alt="cross icon"
                                    width="25px"
                                    height="25px"
                                    className="cursor-pointer flex justify-content-end"
                                    style={{ padding: '10px' }}
                                    onClick={() => {
                                        handleDelete(index1);
                                    }}
                                />
                            )}
                        </div>

                        <div
                            className="flex flex-wrap justify-content-start align-items-center gap-3 mb-4 mx-4 p-3"
                            style={{
                                background: '#F7FAFC',
                                borderRadius: '5px'
                            }}
                        >
                            {newInterviewStages.map((stage, index) => (
                                <div key={index} className={`flex justify-content-center align-items-center gap-2 cursor-pointer`}>
                                    <Checkbox
                                        className="interview-stage-checkbox"
                                        onChange={() => handleRadioChange(stage, index1)}
                                        checked={isProcessChecked(stage, index1)}
                                    />
                                    <div className={'available-questions-options-text'}>{stage}</div>
                                </div>
                            ))}
                        </div>
                    </>
                ))}

                <div className="flex justify-content-end mt-1 mx-4">
                    <h4 className="add-more-text cursor-pointer" style={{ fontSize: '1rem' }} onClick={() => setstages([...stages, `Stage ${stages.length + 1}`])}>
                        Add More Stages
                    </h4>
                </div>
            </div>

            <hr className="line-horizontal mt-3 " />

            <div className="flex flex-wrap gap-3 mt-4">
                <Button type="button" className="onboarding-btn-2 w-max px-4 " onClick={() => setVisibleAddLocation(true)}>
                    Preview
                </Button>
                <Button
                    type="button"
                    className="onboarding-btn w-max px-4"
                    onClick={() => {
                        setCurrentStep(currentStep + 1);
                    }}
                >
                    Proceed to payment
                </Button>

                <div className="flex align-items-end cursor-pointer" onClick={handleDraftJobPost}>
                    <Image src={saveJobIcon} className={`${savedJob ? '' : 'save-job'}`} alt="save job image" />
                    <h4 className="save-job-text my-1 ml-2">Save Draft</h4>
                </div>
            </div>
            <Dialog
                visible={visibleAddLocation}
                style={{ width: '90vw' }}
                maximizable
                modal={false}
                className="dialog-box previewdialog"
                onHide={() => setVisibleAddLocation(false)}
            >
                <JobPostingPreview from={'candidate'} jobID={'123'} jobData={jobData} />
            </Dialog>
        </>
    );
};

export default JobPostScreenTwelve;
