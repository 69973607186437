import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import viewIcon from '../../assets/images/view_icon.svg';
import editIcon from '../../assets/images/edit_icon_1.svg';
import editIcon1 from '../../assets/images/edit_icon_2.svg';
import moment from 'moment';
import { Tooltip } from 'primereact/tooltip';
import deleteIcon from '../../assets/images/deleteIcon.svg';

import EditEmployerBasic from '../../components/dialog/profile_edit/EditEmployerBasic';
import EditEmployerInformation from '../../components/dialog/profile_edit/EditEmployerInformation';
import EditEmployeesLove from '../../components/dialog/profile_edit/EditEmployeesLove';
import EditEmployerOfficeGallery from '../../components/dialog/profile_edit/EditEmployerOfficeGallery';

import locationIcon from '../../assets/images/location_icon_2.svg';

import workEnv from '../../assets/images/work_envirment_image.svg';
import careerImage from '../../assets/images/career_advancement_image.svg';

import fbIcon from '../../assets/images/fb_icon_2.svg';
import youtubIcon from '../../assets/images/youtub_icon_2.svg';
import twitterIcon from '../../assets/images/twitter.png';
import tiktokIcon from '../../assets/images/tiktok_icon_2.svg';
import instaIcon from '../../assets/images/insta_icon_2.svg';
import linkdinIcon from '../../assets/images/linkdin_icon_2.svg';

import image1 from '../../assets/images/employee_office_image_5.svg';
import image2 from '../../assets/images/employee_office_image_3.svg';
import image3 from '../../assets/images/employee_office_image_4.svg';
import yelp from '../../assets/images/yelp.png';
import linkedin from '../../assets/images/linkedin.png';
import employer from '../../assets/images/tooth.png';
import snapchat from '../../assets/images/snapchat.png';
import google from '../../assets/images/google.png';
import { BASE_URL, formatPrice, getLoggedUser, getUserToken, imageDisplay, sendRequest } from '../../helpers/utils';
import { ToastContext } from '../../context/ToastContext';
import { showErrorToast, showSuccessToast } from '../../helpers/toast-helper';

import { employeBenifits } from './employeeBenfietsData';
import EditEmployeSkills from '../../components/dialog/profile_edit/EditEmployeSkills';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import JobPostingPreview from '../job_post/JobPostingPreview';
import { Button } from 'primereact/button';
import { initializeAnalytics, sendPageView } from '../../G4A-Package';

const Profile = ({ signupData }) => {
    const [benefitEdit, setBenefitEdit] = useState(false);
    const [employerBasicEdit, setEmployerBasicEdit] = useState(false);
    const [employeInfoEdit, setemployeInfoEdit] = useState(false);
    const [employeLoveEdit, setemployeLoveEdit] = useState(false);
    const [employeOfficeGalleryEdit, setemployeOfficeGalleryEdit] = useState(false);

    const [companyName, setcompanyName] = useState('scc');
    const [visibleAddLocation, setVisibleAddLocation] = useState(false);
    const [officeLogoImage, setofficeLogoImage] = useState('');
    const [location, setlocation] = useState('123 Main Street Cambell, CA 95111');
    const [officeSpecialty, setofficeSpecialty] = useState('Pediatric');
    const [officeSize, setofficeSize] = useState('11-20');
    const [officeCount, setofficeCount] = useState('4');
    const [industry, setindustry] = useState('Dental');
    const [officeSetting, setofficeSetting] = useState('Private');
    const [jobData, setJobData] = useState('');
    const [socialLinks, setSocialLinks] = useState({});
    const userProfileData = getLoggedUser();

    const [missionValue, setMissionValue] = useState(
        'We’re fanatical about creating a lifetime of happy smiles and believe that when kids enjoy the dentist, healthy teeth and gums will follow.'
    );
    const [myProfile, setProfile] = useState([]);
    const [recentJobs, setRecentJobs] = useState([]);
    const userToken = getUserToken();
    const toast = useContext(ToastContext);
    const [confirmDelete, setConfirmDelete] = useState('');
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const locations = useLocation();

    const navigate = useNavigate();

    const [employeeImages, setemployeeImages] = useState([image1, image2, image3]);

    useEffect(() => {
        initializeAnalytics();
        sendPageView(window.location.pathname, 'Employer Profile');
    }, []);

    const userProfile = async () => {
        let response;
        if (!signupData || locations?.state?.employerId !== 'undefined') {
            if (userProfileData?.role == 'recruiter' || userProfileData?.role == 'admin') {
                response = await sendRequest(
                    `${BASE_URL}api/v1/user/${locations?.state?.employerId !== 'undefined' ? locations?.state?.employerId : userProfileData?.addedByEmployer}`,
                    {
                        method: 'get',
                        headers: {
                            'x-access-token': userToken
                        }
                    }
                );
            } else {
                response = await sendRequest(`${BASE_URL}api/v1/user`, {
                    method: 'get',
                    payload: {},
                    headers: {
                        'x-access-token': userToken
                    }
                });
            }

            if (response.status === 200) {
                let userData = response.data.data;
                setProfile(response.data.data || []);
                setcompanyName(userData?.name);
                setofficeSpecialty(userData?.preferences.employer.officeSpecialties);
                setofficeCount(userData?.preferences.employer.noOfOffices);
                setofficeSize(userData?.preferences.employer.noOfEmployees);
                setMissionValue(userData?.preferences.employer.companyAbout);
                setofficeSetting(userData?.preferences.employer.bestDescribeOffice);
                setindustry(userData?.preferences.employer.interestedIndustry);
                setlocation(userData?.preferences.employer.officeAddresses[0]);
                setofficeLogoImage(userData?.preferences.employer?.companyLogo);
                setMissionValue(userData?.preferences?.employer?.companyAbout);
                setSocialLinks(userData?.preferences.employer?.socialAccounts);
            } else showErrorToast(toast, 'Error', response?.data?.decs);
        }
    };

    const getRecentJobs = async () => {
        let response = await sendRequest(
            `${BASE_URL}api/v1/job/employer?id=${
                signupData !== undefined ? signupData?._id : locations?.state?.employerId !== undefined ? locations?.state?.employerId : userProfileData?._id
            }`,
            {
                method: 'get'
            }
        );

        if (response.status === 200) {
            setRecentJobs(response.data.data?.filter((val) => val?.status === 'active') || []);
        } else showErrorToast(toast, 'Error', response?.data?.decs);
    };
    const updateProfile = async (data) => {
        let response = await sendRequest(`${BASE_URL}api/v1/user/updateProfile`, {
            method: 'post',
            payload: locations?.state?.employerId ? { ...data, updateUser: locations?.state?.employerId } : data,

            headers: {
                'x-access-token': userToken
            }
        });

        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            userProfile();
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    useEffect(() => {
        if (!signupData) {
            userProfile();
        } else {
            setProfile(signupData);
        }
        getRecentJobs();
    }, []);

    const benefitsName = employeBenifits.map((el) => el.name);

    const salaryRange = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">{`$${formatPrice(rowData?.salaryRange?.min)} - $${formatPrice(rowData?.salaryRange?.max)}/${
                rowData?.salaryRange?.type
            }`}</div>
        );
    };
    const CreatedAt = (rowData) => {
        return <div className="flex align-items-center gap-2">{moment(rowData.createdAt).format('MMM Do YYYY')}</div>;
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-start gap-2 mr-2">
                <img
                    alt={rowData.role}
                    src={viewIcon}
                    width="25"
                    onClick={() => {
                        setVisibleAddLocation(true);
                        setJobData(rowData);
                    }}
                    data-pr-tooltip="View Job Post"
                    data-pr-position="top"
                    height="25"
                    className="cursor-pointer custom-target-icon"
                />
                {userProfileData?.role === 'employer' && (
                    <>
                        <img
                            alt={rowData.role}
                            src={editIcon1}
                            width="25"
                            height="25"
                            className="cursor-pointer custom-target-icon"
                            data-pr-tooltip="Edit Job Post"
                            data-pr-position="top"
                            onClick={() => {
                                navigate('/view/employer/profile/', { state: { jobID: rowData?._id, edit: true } });
                            }}
                        />
                        <img
                            alt={rowData.role}
                            src={deleteIcon}
                            width="25"
                            height="25"
                            className="cursor-pointer custom-target-icon"
                            data-pr-tooltip="Delete Job Post"
                            data-pr-position="left"
                            onClick={() => {
                                setConfirmDelete(rowData?._id);
                                setConfirmDeleteModal(true);
                            }}
                        />
                    </>
                )}
            </div>
        );
    };
    const actionTemplates = (rowData) => {
        return (
            <div className="flex justify-content-start gap-2 mr-2">
                <a href={`/view/employer/profile/${rowData?._id}`} target="_blank" style={{ minWidth: '100px' }}>
                    <img
                        alt={rowData.role}
                        src={viewIcon}
                        width="25"
                        data-pr-tooltip="View Job Post"
                        data-pr-position="top"
                        height="25"
                        className="cursor-pointer custom-target-icon"
                    />
                </a>
            </div>
        );
    };
    const deleteRecentJobs = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/job/${confirmDelete}`, {
            method: 'post',
            headers: {
                'x-access-token': userToken
            }
        });

        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            getRecentJobs();
        } else showErrorToast(toast, 'Error', response?.data?.desc);
        setConfirmDeleteModal(false);
    };

    const deleteImage = async (path) => {
        await sendRequest(`${BASE_URL}api/v1/media/delete`, {
            method: 'post',
            payload: {
                filePath: path
            },
            headers: {
                'x-access-token': userToken
            }
        });
        let filteredMedia = myProfile?.preferences?.employer?.officeImages.filter((image) => image !== path);
        let updateProfileResponse = await sendRequest(`${BASE_URL}api/v1/user/updateProfile`, {
            method: 'post',
            payload: {
                preferences: {
                    employer: {
                        officeImages: filteredMedia
                    }
                },
                ...(locations?.state?.employerId ? { updateUser: locations?.state?.employerId } : {})
            },
            headers: {
                'x-access-token': userToken
            }
        });
        showSuccessToast(toast, 'Success', updateProfileResponse?.data?.desc);
        userProfile();
    };
    const socialinks = myProfile?.preferences?.employer?.socialAccounts;
    let icons = [
        { icons: fbIcon, color: '#007cc8', profile: socialinks?.Facebook ? myProfile?.preferences?.employer?.socialAccounts?.Facebook : '' },
        {
            icons: twitterIcon,
            color: '#000',
            profile: socialinks?.Twitter ? socialinks?.Twitter : ''
        },
        {
            icons: linkdinIcon,
            color: '#005589',
            profile: socialinks?.Linkedin ? socialinks?.Linkedin : ''
        },
        {
            icons: youtubIcon,
            color: '#F80404',
            profile: socialinks?.google ? socialinks?.google : ''
        },
        {
            icons: instaIcon,
            color: 'linear-gradient(209.08deg, #EA0BAD 8.82%, #691FEE 38.02%, #F6225A 65.17%, #E9B900 87.79%)',
            profile: socialinks?.Instagram ? socialinks?.Instagram : ''
        },
        {
            icons: tiktokIcon,
            color: '#000000',
            profile: socialinks?.TikTok ? socialinks?.TikTok : ''
        },
        {
            icons: yelp,
            color: '#bb0000',
            profile: socialinks?.Yelp ? socialinks?.Yelp : ''
        },
        {
            icons: linkedin,
            color: '#007ab9',
            profile: socialinks?.LinkedIn ? socialinks?.LinkedIn : ''
        },
        {
            icons: snapchat,
            color: '#ffc91b',
            profile: socialinks?.Snapchat ? socialinks?.Snapchat : ''
        },
        {
            icons: google,
            color: '#fff',
            profile: socialinks?.Google ? socialinks?.Google : ''
        }
    ];

    return (
        <div className={signupData && 'p-5'} id={signupData ? 'hidepencil' : 'showPencil'}>
            <h1 className="search-candidates-heading m-0">Profile</h1>
            <div className="grid mt-5 employer-profile">
                <div className="col-12 sm:col-5 md:col-4 lg:col-5 xl:col-4">
                    <div className="grid mt-5 relative">
                        <div className="col-4 sm:col-12 md:col-12 lg:col-3 xl:col-4">
                            <Image
                                src={myProfile?.preferences?.employer?.companyLogo !== '' ? imageDisplay(myProfile?.preferences?.employer?.companyLogo) : employer}
                                alt="office logo"
                                className="logo-employee"
                            />
                        </div>
                        <div className="col-8 sm:col-12 md:col-12 lg:col-8 xl:col-8">
                            <h1 className="job-card-city-heading mt-3">
                                {`${myProfile?.name}`}
                                {userProfileData?.role !== 'recruiter' && (
                                    <span>
                                        <Image
                                            src={editIcon}
                                            alt="Logo"
                                            className="pt-2 mr-3 cursor-pointer edit-blue-image"
                                            onClick={() => {
                                                setEmployerBasicEdit(true);
                                            }}
                                        />
                                    </span>
                                )}
                            </h1>

                            {myProfile?.preferences?.employer?.officeAddresses.length > 0 && (
                                <div className="flex ">
                                    <Image src={locationIcon} alt="Logo" className="p-0" />
                                    <p className="job-card-title-heading my-0  px-3 py-0">{`${
                                        myProfile?.preferences?.employer?.officeAddresses.length > 0 ? myProfile?.preferences?.employer?.officeAddresses[0] : ''
                                    }`}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-3 mt-5">
                        {icons
                            ?.filter((val) => val.profile !== '')
                            .map((val, i) => (
                                <div className="social-media-icon cursor-pointer" key={i} style={{ background: val?.color }}>
                                    <a href={val?.profile} target="_blank">
                                        <Image src={val?.icons} alt="social media icon" />
                                    </a>
                                </div>
                            ))}
                    </div>
                    <hr className="horizontal-line mt-8" />

                    <h1 className="info-txt mt-5 relative">
                        Information
                        {userProfileData?.role !== 'recruiter' && (
                            <span>
                                <Image
                                    src={editIcon}
                                    alt="Logo"
                                    className="pt-2 mr-3 cursor-pointer edit-blue-image"
                                    onClick={() => {
                                        setemployeInfoEdit(true);
                                    }}
                                />
                            </span>
                        )}
                    </h1>
                    <p className="mission-statment-txt mt-3">
                        <span style={{ fontWeight: '800' }}>Mission Statement: </span>
                        {`${myProfile?.preferences?.employer?.companyAbout}`}
                    </p>

                    <div className="grid mt-5">
                        <div className="col-5 office-info-heading">Office Specialty:</div>
                        <div className="col-5 office-info-heading-data specialty">
                            {myProfile?.preferences?.employer?.officeSpecialties?.slice(0, 3).map((val, index) => (
                                <p>
                                    {val} {index !== myProfile?.preferences?.employer?.officeSpecialties.length - 1 && ', '}
                                </p>
                            ))}
                        </div>
                        <div className="col-5 office-info-heading">Office Size:</div>
                        <div className="col-5 office-info-heading-data"> {`${myProfile?.preferences?.employer?.noOfEmployees} Employees`}</div>
                        <div className="col-5 office-info-heading">Number of Offices:</div>
                        <div className="col-5 office-info-heading-data">{`${myProfile?.preferences?.employer?.noOfOffices} Offices`}</div>
                        <div className="col-5 office-info-heading">Indusrty:</div>
                        <div className="col-5 office-info-heading-data">{`${myProfile?.preferences?.employer?.interestedIndustry}`}</div>
                        <div className="col-5 office-info-heading">Office setting:</div>
                        <div className="col-5 office-info-heading-data">{`${myProfile?.preferences?.employer?.bestDescribeOffice}`}</div>{' '}
                        <div className="col-5 office-info-heading">Visa Sponsorship:</div>
                        <div className="col-5 office-info-heading-data">{`No`}</div>
                    </div>

                    <hr className="horizontal-line mt-6 " />

                    <h1 className="employee-batch-match-profile-name mt-5 relative" style={{ fontSize: '2rem' }}>
                        What Our Employees Love
                        {userProfileData?.role !== 'recruiter' && (
                            <span>
                                <Image
                                    src={editIcon}
                                    alt="Logo"
                                    className="pt-2 mr-3 cursor-pointer edit-blue-image"
                                    onClick={() => {
                                        setemployeLoveEdit(true);
                                    }}
                                />
                            </span>
                        )}
                    </h1>

                    {myProfile?.preferences?.employer?.employerLoveAbout?.map((most, index) => (
                        <div
                            key={index}
                            className="flex flex-wrap justify-content-start align-items-center employee-love-container mt-3"
                            style={{ maxWidth: '301px', width: '100%' }}
                        >
                            <Image src={index % 2 === 0 ? workEnv : careerImage} alt="Logo" className="pl-3" width="34px" height="34px" />
                            <h1 className="employee-love-container-txt m-0 pl-3">{most}</h1>
                        </div>
                    ))}
                </div>
                <div className="col-12 sm:col-7 md:col-8 lg:col-7 xl:col-8 candidate-view-profile-right-container">
                    <div className="mt-3 mx-2" style={{ background: '#F7FAFC', borderRadius: '10px', paddingBottom: '10px' }}>
                        <div className="heading-container-employee-2 mt-5">
                            <h1 className="heading-container-text-2 my-3">Benefits</h1>
                            {userProfileData?.role !== 'recruiter' && (
                                <Image
                                    src={editIcon}
                                    onClick={() => {
                                        setBenefitEdit(true);
                                    }}
                                    alt="Logo"
                                    className="pt-2 mr-3 cursor-pointer"
                                />
                            )}
                        </div>
                        <div className="work-history">
                            <div className="grid mt-1 mx-5">
                                {myProfile?.preferences?.employer?.employerOfferBenefits?.map((benefit, index) => (
                                    <div key={index} className="col-12 sm:col-12 md:col-6 lg:col-4">
                                        <div key={index} className="flex justify-content-start align-items-center available-questions-options cursor-pointer p-2">
                                            <Image src={employeBenifits.find((value) => value.name === benefit)?.image} alt="Logo" className="benefit-icons px-2" />
                                            <div className="screen-benefit-text w-max">{benefit}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="my-3 mx-2" style={{ background: '#F7FAFC', borderRadius: '10px', paddingBottom: '20px' }}>
                        <div className="heading-container-employee-2 mt-3 ">
                            <h1 className="heading-container-text-2 my-3">Office Gallery</h1>

                            {userProfileData?.role !== 'recruiter' && (
                                <Image
                                    src={editIcon}
                                    onClick={() => {
                                        setemployeOfficeGalleryEdit(true);
                                    }}
                                    alt="Logo"
                                    className=" pt-2 mr-3 cursor-pointer"
                                />
                            )}
                        </div>
                        <div className="work-history">
                            <div className="grid mt-5 mx-2">
                                {myProfile?.preferences?.employer?.officeImages.length > 0 &&
                                    myProfile?.preferences?.employer?.officeImages?.map((image, index) => (
                                        <div key={index} className="col-4 flex justify-content-center relative">
                                            {userProfileData?.role !== 'recruiter' && !signupData && (
                                                <Image
                                                    src={deleteIcon}
                                                    alt="delete image"
                                                    width="20px"
                                                    height="20px"
                                                    style={{ position: 'absolute', top: 0, right: 0, padding: 10, cursor: 'pointer' }}
                                                    onClick={() => deleteImage(image)}
                                                />
                                            )}
                                            <Image src={imageDisplay(image)} alt="office image" className="profile-office-images" />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <Tooltip target=".custom-target-icon" />

                    <>
                        <div className="my-3 mx-2" style={{ background: '#F7FAFC', borderRadius: '10px', paddingBottom: '20px' }}>
                            <div className="heading-container-employee-2 mt-3 ">
                                <h1 className="heading-container-text-2 my-3">Recent Job Postings</h1>
                            </div>
                            <div className="grid mt-5 mx-1 recent-job-data-container" style={{ height: '40vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                <DataTable
                                    value={recentJobs}
                                    className="interview-tracker-datatable w-full mx-2 xl:mx-4"
                                    resizableColumns={true}
                                    responsiveLayout="scroll"
                                    paginatorClassName="interview-tracker-paginator"
                                    paginatorLeft
                                >
                                    <Column field="position" header="Role"></Column>
                                    <Column body={CreatedAt} header="Date"></Column>

                                    <Column field="jobLocation" header="Location"></Column>

                                    <Column body={salaryRange} header="Expected Salary"></Column>
                                    {!signupData && <Column header="Action" body={actionTemplate}></Column>}
                                    {userProfileData?.role === 'candidate' && <Column header="Action" body={actionTemplates}></Column>}
                                </DataTable>
                            </div>
                        </div>
                    </>
                    {!signupData && (
                        <>
                            <EditEmployerBasic
                                updateProfile={updateProfile}
                                userProfile={userProfile}
                                setProfile={setProfile}
                                myProfile={myProfile}
                                visible={employerBasicEdit}
                                setVisible={setEmployerBasicEdit}
                                logo={officeLogoImage}
                                companyName={companyName}
                                setcompanyName={setcompanyName}
                                location={location}
                                setlocation={setlocation}
                                socialLinks={socialLinks}
                                setSocialLinks={setSocialLinks}
                            />

                            {employeInfoEdit && (
                                <EditEmployerInformation
                                    updateProfile={updateProfile}
                                    setProfile={setProfile}
                                    myProfile={myProfile}
                                    visible={employeInfoEdit}
                                    setVisible={setemployeInfoEdit}
                                    missionValue={missionValue}
                                    setMissionValue={setMissionValue}
                                    officeSpecialty={officeSpecialty}
                                    setofficeSpecialty={setofficeSpecialty}
                                    officeSize={officeSize}
                                    setofficeSize={setofficeSize}
                                    officeCount={officeCount}
                                    setofficeCount={setofficeCount}
                                    industry={industry}
                                    setindustry={setindustry}
                                    officeSetting={officeSetting}
                                    setofficeSetting={setofficeSetting}
                                />
                            )}
                            {employeLoveEdit && (
                                <EditEmployeesLove
                                    updateProfile={updateProfile}
                                    setProfile={setProfile}
                                    myProfile={myProfile}
                                    visible={employeLoveEdit}
                                    setVisible={setemployeLoveEdit}
                                />
                            )}

                            {benefitEdit && (
                                <EditEmployeSkills
                                    updateProfile={updateProfile}
                                    setProfile={setProfile}
                                    myProfile={myProfile?.preferences?.employer?.employerOfferBenefits}
                                    visible={benefitEdit}
                                    setVisible={setBenefitEdit}
                                    data={benefitsName}
                                />
                            )}
                            {employeOfficeGalleryEdit && (
                                <EditEmployerOfficeGallery
                                    updateProfile={updateProfile}
                                    setProfile={setProfile}
                                    myProfile={myProfile}
                                    visible={employeOfficeGalleryEdit}
                                    setVisible={setemployeOfficeGalleryEdit}
                                    employeeImages={employeeImages}
                                    setemployeeImages={setemployeeImages}
                                    userProfile={userProfile}
                                />
                            )}
                        </>
                    )}
                    <Dialog
                        visible={visibleAddLocation}
                        style={{ width: '90vw' }}
                        maximizable
                        modal={false}
                        className="dialog-box previewdialog"
                        onHide={() => setVisibleAddLocation(false)}
                    >
                        <div className="px-4">
                            <JobPostingPreview from={'candidate'} companyAbout={myProfile?.preferences?.employer?.companyLogo} jobID={'123'} jobData={jobData} />
                        </div>
                    </Dialog>

                    <Dialog header="Header" visible={confirmDeleteModal} style={{ width: '50vw' }} onHide={() => setConfirmDeleteModal(false)}>
                        <h3 className="m-0 p-5 text-center">Are you sure you want to delete job ? </h3>
                        <div className="text-center mb-5">
                            <Button label="No" icon="pi pi-times border-0" onClick={() => setConfirmDeleteModal(false)} className="p-button-text" />
                            <Button label="Yes" icon="pi pi-check" className="border-0" onClick={deleteRecentJobs} autoFocus />
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Profile;
