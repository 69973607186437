import React, { useEffect } from 'react';
import { formatPrice, getLoggedUser } from '../../../helpers/utils';
import { loadStripe } from '@stripe/stripe-js';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';
import { ProgressSpinner } from 'primereact/progressspinner';
import { updateJobData } from '../../../redux/userSlice';
import { useDispatch } from 'react-redux';
import { showErrorToast, showInfoToast, showSuccessToast } from '../../../helpers/toast-helper';
import { ToastContext } from '../../../context/ToastContext';
import { useContext } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { ConfirmPopup } from 'primereact/confirmpopup';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPKEY);

const PaymentPlain = (props) => {
    const userToken = getUserToken();
    const { setVisible, setPriceLogin, promoCode } = props;
    const dispatch = useDispatch();
    const userProfile = getLoggedUser();
    const msgs = useRef(null);
    const [visible1, setVisible1] = useState(false);
    const [islooading, setislooading] = useState(false);

    const toast = useContext(ToastContext);

    const handleCheckout = async () => {
        if (userProfile) {
            if (props?.userData?.isSubscribed && props?.userData?.jobLimit > 0) {
                showInfoToast(toast, 'Info', 'You can change plan unless you cancel previous subscription');
            } else {
                try {
                    setislooading(true);
                    dispatch(updateJobData(props?.jobData));
                    const stripe = await stripePromise;
                    const response = await sendRequest(`${BASE_URL}api/v1/subscription/create`, {
                        method: 'post',
                        payload: {
                            planId: props?.data._id,
                            productId: props?.data.productId,
                            promoCode
                        },
                        headers: {
                            'x-access-token': userToken
                        }
                    });
                    const session = await response.data;
                    const result = await stripe.redirectToCheckout({ sessionId: session.id });
                    if (result.error) {
                        console.error(result.error.message);
                    }
                } catch (error) {
                    showErrorToast(toast, 'Error', 'Something went wrong! Please try again later');
                    throw new Error(error);
                } finally {
                    setislooading(false);
                }
            }
        } else {
            setPriceLogin(true);
            setVisible(false);
        }
    };

    const handleConfirmCancelSubscription = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/subscription/cancel`, {
            method: 'post',
            headers: {
                'x-access-token': userToken
            }
        });

        if (response.status === 201) {
            showSuccessToast(toast, 'Info', 'Subscription Cancelled');
            setVisible(false);
            if (props?.getuserData) props?.getuserData();
        } else {
            showErrorToast(toast, 'Error', response?.data?.desc);
        }
    };

    return (
        <div
            className={`flex flex-column align-items-center text-center cards-plans  ${
                (props?.selectedPlain === props?.data._id && props?.selectedPlain !== undefined) ||
                (props?.userData?.isSubscribed && props?.userData?.planId === props?.data._id)
                    ? 'plain-container-selected'
                    : userProfile
                    ? 'plain-container loggedin'
                    : 'plain-container '
            }`}
        >
            <div className="payment-plain-btn w-full px-3 py-2 mb-2" style={{ background: 'black', color: 'white' }}>
                {props?.data.name}
            </div>
            {props?.data?.category === 'yearly' && (
                <h1 className="payment-plain-amount mt-1 mb-1">
                    <span style={{ textDecoration: 'line-through' }}>
                        ${props?.data?.name === 'standard' ? '1430' : props?.data?.name === 'professional' ? '2679' : '4790'}
                    </span>
                    {props?.selectedStage == 'Monthly Offers' ? '/month' : '/year'}
                </h1>
            )}
            <h1 className="payment-plain-amount mb-2 mt-0" style={{ minHeight: '40px' }}>
                ${props?.data?.category === 'yearly' ? formatPrice(props?.data?.price) : formatPrice(props?.data?.price)}
                {props?.selectedStage == 'monthly' ? '/month' : '/year'}
            </h1>

            <ul className="payment-plain-text-2">
                {props.data?.features?.map((val, i) => (
                    <li style={{ listStyle: 'inside' }} className="text-left my-1" key={i}>
                        {val}
                    </li>
                ))}
            </ul>
            {!islooading && (
                <div
                    className={`become-btn mt-3 cursor-pointer ${
                        props?.userData?.isSubscribed && props?.userData?.planId === props?.data._id && props?.userData?.jobLimit > 0 && 'subscribed'
                    } `}
                    onClick={() => {
                        if (props?.userData?.isSubscribed && props?.userData?.planId === props?.data._id && props?.userData?.jobLimit > 0) {
                        } else handleCheckout();
                    }}
                >
                    {props?.userData?.isSubscribed && props?.userData?.planId === props?.data._id ? 'Subscribed' : 'Get Started'}
                </div>
            )}
            {islooading && (
                <ProgressSpinner style={{ width: '20px', height: '20px' }} className="mt-4" strokeWidth="6" fill="var(--surface-ground)" animationDuration=".5s" />
            )}
            {props?.userData?.isSubscribed && props?.userData?.planId === props?.data._id && (
                <p ref={msgs} onClick={() => setVisible1(true)} className="mr-2 mt-2 cancel-subsc">
                    Cancel subscription
                </p>
            )}
            <ConfirmPopup
                target={msgs.current}
                visible={visible1}
                onHide={() => setVisible1(false)}
                message="Are you sure you want to cancel the subscription?"
                accept={handleConfirmCancelSubscription}
                reject
            />
        </div>
    );
};

export default PaymentPlain;
