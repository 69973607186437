import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';

import tooth from '../../../assets/images/tooth_image.svg';
import optometry from '../../../assets/images/optometry_image.svg';
import chiropractic from '../../../assets/images/chiropractic_image.svg';
import crossIcon from '../../../assets/images/cross_black_icon.svg';

const EditEmployerInformation = (props) => {
    const [selectedOfficeSpecialities, setselectedOfficeSpecialities] = useState([]);
    const [selectedEmployeeCount, setselectedEmployeeCount] = useState();
    const [selectedOfficeCount, setselectedOfficeCount] = useState();
    const [selectedIndustry, setselectedIndustry] = useState();
    const [selectedOfficeSetting, setselectedOfficeSetting] = useState();

    const employeCount = ['1-10', '11-20', '21-50', '51-100', '101-500', '500+'];
    const officesCount = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'];

    const officeSpecialities = ['General', 'Pediatric', 'Orthodontic', 'Cosmetic', 'Periodontal', 'Endodontic', 'Oral & Maxillofacial Surgery', 'Prosthodontic'];
    const officeSetting = ['Corporate', 'Private', 'Family'];

    const industries = [
        {
            name: 'Dental',
            image: tooth
        },
        {
            name: 'Optometry',
            image: optometry
        },
        {
            name: 'Chiropractic',
            image: chiropractic
        }
    ];

    const specialitiesHandler = (speciality) => {
        const updateSelectedOfficeSpecialities = selectedOfficeSpecialities.find((el) => el === speciality);

        if (updateSelectedOfficeSpecialities) {
            const selectedOfficeSpecialitiesfilter = selectedOfficeSpecialities.filter((el) => el !== speciality);

            setselectedOfficeSpecialities(selectedOfficeSpecialitiesfilter);
        } else {
            setselectedOfficeSpecialities([...selectedOfficeSpecialities, speciality]);
        }
    };

    const checkSpecialities = (name) => {
        const indexOfEmployee = selectedOfficeSpecialities.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setselectedOfficeSpecialities(props?.officeSpecialty);
        setselectedEmployeeCount({ count: props?.officeSize });
        setselectedOfficeCount({ office: props?.officeCount });
        setselectedIndustry({ name: props?.industry });
        setselectedOfficeSetting({ setting: props?.officeSetting });
    }, []);
    return (
        <Dialog visible={props?.visible} position={'center'} className="dental-tools-edit" onHide={() => props?.setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Informations</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => props?.setVisible(false)}
                    />
                </div>

                <p className="employer-questions-heading mt-5">Add your company’s tagline or mission statement</p>

                <InputTextarea
                    className="employe-textarea mt-3 w-full border-0"
                    placeholder="our best life begins with a smile."
                    value={props?.missionValue}
                    onChange={(e) => props?.setMissionValue(e.target.value)}
                    rows={5}
                    cols={30}
                />

                <p className="employer-questions-heading mt-5">Which of these specialties best describes your office?</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {officeSpecialities?.map((speciality, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3  ${
                                checkSpecialities(speciality) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => specialitiesHandler(speciality)}
                        >
                            <div className={`${checkSpecialities(speciality) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {speciality}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="employer-questions-heading mt-5">How many employees are in your company?</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {employeCount?.map((count, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3  ${
                                selectedEmployeeCount?.count === count
                                    ? 'available-questions-options-select cursor-pointer'
                                    : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => {
                                setselectedEmployeeCount({ count });
                                props?.setofficeCount(count);
                            }}
                        >
                            <div className={`${selectedEmployeeCount?.count === count ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {count}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="employer-questions-heading mt-5">How many offices do you have?</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {officesCount?.map((office, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                selectedOfficeCount?.office === office
                                    ? 'available-questions-options-select cursor-pointer'
                                    : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => {
                                setselectedOfficeCount({ office });
                                props?.setofficeSize(office);
                            }}
                        >
                            <div className={`${selectedOfficeCount?.office === office ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {office}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="employer-questions-heading mt-5">Which industry are you interested in?</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {industries?.map((industry, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                selectedIndustry?.name === industry.name
                                    ? 'available-questions-options-select cursor-pointer'
                                    : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => {
                                if (industry.name === 'Dental') {
                                    setselectedIndustry({ name: industry.name });
                                    props?.setindustry(industry.name);
                                }
                            }}
                        >
                            <Image
                                src={industry.image}
                                alt="Logo"
                                className={`${(selectedIndustry?.name === industry.name && 'benefit-image-select') || 'benefit-image'}`}
                            />
                            <div
                                className={`${
                                    (selectedIndustry?.name === industry.name && 'available-questions-options-select-text') || 'available-questions-options-text'
                                }`}
                            >
                                {industry.name}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="employer-questions-heading mt-5">Which of these best describes your office setting?</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {officeSetting?.map((setting, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                selectedOfficeSetting?.setting === setting
                                    ? 'available-questions-options-select cursor-pointer'
                                    : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => {
                                setselectedOfficeSetting({ setting });
                                props?.setofficeSetting(setting);
                            }}
                        >
                            <div
                                className={`${
                                    selectedOfficeSetting?.setting === setting ? 'available-questions-options-select-text' : 'available-questions-options-text'
                                }`}
                            >
                                {setting}
                            </div>
                        </div>
                    ))}
                </div>

                <hr className="line-horizontal mt-5" />

                <Button
                    type="button"
                    className="onboarding-btn w-max px-6 mt-5"
                    onClick={() => {
                        let data = {
                            preferences: {
                                employer: {
                                    companyAbout: props?.missionValue,
                                    bestDescribeOffice: selectedOfficeSetting.setting,
                                    officeSpecialties: selectedOfficeSpecialities,
                                    noOfEmployees: selectedEmployeeCount.count,
                                    noOfOffices: selectedOfficeCount.office,
                                    interestedIndustry: selectedIndustry.name
                                }
                            }
                        };
                        props?.updateProfile(data);
                        props?.setVisible(false);
                    }}
                >
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditEmployerInformation;
