import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';

import addIcon from '../../../assets/images/add_image.svg';
import pto from '../../../assets/images/pto_image.svg';
import bonus from '../../../assets/images/bonus_image.svg';
import medical from '../../../assets/images/medical_image.svg';
import education from '../../../assets/images/education_image.svg';
import flexableWork from '../../../assets/images/flexable_work_hours_image.svg';
import paidLeaves from '../../../assets/images/paid_leaves_image.svg';
import lifeInsurance from '../../../assets/images/life_insurance_image.svg';
import dentalCare from '../../../assets/images/dental_care_image.svg';
import businessFinance from '../../../assets/images/business_and_finance_image.svg';
import disable from '../../../assets/images/disabled_image.svg';
import dumbbell from '../../../assets/images/dumbbell_image.svg';
import tooth from '../../../assets/images/tooth_image.svg';
import rockingImage from '../../../assets/images/rocking_image.svg';

const EmployerOnboardingScreenThree = ({ employerData, setEmployerData }) => {
    const [benefitValue, setbenefitValue] = useState();
    const [selectedBenefits, setSelectedBenefits] = useState([]);
    const [employeBenifits, setemployeBenifits] = useState([
        {
            name: 'PTO',
            image: pto
        },
        {
            name: 'Employee Bonuses',
            image: bonus
        },
        {
            name: 'Medical',
            image: medical
        },
        {
            name: 'Dental',
            image: tooth
        },
        {
            name: 'Education',
            image: education
        },
        {
            name: 'Flexible work hours',
            image: flexableWork
        },
        {
            name: 'Paid Holidays',
            image: paidLeaves
        },
        {
            name: 'Life Insurance',
            image: lifeInsurance
        },

        {
            name: 'Free dental work for employees',
            image: dentalCare
        },
        {
            name: 'Profit-sharing program',
            image: businessFinance
        },
        {
            name: 'Short-term disability',
            image: disable
        },
        {
            name: 'Gym membership',
            image: dumbbell
        },
        {
            name: '401k',
            image: rockingImage
        }
    ]);

    const benefitsHandler = (name) => {
        const filterBenefit = employeBenifits.find((benefit) => benefit.name === name);
        const selectedBenefitNames = selectedBenefits?.map((item) => item.name);

        const updateSelectedBenefits = selectedBenefits.find((benefit) => benefit?.name === name);

        if (updateSelectedBenefits) {
            const selectedBenefitsfilter = selectedBenefits.filter((benefit) => benefit?.name !== name);
            const selectedBenefitsfilterNames = selectedBenefitsfilter?.map((item) => item.name);

            setSelectedBenefits(selectedBenefitsfilter);
            setEmployerData({ ...employerData, employerOfferBenefits: selectedBenefitsfilterNames });
        } else {
            setSelectedBenefits([...selectedBenefits, filterBenefit]);
            setEmployerData({ ...employerData, employerOfferBenefits: [...selectedBenefitNames, filterBenefit.name] });
        }
    };

    const checkEmployeesBenefit = (name) => {
        const indexOfEmployee = selectedBenefits.findIndex((employee) => employee.name === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (employerData.employerOfferBenefits.length) {
            setemployeBenifits((prevBenf) => {
                const updatedBenifits = [...prevBenf];
                for (let item of employerData.employerOfferBenefits) {
                    if (!updatedBenifits.some((benefit) => benefit.name === item)) {
                        updatedBenifits.push({ name: item, image: '' });
                    }
                }

                const filteredBenefits = prevBenf.filter((benefit) => employerData.employerOfferBenefits.some((el) => el === benefit.name));
                setSelectedBenefits(filteredBenefits);
                return updatedBenifits;
            });
        }
    }, []);

    return (
        <div>
            <p className="employer-questions-heading mt-5">Select all of the benefits you offer your employees?</p>

            <div className="flex align-item-center justify-content-between md:w-10 add-button-container mt-3">
                <InputText
                    placeholder="Other Benefits"
                    value={benefitValue}
                    onChange={(e) => {
                        setbenefitValue(e.target.value);
                    }}
                    className="border-0 add-button-container-input w-9"
                />

                <div
                    className="flex justify-content-between add-button cursor-pointer mr-2"
                    onClick={() => {
                        if (benefitValue) {
                            setemployeBenifits([...employeBenifits, { name: benefitValue, image: '' }]);
                            setbenefitValue('');
                        }
                    }}
                >
                    <Image src={addIcon} alt="add" />
                    <div className="add-button-text">Add</div>
                </div>
            </div>
            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {employeBenifits?.map((benefit, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3  ${
                            checkEmployeesBenefit(benefit.name) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                        }`}
                        onClick={() => benefitsHandler(benefit.name)}
                    >
                        <Image src={benefit.image} alt="Logo" className={`${(checkEmployeesBenefit(benefit.name) && 'benefit-image-select') || 'benefit-image'}`} />
                        <div className={`${(checkEmployeesBenefit(benefit.name) && 'available-questions-options-select-text') || 'available-questions-options-text'}`}>
                            {benefit.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmployerOnboardingScreenThree;
