import React from 'react';
import { Helmet } from 'react-helmet';

const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID || 'G-FX0RWFZC40';

const GoogleAnalytics = () => (
    <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_MEASUREMENT_ID}`}></script>
        <script>
            {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GOOGLE_ANALYTICS_MEASUREMENT_ID}');
      `}
        </script>
    </Helmet>
);

export default GoogleAnalytics;
