import React, { useState, useContext, useEffect } from 'react';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';

import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';
import { ToastContext } from '../../../context/ToastContext';
import { sendRequest } from '../../../helpers/utils';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import Profile from '../../../pages/profile/Profile';

const EmployerOnboardingScreenTwelve = ({ employerData, setEmployerData, setVisible, setCurrentStep, currentStep, setEmail, signupBasicInfo, setSignupBasicInfo }) => {
    const [previewData, setPreveiwData] = useState(false);
    const [previewEmployer, setPreveiwEmployer] = useState();

    const toast = useContext(ToastContext);

    const schema = yup.object().shape({
        email: yup.string().email('Must be valid Email').required('Business Email is required!'),
        password: yup.string().required('Password is required!'),
        mobileNumber: yup.number().required('offfice Number is required!'),
        companyName: yup.string().required('Company Name is required!'),
        firstName: yup.string().required('First Name is required!'),
        lastName: yup.string().required('Last Name is required!')
    });

    // Form Intial Values - If its New Form
    const initialValues = {
        email: '',
        password: '',
        mobileNumber: '',
        companyName: '',
        firstName: '',
        lastName: ''
    };
    let signupData = {};
    const handleSubmitPreview = async () => {
        try {
            const formDataToSend = new FormData();
            const officeImagesToSend = new FormData();

            formDataToSend.append('files', employerData.companyLogo);

            if (employerData.officeImages.length) {
                employerData.officeImages.forEach((e) => officeImagesToSend.append('files', e));
            }

            // Make the Axios POST request
            const companLogoResponse = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/media/upload`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const officeImagesResponse = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/media/upload`, officeImagesToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const updatedImages = officeImagesResponse.data.data.map((e) => e.path);
            signupData = {
                name: signupBasicInfo?.companyName,
                fullName: '',
                email: '',
                phoneNumber: '',
                password: '',
                role: 'employer',
                preferences: {
                    employer: { ...employerData, companyLogo: companLogoResponse?.data?.data[0]?.path, officeImages: updatedImages ? updatedImages : [] }
                }
            };
            setPreveiwData(true);
            setPreveiwEmployer(signupData);
            // Reset the form after successful submission (optional)
        } catch (error) {
            // Handle errors (e.g., server error or network issue)
            console.error('Error:', error);
            showErrorToast(toast, 'Sign Up', error);
        }
    };
    const handleSubmit = async (values) => {
        const { email, password, mobileNumber, companyName, firstName, lastName } = values;
        setEmail(email);
        try {
            const formDataToSend = new FormData();
            const officeImagesToSend = new FormData();

            formDataToSend.append('files', employerData.companyLogo);

            if (employerData.officeImages.length) {
                employerData.officeImages.forEach((e) => officeImagesToSend.append('files', e));
            }

            // Make the Axios POST request
            const companLogoResponse = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/media/upload`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const officeImagesResponse = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/media/upload`, officeImagesToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const updatedImages = officeImagesResponse.data.data.map((e) => e.path);

            signupData = {
                name: companyName,
                email: email,
                phoneNumber: mobileNumber,
                password: password,
                fullName: `${firstName} ${lastName}`,
                role: 'employer',
                preferences: {
                    employer: { ...employerData, companyLogo: companLogoResponse?.data?.data[0]?.path, officeImages: updatedImages ? updatedImages : [] }
                }
            };
            if (!previewData) {
                const signup = await sendRequest(`${process.env.REACT_APP_API_URL}api/v1/user/register`, {
                    payload: signupData
                });

                if (signup.status === 201) {
                    showSuccessToast(toast, 'Sign Up', signup?.data?.message);
                    setEmployerData({
                        user: '',
                        interestedIndustry: '',
                        employerLoveAbout: [],
                        employerOfferBenefits: [],
                        noOfEmployees: '',
                        noOfOffices: '',
                        bestDescribeOffice: '',
                        officeSpecialties: [],
                        companyUrl: '',
                        socialAccounts: {},
                        officeAddresses: [],
                        companyLogo: {},
                        companyAbout: '',
                        officeImages: []
                    });
                    setCurrentStep(currentStep + 1);
                } else {
                    showErrorToast(toast, 'Sign Up', signup?.data?.desc);
                }
            }

            // Reset the form after successful submission (optional)
        } catch (error) {
            // Handle errors (e.g., server error or network issue)
            console.error('Error:', error);
            showErrorToast(toast, 'Sign Up', error);
        }
    };

    useEffect(() => {
        initialValues.email = signupBasicInfo.email;
        initialValues.password = signupBasicInfo.password;
        initialValues.mobileNumber = signupBasicInfo.mobileNumber;
        initialValues.companyName = signupBasicInfo.companyName;
        initialValues.firstName = signupBasicInfo.firstName;
        initialValues.lastName = signupBasicInfo.lastName;
    }, []);

    return (
        <div>
            <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <p className="employer-questions-heading mt-5">Last step, Sign Up!</p>
                        <div className="grid mt-3">
                            <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                                <label htmlFor="firstName" className="employee-signup-input-label mb-2">
                                    First Name
                                </label>
                                <InputText
                                    id="firstName"
                                    type="text"
                                    name="firstName"
                                    className="employee-signup-input border-0"
                                    placeholder="First Name"
                                    value={values.firstName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setSignupBasicInfo({ ...signupBasicInfo, firstName: e.target.value });
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.firstName && errors.firstName && <p className="error">{errors.firstName}</p>}</div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                                <label htmlFor="lastName" className="employee-signup-input-label mb-2">
                                    Last Name
                                </label>
                                <InputText
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    className="employee-signup-input border-0"
                                    placeholder="Last Name"
                                    value={values.lastName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setSignupBasicInfo({ ...signupBasicInfo, lastName: e.target.value });
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.lastName && errors.lastName && <p className="error">{errors.lastName}</p>}</div>
                            </div>

                            <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                                <label htmlFor="companyName" className="employee-signup-input-label mb-2">
                                    Company Name
                                </label>
                                <InputText
                                    id="companyName"
                                    type="text"
                                    name="companyName"
                                    className="employee-signup-input border-0"
                                    placeholder="name"
                                    value={values.companyName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setSignupBasicInfo({ ...signupBasicInfo, companyName: e.target.value });
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.companyName && errors.companyName && <p className="error">{errors.companyName}</p>}</div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                                <label htmlFor="password" className="employee-signup-input-label mb-2">
                                    Password
                                </label>
                                <Password
                                    id="password"
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setSignupBasicInfo({ ...signupBasicInfo, password: e.target.value });
                                    }}
                                    placeholder="*********"
                                    promptLabel="Choose a password"
                                    weakLabel="Too simple"
                                    mediumLabel="Average complexity"
                                    strongLabel="Complex password"
                                    inputClassName="employee-signup-input border-0 w-full"
                                    toggleMask
                                />
                                <div className="error-message mt-1">{touched.password && errors.password && <p className="error">{errors.password}</p>}</div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                                <label htmlFor="email" className="employee-signup-input-label mb-2">
                                    Business Email
                                </label>
                                <InputText
                                    id="email"
                                    type="email"
                                    name="email"
                                    className="employee-signup-input border-0"
                                    placeholder="sampl@gmail.com"
                                    value={values.email}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setSignupBasicInfo({ ...signupBasicInfo, email: e.target.value });
                                    }}
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.email && errors.email && <p className="error">{errors.email}</p>}</div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                                <label htmlFor="mobileNumber" className="employee-signup-input-label mb-2">
                                    Office Phone Number
                                </label>
                                <InputText
                                    id="mobileNumber"
                                    type="text"
                                    name="mobileNumber"
                                    value={values.mobileNumber}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setSignupBasicInfo({ ...signupBasicInfo, mobileNumber: e.target.value });
                                    }}
                                    className="employee-signup-input border-0"
                                    placeholder="ex: 4088882424"
                                    aria-describedby="username-help"
                                />
                                <div className="error-message mt-1">{touched.mobileNumber && errors.mobileNumber && <p className="error">{errors.mobileNumber}</p>}</div>
                            </div>
                        </div>

                        <hr className="line-horizontal mt-3 " />
                        <div className="flex flex-wrap gap-3 mt-4">
                            <Button type="button" className="onboarding-btn-2 w-max px-6 " onClick={handleSubmitPreview}>
                                Preview
                            </Button>
                            <Button type="submit" className="onboarding-btn w-max px-6">
                                Sign Up
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>

            <Dialog visible={previewData} style={{ width: '90vw' }} maximizable modal={false} className="dialog-box signup-view" onHide={() => setPreveiwData(false)}>
                <Profile signupData={previewEmployer} />
            </Dialog>
        </div>
    );
};

export default EmployerOnboardingScreenTwelve;
