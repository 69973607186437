import React, { useEffect, useState } from 'react';
import ActiveEmployerCard from '../../cards/ActiveEmployerCard';
import employerImage from '../../../assets/images/employer_image_1.svg';
import { BASE_URL, formatPrice, imageDisplay, sendRequest } from '../../../helpers/utils';
import { Dialog } from 'primereact/dialog';
import Profile from '../../../pages/profile/Profile';

const ActiveEmployerSection = () => {
    const [activeEmployee, setActiveEmplyee] = useState('');
    const [previewData, setPreveiwData] = useState(false);
    const [previewEmployer, setPreveiwEmployer] = useState();

    const recentCandidates = async () => {
        let response;

        response = await sendRequest(`${BASE_URL}api/v1/user/activeEmployers`, {
            method: 'get'
        });

        if (response.status === 200) {
            setActiveEmplyee(response.data.data || []);
        }
    };

    useEffect(() => {
        recentCandidates();
    }, []);

    const activeEmployerData = (data) => {
        if (data) setPreveiwData(true);
        setPreveiwEmployer(data);
    };

    const activeEmp = {
        officeImage: employerImage,
        employerTitle: 'Benefits Cordinator',
        address: 'Dublin, CA',
        salaryRange: { min: 25, max: 35 },
        benefits: ['401k', 'Unlimited PTO']
    };

    return (
        <div className="w-full flex flex-column mt-6" style={{ background: '#F7FAFC' }}>
            <h1 className="secondary-heading my-1">Most Active Employers</h1>
            <div className="grid mt-5 mx-0 mb-0">
                {activeEmployee?.length > 0 &&
                    activeEmployee
                        .filter((item) => item?.employerData?.length !== 0)
                        .slice(0, 6)
                        .map((active, index) => {
                            return (
                                <div key={index} className="col-12 lg:col-4 md:col-6" onClick={() => activeEmployerData(active?.employerData[0])}>
                                    <ActiveEmployerCard
                                        officeImage={
                                            active.employerData[0]?.preferences?.employer?.officeImages[0]?.length > 0
                                                ? imageDisplay(active.employerData[0]?.preferences?.employer?.officeImages[0])
                                                : activeEmp?.officeImage
                                        }
                                        employerTitle={active.employerData[0]?.name || ''}
                                        profileImage={active?.employerData[0]?.preferences?.employer?.companyLogo}
                                        location={active?.employerData[0]?.preferences?.employer?.officeAddresses[0]}
                                        benefits={
                                            active?.employerData[0]?.preferences?.employer?.employerOfferBenefits?.length > 0
                                                ? active?.employerData[0]?.preferences?.employer?.employerOfferBenefits
                                                : activeEmp?.benefits
                                        }
                                    />
                                </div>
                            );
                        })}
            </div>
            <Dialog visible={previewData} style={{ width: '90vw' }} maximizable modal={false} className="dialog-box signup-view" onHide={() => setPreveiwData(false)}>
                <Profile signupData={previewEmployer} />
            </Dialog>
        </div>
    );
};

export default ActiveEmployerSection;
