import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';

import jobSeeker from '../../../assets/images/job_seeker_image.svg';
import employerImage from '../../../assets/images/employer_image_2.svg';
import tick from '../../../assets/images/tick_icon.svg';
import { initializeAnalytics, sendPageView } from '../../../G4A-Package';

const CandidateOnboardingOne = ({ candidateData, setCandidateData }) => {
    const [selectedAvailability, setselectedAvailability] = useState({});

    const [selectedUser, setselectedUser] = useState({
        name: 'Job Seeker',
        image: jobSeeker
    });
    const availability = ['Immediately', 'After 2 weeks notice', '1 Month', '3 Months'];

    const onboardingUser = [
        {
            name: 'Job Seeker',
            image: jobSeeker
        },
        {
            name: 'Employer',
            image: employerImage
        }
    ];

    useEffect(() => {
        setselectedAvailability({ available: candidateData?.availability });
    }, []);

    useEffect(() => {
        initializeAnalytics();
        sendPageView(window.location.pathname, 'Candidate Onboarding');
    }, []);

    return (
        <div>
            <div className="flex flex-wrap mt-8 gap-3">
                {onboardingUser?.map((user, index) => (
                    <div
                        key={index}
                        className={`${
                            (selectedUser?.name == user.name &&
                                'flex justify-content-center align-items-center user-selection-container-active cursor-pointer gap-2 w-max') ||
                            'flex justify-content-center align-items-center user-selection-container-in-active  gap-2 w-max'
                        } `}
                        // onClick={() => setselectedUser(user)}
                    >
                        <Image src={tick} className={`${(selectedUser?.name == user.name && 'tick-image') || 'hidden'} `} alt="Logo" />

                        <Image src={user.image} alt="Logo" />
                        <h2 className={`${(selectedUser?.name == user.name && 'user-selection-container-active-text') || 'user-selection-container-in-active-text'} `}>
                            {user.name}
                        </h2>
                    </div>
                ))}
            </div>

            <p className="employer-questions-heading mt-5">When are you available?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {availability?.map((el, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 ${
                            selectedAvailability?.available === el ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                        }`}
                        onClick={() => {
                            setselectedAvailability({ available: el });
                            setCandidateData({ ...candidateData, user: selectedUser.name, availability: el });
                        }}
                    >
                        <div className={`${(selectedAvailability?.available === el && 'available-questions-options-select-text') || 'available-questions-options-text'}`}>
                            {el}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CandidateOnboardingOne;
