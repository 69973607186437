import React, { useEffect, useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

import { ToastContext } from '../../../context/ToastContext';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';

import crossIcon from '../../../assets/images/cross_black_icon.svg';

const EditJobCertificates = ({ visible, setVisible, jobData, setJobData, jobId, setJobDetail }) => {
    const [selectedCertificates, setselectedCertificates] = useState([]);

    const certificates = [
        'Sealant Certified',
        'X-Ray Certified',
        'Nitrose Oxide Certified',
        'CEREC Certified',
        'Local Anesthetic Certified',
        'CPR/BLS Certified',
        'DANB Certified',
        'Laser Certified',
        'Certified Dental Technician',
        'CPR/BLS Certification',
        'Soft Tissue Curettage Certificate',
        'RDA',
        'CDA',
        'EFDA',
        'AAID'
    ];

    const toast = useContext(ToastContext);
    const userToken = getUserToken();

    const certificatesHandler = (certificate) => {
        const updateCertificates = selectedCertificates.find((el) => el === certificate);
        if (updateCertificates) {
            const selectedCertificatesfilter = selectedCertificates.filter((el) => el !== certificate);
            setselectedCertificates(selectedCertificatesfilter);
            const updateCertificate = selectedCertificatesfilter.map((el) => el);
            setJobData({
                ...jobData,
                licenseOrCertificates: {
                    ...jobData?.licenseOrCertificates,
                    certificates: updateCertificate
                }
            });
        } else {
            setselectedCertificates([...selectedCertificates, certificate]);

            setJobData({
                ...jobData,
                licenseOrCertificates: {
                    ...jobData.licenseOrCertificates,
                    certificates: [...jobData?.licenseOrCertificates?.certificates, certificate]
                }
            });
        }
    };

    const checkCertificates = (name) => {
        const indexOfEmployee = selectedCertificates.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    const jobInfoUpdate = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/job/update/${jobId}`, {
            method: 'post',
            payload: jobData,
            headers: {
                'x-access-token': userToken
            }
        });
        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            setJobDetail(response.data.data || []);
            setVisible(false);
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    useEffect(() => {
        setselectedCertificates([...jobData?.licenseOrCertificates?.certificates]);
    }, [jobData.licenseOrCertificates.certificates]);

    return (
        <Dialog visible={visible} position={'center'} className="dental-tools-edit" onHide={() => setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Licenses and Certificates</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => setVisible(false)}
                    />
                </div>

                <p className="employer-questions-heading mt-5">
                    Are there any required licenses and/or certificates that this person needs in order to be qualified for this job?
                </p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {certificates?.map((certificate, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                checkCertificates(certificate) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => certificatesHandler(certificate)}
                        >
                            <div className={`${checkCertificates(certificate) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {certificate}
                            </div>
                        </div>
                    ))}
                </div>

                <hr className="line-horizontal mt-5" />

                <Button type="button" className="onboarding-btn w-max px-6 mt-5" onClick={jobInfoUpdate}>
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditJobCertificates;
