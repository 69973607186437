import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { getUserToken, sendRequest, BASE_URL } from '../../helpers/utils';
import { showErrorToast } from '../../helpers/toast-helper';
import { ToastContext } from '../../context/ToastContext';
import { ProgressSpinner } from 'primereact/progressspinner';

const Success = () => {
    const userToken = getUserToken();
    const jobData = useSelector((state) => state.user.jobData);
    const navigate = useNavigate();
    const toast = useContext(ToastContext);
    const [isFetchingData, setIsFetchingData] = useState(true);

    const checkUserSubscription = async () => {
        try {
            await sendRequest(`${BASE_URL}api/v1/subscription/checkSubscriptions`, {
                method: 'get',
                headers: {
                    'x-access-token': userToken
                }
            });
        } catch (error) {
            showErrorToast(toast, 'Error', 'Something went wrong! Please try again later');
            throw new Error(error);
        } finally {
            setIsFetchingData(false);
        }
    };

    useEffect(() => {
        checkUserSubscription();
    }, []);

    const redirectToJobPage = () => {
        navigate('/employer/jobPost', { state: { paymemntSuccess: true, jobData: jobData } });
    };

    return (
        <div className="text-center mt-5">
            {isFetchingData ? (
                <ProgressSpinner style={{ width: '20px', height: '20px' }} className="mt-4" strokeWidth="6" fill="var(--surface-ground)" animationDuration=".5s" />
            ) : (
                <>
                    <h1>Payment Successful!</h1>
                    <p className="my-3">You can post a job now.</p>

                    <Button onClick={redirectToJobPage} className="mt-3">
                        Go to Job Post
                    </Button>
                </>
            )}
        </div>
    );
};

export default Success;
