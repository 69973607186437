import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

import { useNavigate } from 'react-router-dom';

import EmployerOnboardingOne from './EmployerOnboardingOne';

import logo from '../../../assets/images/dental_batch_white_logo.png';
import jobSeeker from '../../../assets/images/job_seeker_image.svg';
import employerImage from '../../../assets/images/employer_image_2.svg';

import tooth from '../../../assets/images/tooth_image.svg';
import optometry from '../../../assets/images/optometry_image.svg';
import chiropractic from '../../../assets/images/chiropractic_image.svg';
import crossIcon from '../../../assets/images/cross_icon.svg';
import backIcon from '../../../assets/images/onboarding_back_icon.svg';
import EmployerOnboardingScreenTwo from './EmployerOnboardingScreenTwo';
import EmployerOnboardingScreenThree from './EmployerOnboardingScreenThree';
import EmployerOnboardingScreenFour from './EmployerOnboardingScreenFour';
import EmployerOnboardingScreenFive from './EmployerOnboardingScreenFive';
import EmployerOnboardingScreenSix from './EmployerOnboardingScreenSix';
import EmployerOnboardingScreenSeven from './EmployerOnboardingScreenSeven';
import EmployerOnboardingScreenEight from './EmployerOnboardingScreenEight';
import EmployerOnboardingScreenNine from './EmployerOnboardingScreenNine';
import EmployerOnboardingScreenTen from './EmployerOnboardingScreenTen';
import EmployerOnboardingScreenEleven from './EmployerOnboardingScreenEleven';
import EmployerOnboardingScreenTwelve from './EmployerOnboardingScreenTwelve';
import VerifyEmailScreen from '../login/VerifyEmailScreen';
import { percentageValue } from '../../../helpers/utils';

const EmployerOnboarding = (props) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [email, setEmail] = useState('');

    const [employerData, setEmployerData] = useState({
        user: '',
        interestedIndustry: '',
        employerLoveAbout: [],
        employerOfferBenefits: [],
        noOfEmployees: '',
        noOfOffices: '',
        bestDescribeOffice: '',
        officeSpecialties: [],
        companyUrl: '',
        socialAccounts: {},
        officeAddresses: [],
        companyLogo: {},
        companyAbout: '',
        officeImages: []
    });

    const [signupBasicInfo, setSignupBasicInfo] = useState({
        email: '',
        password: '',
        mobileNumber: '',
        companyName: '',
        firstName: '',
        lastName: ''
    });

    const validateStep = () => {
        let isValid = false;

        switch (currentStep) {
            case 0:
                if (employerData.interestedIndustry) isValid = true;
                break;
            case 1:
                if (employerData.employerLoveAbout.length > 0) isValid = true;
                break;
            case 2:
                if (employerData.employerOfferBenefits.length > 0) isValid = true;
            case 3:
                if (employerData.noOfEmployees !== '' && employerData.noOfOffices !== '') isValid = true;
                break;
            case 4:
                if (employerData.bestDescribeOffice !== '' && employerData.officeSpecialties.length > 0) isValid = true;
                break;
            case 5:
                if (employerData.bestDescribeOffice !== '' && employerData.officeSpecialties.length > 0) isValid = true;
                break;
            case 7:
                if (employerData.officeAddresses.length > 0) isValid = true;
                break;
            case 8:
                if (Object.entries(employerData?.companyLogo).length > 0) isValid = true;
                break;
            case 9:
                if (employerData?.companyAbout) isValid = true;
                break;
            case 10:
                if (employerData.officeImages.length > 0) isValid = true;
                break;

            default:
                isValid = true;
                break;
        }

        return isValid;
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <EmployerOnboardingOne employerData={employerData} setEmployerData={setEmployerData} />;
            case 1:
                return <EmployerOnboardingScreenTwo employerData={employerData} setEmployerData={setEmployerData} />;
            case 2:
                return <EmployerOnboardingScreenThree employerData={employerData} setEmployerData={setEmployerData} />;
            case 3:
                return <EmployerOnboardingScreenFour employerData={employerData} setEmployerData={setEmployerData} />;
            case 4:
                return <EmployerOnboardingScreenFive employerData={employerData} setEmployerData={setEmployerData} />;
            case 5:
                return <EmployerOnboardingScreenSix employerData={employerData} setEmployerData={setEmployerData} />;
            case 6:
                return <EmployerOnboardingScreenSeven employerData={employerData} setEmployerData={setEmployerData} />;
            case 7:
                return <EmployerOnboardingScreenEight employerData={employerData} setEmployerData={setEmployerData} />;
            case 8:
                return <EmployerOnboardingScreenNine employerData={employerData} setEmployerData={setEmployerData} />;
            case 9:
                return <EmployerOnboardingScreenTen employerData={employerData} setEmployerData={setEmployerData} />;
            case 10:
                return <EmployerOnboardingScreenEleven employerData={employerData} setEmployerData={setEmployerData} />;
            case 11:
                return (
                    <EmployerOnboardingScreenTwelve
                        employerData={employerData}
                        setEmployerData={setEmployerData}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                        setEmail={setEmail}
                        signupBasicInfo={signupBasicInfo}
                        setSignupBasicInfo={setSignupBasicInfo}
                    />
                );

            case 12:
                return <VerifyEmailScreen email={email} from={'onboarding'} setVisible={props?.setVisible} setCurrentStep={setCurrentStep} />;
            default:
                return <EmployerOnboardingOne employerData={employerData} setEmployerData={setEmployerData} />;
        }
    };

    useEffect(() => {}, [currentStep]);

    return (
        <Dialog visible={props?.visible} className="dialog-box" onHide={() => props?.setVisible(false)}>
            <div className="flex dialog-container h-full overflow-hidden">
                <div
                    className="flex flex-column justify-content-between col-4 sm:col-6 md:col-4 lg:col-4 employer-onboarding-logo-container"
                    style={{ background: '#854dff' }}
                >
                    <div className="flex justify-content-center align-items-center mt-5" style={{ height: '90%' }}>
                        <Image src={logo} alt="Logo" className="batch-logo" />
                    </div>
                    <Image
                        src={backIcon}
                        alt="Logo"
                        className={`cursor-pointer batch-back-btn  ${currentStep === 0 && ''}`}
                        onClick={() => {
                            setCurrentStep(currentStep - 1);
                        }}
                        style={{ visibility: currentStep === 0 ? 'hidden' : 'visible' }}
                    />
                </div>
                <div className="col-8 sm:col-6 md:col-8 lg:col-8 employer-onboarding-content-container h-full overflow-x-hidden">
                    <div className="flex flex-column p-4 m-4">
                        <Image
                            src={backIcon}
                            width="36px"
                            height="36px"
                            alt="logo"
                            style={{ position: 'absolute', top: 5, left: 5 }}
                            onClick={() => {
                                setCurrentStep(currentStep - 1);
                            }}
                            className="back-image cursor-pointer"
                        />

                        <h2 className="progress-bar-text">{`${percentageValue(currentStep + 1, 13)}% Completed!`}</h2>
                        <ProgressBar value={percentageValue(currentStep + 1, 13)} displayValueTemplate={() => <></>} />

                        <Image
                            src={crossIcon}
                            alt="cross icon"
                            width="20px"
                            height="20px"
                            className="cursor-pointer"
                            style={{ position: 'absolute', top: 0, right: 0, padding: 10 }}
                            onClick={() => {
                                setEmployerData({
                                    user: '',
                                    interestedIndustry: '',
                                    employerLoveAbout: [],
                                    employerOfferBenefits: [],
                                    noOfEmployees: '',
                                    noOfOffices: '',
                                    bestDescribeOffice: '',
                                    officeSpecialties: [],
                                    companyUrl: '',
                                    socialAccounts: {},
                                    officeAddresses: [],
                                    companyLogo: {},
                                    companyAbout: '',
                                    officeImages: []
                                });
                                setCurrentStep(0);
                                props?.setVisible(false);
                            }}
                        />

                        {renderStep()}
                        {![11, 12, 13].includes(currentStep) && <hr className="line-horizontal mt-3 " />}

                        {[0, 1, 2, 3, 4].includes(currentStep) && (
                            <Button
                                type="button"
                                className="onboarding-btn mt-4 w-max"
                                disabled={!validateStep()}
                                onClick={() => {
                                    setCurrentStep(currentStep + 1);
                                }}
                            >
                                Next
                            </Button>
                        )}

                        {[5, 6, 8, 9, 10, 7].includes(currentStep) && (
                            <div className="flex flex-wrap gap-3 mt-4">
                                <Button
                                    type="button"
                                    className="onboarding-btn-2 w-max px-6 "
                                    onClick={() => {
                                        setCurrentStep(currentStep + 1);
                                    }}
                                >
                                    Skip
                                </Button>

                                <Button
                                    type="button"
                                    className="onboarding-btn w-max px-6"
                                    disabled={!validateStep()}
                                    onClick={() => {
                                        setCurrentStep(currentStep + 1);
                                    }}
                                >
                                    Next
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default EmployerOnboarding;
