import React, { useState, useEffect } from 'react';
import { Image } from 'primereact/image';
import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';
import defaultLogo from '../../../assets/images/emp_default_logo.svg';

const EmployerOnboardingScreenNine = ({ employerData, setEmployerData }) => {
    const [file, setFile] = useState(null);
    const [logoImage, setlogoImage] = useState();
    const [fileName, setFileName] = useState('');

    const onFileUpload = (event) => {
        setFile(event.files[0]);
        setFileName(event.files[0].name);
    };

    useEffect(() => {
        if (employerData.companyLogo) {
            setlogoImage(employerData.companyLogo.objectURL);
        }
    }, []);

    return (
        <div>
            <p className="employer-questions-heading mt-5">Does your company have a logo?</p>

            <div className="flex align-item-center justify-content-start flex-column mt-3 mb-2">
                <Image src={logoImage ? logoImage : defaultLogo} alt="Logo" className="employee-company-logo" />

                <FileUpload
                    name="demo"
                    accept="image/*"
                    mode="basic"
                    chooseLabel="Upload Photo"
                    uploadLabel="Upload"
                    cancelLabel="Cancel"
                    className={classNames({ 'p-mr-2 mt-3': true })}
                    onSelect={(e) => {
                        setlogoImage(e.files[0].objectURL);
                        setEmployerData({ ...employerData, companyLogo: e.files[0] });
                    }}
                >
                    {fileName && <span className="p-d-block p-mt-2">Selected file: {fileName}</span>}
                </FileUpload>
            </div>
        </div>
    );
};

export default EmployerOnboardingScreenNine;
