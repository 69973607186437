import React from 'react';
import { Image } from 'primereact/image';
import employerLogo from '../../assets/images/tooth.png';
import location from '../../assets/images/location_icon_2.svg';

import { getColorForItem, imageDisplay } from '../../helpers/utils';

const ActiveEmployerCard = (props) => {
    return (
        <div className="flex flex-column p-4 my-2 w-full job-card">
            <Image src={props?.officeImage} className="w-full full-img" alt="Logo" />
            <h1 className="job-card-city-heading" style={{ minHeight: '23px' }}>
                {props?.employerTitle}
            </h1>
            <div className="flex gap-4">
                <Image
                    src={`${props?.profileImage ? imageDisplay(props?.profileImage) : employerLogo}`}
                    className="job-card-profile-image"
                    alt="Logo"
                    width="52px"
                    height="52px"
                    imageStyle={{ borderRadius: '38px' }}
                />
                <h2 className="job-card-title-heading truncate-text">
                    <span className="mr-2">
                        <Image src={location} alt="Logo" />
                    </span>
                    {props?.location}
                </h2>
            </div>
            <div className="flex mt-3 gap-2 justify-content-start align-items-center">
                {props?.benefits?.length > 0 &&
                    props?.benefits?.slice(0, 4)?.map((benefit, index) => (
                        <div
                            className={`py-2 px-4 main-ben
                                        `}
                            style={{
                                color: getColorForItem(benefit),
                                borderColor: getColorForItem(benefit),
                                backgroundColor: getColorForItem(benefit, 'bg')
                            }}
                            key={index}
                        >
                            {benefit}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ActiveEmployerCard;
