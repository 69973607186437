import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';

import tooth from '../../../assets/images/tooth_image.svg';
import optometry from '../../../assets/images/optometry_image.svg';
import chiropractic from '../../../assets/images/chiropractic_image.svg';

const CandidateOnboardingScreenTwo = ({ candidateData, setCandidateData }) => {
    const [selectedIndustry, setselectedIndustry] = useState({});
    const [selectedJobClasfication, setselectedJobClasfication] = useState({});

    const industries = [
        {
            name: 'Dental',
            image: tooth
        },
        {
            name: 'Optometry',
            image: optometry
        },
        {
            name: 'Chiropractic',
            image: chiropractic
        }
    ];

    const jobClassification = ['Full-time', 'Part-time', 'Contract', 'Temporary', 'Any'];

    useEffect(() => {
        setselectedJobClasfication({ clasfication: candidateData?.jobType });
        setselectedIndustry({ name: candidateData?.interestedIndustry });
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">What type of job are you looking for?</p>
            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {jobClassification?.map((clasfication, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            selectedJobClasfication.clasfication === clasfication ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => {
                            setselectedJobClasfication({ clasfication });
                            setCandidateData({ ...candidateData, jobType: clasfication });
                        }}
                    >
                        <div
                            className={`${
                                selectedJobClasfication.clasfication === clasfication ? 'available-questions-options-select-text' : 'available-questions-options-text'
                            }`}
                        >
                            {clasfication}
                        </div>
                    </div>
                ))}
            </div>

            <p className="employer-questions-heading mt-5">Which industry are you interested in working in?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {industries?.map((industry, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center cursor-pointer gap-3 ${
                            selectedIndustry?.name === industry.name ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => {
                            if (industry.name === 'Dental') {
                                setselectedIndustry({ name: industry.name });
                                setCandidateData({ ...candidateData, interestedIndustry: industry.name });
                            }
                        }}
                    >
                        <Image src={industry.image} alt="Logo" className={`${(selectedIndustry?.name === industry.name && 'benefit-image-select') || 'benefit-image'}`} />
                        <div
                            className={`${(selectedIndustry.name === industry.name && 'available-questions-options-select-text') || 'available-questions-options-text'}`}
                        >
                            {industry.name}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenTwo;
