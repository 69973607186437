import React, { useEffect, useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ToastContext } from '../../../context/ToastContext';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';

import crossIcon from '../../../assets/images/cross_black_icon.svg';

const EditJobInformation = ({ visible, setVisible, jobData, setJobData, jobId, setJobDetail }) => {
    const [selectedLanguages, setselectedLanguages] = useState([]);

    const [selectedExperience, setselectedExperience] = useState();
    const [selectedJobClasfication, setselectedJobClasfication] = useState();
    const [selectedEducationRequirments, setselectedEducationRequirments] = useState();
    const [isValid, setIsValid] = useState(false);
    const [selectedOpinion, setselectedOpinion] = useState();
    const [selectedTime, setSelectedTime] = useState({ label: 'Hourly' });
    const languages = ['Spanish', 'Vietnamese', 'Tagalog', 'Arabic', 'Hindi', 'Urdu', 'Japanese', 'French', 'German', 'Mandarin', 'Italian', 'Portuguese', 'Dutch'];

    const educationReq = ['High School Diploma / GED', 'Vocational Certificate', 'Some College', 'AA Degree', 'BS/BA Degree', 'MS/MBA Degree', 'Doctorate'];

    const experience = ['0-6 Months', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'];
    const jobClassification = ['Full-time', 'Part-time', 'Contract', 'Contract to hire', 'Temporary'];
    const sponsorOpinion = ['Yes', 'No', 'I Don’t Know'];

    const toast = useContext(ToastContext);
    const userToken = getUserToken();

    const times = [
        {
            label: 'Hourly'
        },
        {
            label: 'Daily'
        },
        {
            label: 'Monthly'
        },
        {
            label: 'Yearly'
        }
    ];
    const [salValue, setSalValue] = useState({
        min: 1,
        max: 1000
    });

    const [state, setState] = useState({
        lowerBound: jobData.salaryRange?.min,
        upperBound: jobData.salaryRange?.max,
        value: [20, 500]
    });

    const checkLanguages = (name) => {
        const indexOfEmployee = selectedLanguages.findIndex((el) => el === name);
        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    const educationReqHandler = (requirement) => {
        setselectedEducationRequirments(requirement);
        setJobData({ ...jobData, minimumEducation: requirement });
    };

    const jobInfoUpdate = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/job/update/${jobId}`, {
            method: 'post',
            payload: jobData,
            headers: {
                'x-access-token': userToken
            }
        });
        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            setJobDetail(response.data.data || []);
            setVisible(false);
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    const languagesHandler = (language) => {
        const updateLanguage = selectedLanguages?.find((el) => el === language);
        if (updateLanguage) {
            const selectedLanguagefilter = selectedLanguages?.filter((el) => el !== language);
            setselectedLanguages(selectedLanguagefilter);
            setJobData({
                ...jobData,
                languages: {
                    ...jobData?.languages,
                    languages: selectedLanguagefilter
                }
            });
        } else {
            setselectedLanguages([...selectedLanguages, language]);
            setJobData({
                ...jobData,
                languages: {
                    ...jobData?.languages,
                    languages: [...jobData?.languages?.languages, language]
                }
            });
        }
    };

    useEffect(() => {
        const MinValue = jobData.salaryRange?.type === 'Hourly' ? 1 : jobData.salaryRange?.type === 'Daily' ? 1 : jobData.salaryRange?.type === 'Monthly' ? 500 : 1500;
        const MaxValue =
            jobData.salaryRange?.type === 'Hourly' ? 1000 : jobData.salaryRange?.type === 'Daily' ? 1000 : jobData.salaryRange?.type === 'Monthly' ? 100000 : 500000;
        setSalValue({ min: MinValue, max: MaxValue });
        setState({ lowerBound: MinValue, upperBound: MaxValue, value: [MinValue, MaxValue] });
        setJobData({ ...jobData, salaryRange: { ...jobData.salaryRange, min: MinValue, max: MaxValue } });
    }, [visible]);

    useEffect(() => {
        const MinValue = selectedTime?.label === 'Hourly' ? 1 : selectedTime?.label === 'Daily' ? 1 : selectedTime?.label === 'Monthly' ? 500 : 1500;
        const MaxValue = selectedTime?.label === 'Hourly' ? 1000 : selectedTime?.label === 'Daily' ? 1000 : selectedTime?.label === 'Monthly' ? 100000 : 500000;
        setSalValue({ min: MinValue, max: MaxValue });
        setState({ lowerBound: MinValue, upperBound: MaxValue, value: [MinValue, MaxValue] });
        setJobData({ ...jobData, salaryRange: { ...jobData.salaryRange, min: MinValue, max: MaxValue } });
    }, [selectedTime]);

    const onLowerBoundChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            lowerBound: +e.target.value
        }));
        const { lowerBound, upperBound } = state;

        const MinValue = selectedTime?.label === 'Hourly' ? 1 : selectedTime?.label === 'Daily' ? 1 : selectedTime?.label === 'Monthly' ? 500 : 1500;
        const MaxValue = selectedTime?.label === 'Hourly' ? 1000 : selectedTime?.label === 'Daily' ? 1000 : selectedTime?.label === 'Monthly' ? 100000 : 500000;
        if (lowerBound >= MinValue && upperBound <= MaxValue) {
            setJobData({
                ...jobData,
                salaryRange: { ...jobData?.salaryRange, max: upperBound, min: +e.target.value }
            });
            setIsValid(true);
        } else {
            showErrorToast(toast, 'Value Not Valid', '');
        }
    };

    const onUpperBoundChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            upperBound: +e.target.value
        }));

        const { lowerBound, upperBound } = state;

        const MinValue = selectedTime?.label === 'Hourly' ? 1 : selectedTime?.label === 'Daily' ? 1 : selectedTime?.label === 'Monthly' ? 500 : 1500;
        const MaxValue = selectedTime?.label === 'Hourly' ? 1000 : selectedTime?.label === 'Daily' ? 1000 : selectedTime?.label === 'Monthly' ? 100000 : 500000;
        if (lowerBound >= MinValue && upperBound <= MaxValue) {
            setJobData({
                ...jobData,
                salaryRange: { ...jobData?.salaryRange, max: +e.target.value, min: lowerBound }
            });
            setIsValid(true);
        } else {
            showErrorToast(toast, 'Value Not Valid', '');
        }
    };

    useEffect(() => {
        setselectedLanguages([...jobData.languages.languages]);
        setselectedEducationRequirments(Array.isArray(jobData.minimumEducation) ? jobData.minimumEducation[0] : jobData.minimumEducation);
        setselectedExperience(jobData.minimumExperience === 10 ? '10+' : jobData.minimumExperience === 0 ? '0-6 Months' : String(jobData.minimumExperience));
        setselectedJobClasfication(jobData.jobType);
        setselectedOpinion(jobData.sponserVisa);
    }, [jobData.languages.languages]);

    return (
        <Dialog visible={visible} position={'center'} className="dental-tools-edit" onHide={() => setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Informations</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => setVisible(false)}
                    />
                </div>

                <p className="employer-questions-heading mt-5">Other than English, will this person be required to speak another language fluently? </p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                    {languages?.map((language, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                checkLanguages(language) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => languagesHandler(language)}
                        >
                            <div className={`${checkLanguages(language) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {language}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="employer-questions-heading mt-5">What is the minimum number of years of experience required for this position?</p>
                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {experience?.map((count, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                selectedExperience === count ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => {
                                setselectedExperience(count);
                                setJobData({
                                    ...jobData,
                                    minimumExperience: count === '10+' ? 10 : count === '0-6 Months' ? 0 : Number(count)
                                });
                            }}
                        >
                            <div className={`${selectedExperience === count ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {count}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="employer-questions-heading mt-5">What is the job classification?</p>
                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {jobClassification?.map((clasfication, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                selectedJobClasfication === clasfication
                                    ? 'available-questions-options-select cursor-pointer'
                                    : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => {
                                setselectedJobClasfication(clasfication);
                                setJobData({
                                    ...jobData,
                                    jobType: clasfication
                                });
                            }}
                        >
                            <div
                                className={`${selectedJobClasfication === clasfication ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}
                            >
                                {clasfication}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="employer-questions-heading mt-5">What is the minimum education level required for this position?</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {educationReq?.map((requirement, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                selectedEducationRequirments === requirement
                                    ? 'available-questions-options-select cursor-pointer'
                                    : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => educationReqHandler(requirement)}
                        >
                            <div
                                className={`${
                                    selectedEducationRequirments === requirement ? 'available-questions-options-select-text' : 'available-questions-options-text'
                                }`}
                            >
                                {requirement}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="employer-questions-heading mt-5">Does your company sponsor visas?</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                    {sponsorOpinion?.map((opinion, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                                selectedOpinion === opinion ? 'available-questions-options-select ' : 'available-questions-options'
                            }`}
                            onClick={() => {
                                setselectedOpinion(opinion);
                                setJobData({
                                    ...jobData,
                                    sponserVisa: opinion
                                });
                            }}
                        >
                            <div className={`${selectedOpinion === opinion ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {opinion}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="employer-questions-heading mt-5">What is the salary range for this role? </p>
                <div className="flex-wrap justify-content-between mt-5 gap-2">
                    <Dropdown
                        options={times}
                        value={selectedTime}
                        onChange={(e) => {
                            setSelectedTime(e.value);
                            setJobData({ ...jobData, salaryRange: { ...jobData?.salaryRange, type: e.value.label } });
                        }}
                        optionLabel="label"
                        placeholder="Hourly"
                        className={`circular-slider-dropdown border-0 h-max pl-4 pr-1`}
                        panelClassName="circular-slider-panel"
                    />
                    <>
                        <div>
                            <label style={{ minWidth: '150px', display: 'inline-block' }}>Minimum: </label>
                            <InputNumber prefix="$" showButtons min={salValue?.min} max={salValue?.max} value={state.lowerBound} onValueChange={onLowerBoundChange} />
                            <br />
                            <div className="mt-2">
                                <label style={{ minWidth: '150px', display: 'inline-block' }}>Maximum: </label>
                                <InputNumber prefix="$" showButtons min={salValue?.min} max={salValue?.max} value={state.upperBound} onValueChange={onUpperBoundChange} />
                            </div>
                        </div>
                    </>
                </div>

                <hr className="line-horizontal mt-5" />

                <Button type="button" className="onboarding-btn w-max px-6 mt-5" onClick={jobInfoUpdate}>
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditJobInformation;
