import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import crossIcon from '../../../assets/images/cross_black_icon.svg';

const EditEmployeesLove = (props) => {
    const myDat = [
        'Work environment',
        'Career advancement',
        'Flexible work hours',
        'Diverse workforce',
        'Modern & updated office',
        'Empathetic Leadership',
        'Recognition & rewards',
        'Fun office environment',
        'Compensation'
    ];
    useEffect(() => {
        setMyDatSelected(props?.myProfile);
    }, [props?.myProfile]);

    const [myDatSelected, setMyDatSelected] = useState();
    const handleItemClick = (item) => {
        if (myDatSelected?.includes(item)) {
            setMyDatSelected(myDatSelected?.filter((selectedItem) => selectedItem !== item));
        } else if (myDatSelected.length < 6) {
            setMyDatSelected([...myDatSelected, item]);
        }
    };

    const checkEmployeesLikes = (name) => {
        const indexOfEmployee = myDatSelected?.findIndex((employee) => employee === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setMyDatSelected(props?.myProfile?.preferences?.employer?.employerLoveAbout);
    }, []);
    return (
        <Dialog visible={props?.visible} position={'center'} className="dental-tools-edit" onHide={() => props?.setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Employees Love</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => props?.setVisible(false)}
                    />
                </div>

                <p className="employer-questions-heading mt-5">Select up to 6 things your employees love about your company</p>
                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {myDat?.map((likes, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                checkEmployeesLikes(likes) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => handleItemClick(likes)}
                        >
                            <Image src={likes.image} alt="Logo" className={`${(checkEmployeesLikes(likes) && 'benefit-image-select') || 'benefit-image'}`} />

                            <div className={`${(checkEmployeesLikes(likes) && 'available-questions-options-select-text') || 'available-questions-options-text'}`}>
                                {likes}
                            </div>
                        </div>
                    ))}
                </div>

                <hr className="line-horizontal mt-5" />

                <Button
                    type="button"
                    className="onboarding-btn w-max px-6 mt-5"
                    onClick={() => {
                        let loveAbout = myDatSelected?.map((val) => val);

                        let data = {
                            preferences: {
                                employer: {
                                    employerLoveAbout: loveAbout
                                }
                            }
                        };
                        props?.updateProfile(data);
                        props?.setVisible(false);
                    }}
                >
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditEmployeesLove;
