import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';

const CandidateOccupationScreenFive = ({ candidateData, setCandidateData }) => {
    const [selectedExperiences, setSelectedExperiences] = useState({});

    const updateDropDown = (tool, value) => {
        const { industryToolsExperience } = candidateData;
        const [...rest] = industryToolsExperience;

        const findSoft = rest.findIndex((a) => a.name === tool);

        rest[findSoft].experience = value;

        return rest;
    };

    // Create a state object to hold the selected experience for each software

    const handleExperienceChange = (tool, value) => {
        // Update the selected experience for the specified software
        setSelectedExperiences({
            ...selectedExperiences,
            [candidateData.industryToolsExperience.name]: value
        });

        const newData = updateDropDown(tool, value);

        setCandidateData({ ...candidateData, industryToolsExperience: newData });
    };

    const experience = [
        { label: '1 year', value: 1 },
        { label: '2 years', value: 2 },
        { label: '3 years', value: 3 },
        { label: '4 years', value: 4 },
        { label: '5 years', value: 5 },
        { label: '6 years', value: 6 },
        { label: '7+ years', value: 7 }
    ];

    useEffect(() => {
        setSelectedExperiences([...candidateData?.industryToolsExperience]);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5 w-11">Please enter the years of experience you have using each tool.</p>

            <div
                className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 pb-3 candiate-onboarding-screen-software-container"
                style={{ height: '266px', overflowY: 'scroll', overflowX: 'hidden' }}
            >
                {candidateData?.industryToolsExperience.map((tool, index) => (
                    <div key={index} className="flex  flex-wrap sm:flex-nowrap justify-content-start align-items-center gap-3 w-full mt-2">
                        <h4 className="candiate-onboarding-screen-software-text w-11 sm:w-12 md:w-6 lg:w-6 xl:w-7 my-0 p-3 ml-3">{tool.name}</h4>
                        <Dropdown
                            options={experience}
                            value={tool.experience}
                            optionLabel="label"
                            onChange={(e) => handleExperienceChange(tool.name, e.value)} // Pass the current software to the change handler
                            placeholder="Select"
                            className={`social-media-dropdown p-1 w-6 sm:w-8 md:w-6 lg:w-5 xl:w-3 ml-3 md:ml-0`}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOccupationScreenFive;
