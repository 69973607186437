import { useEffect, useState } from 'react';
import Header from '../../layout/header/LandingPageHeader';

import FooterSection from './FooterSection';
import AuthScreen from '../../dialog/login/AuthScreen';
import EmployerOnboarding from '../../dialog/employer/EmployerOnboarding';

const Privacy = () => {
    const [visible, setVisible] = useState(false);

    const [visibleLogin, setVisibleLogin] = useState(false);

    const [priceLogin, setPriceLogin] = useState(false);

    useEffect(() => {
        if (priceLogin) setVisible(true);
    }, [priceLogin]);

    return (
        <>
            <Header setVisible={setVisibleLogin} priceLogin={priceLogin} setPriceLogin={setPriceLogin} />
            <div className="bg-white">
                <div className="wrapper">
                    <h2 className="text-center my-5 py-5">BATCH WEBSITE PRIVACY POLICY</h2>
                </div>
                <div className="wrapper my-5 terms privacy">
                    <h5>OUR APPROACH TO PRIVACY </h5>
                    <p>
                        1.1 Batch Technologies USA INC. ("Batch," "we," "our," or "us") operates a dental recruitment messaging tool, available to prospective employers
                        and candidates through our website,{' '}
                        <a href="https://www.joinbatch.com/" target="_blank">
                            www.joinbatch.com
                        </a>{' '}
                        (the "Service"). 1.2 Batch is dedicated to protecting your privacy. This privacy policy outlines our practices regarding the collection, use, and
                        sharing of your personal information when you access our website and use our Service, including our use of cookies and similar technology. 1.3 For
                        inquiries about this privacy policy or our use of your personal information, please contact us using the details at the end of this policy.
                    </p>
                    <h5>WHO IS RESPONSIBLE FOR YOUR PERSONAL INFORMATION </h5>
                    <p> 2.1 Batch is the data controller and is responsible for your personal information processed through our Service.</p>
                    <h5>PERSONAL INFORMATION WE COLLECT ABOUT YOU AND HOW WE USE IT </h5>
                    <p>
                        3.1 We collect information that you provide directly to us when you use our website or Service, such as when you fill out forms, correspond with
                        us, subscribe to our marketing, or engage with our Service. 3.2 We may require certain personal information to provide our Service; without it, we
                        may not be able to fulfill your requests.
                    </p>
                    <h5>INFORMATION WE COLLECT ABOUT YOU AUTOMATICALLY </h5>
                    <p>
                        4.1 Our Service automatically collects information about how you use our website, such as your browsing actions and device information, to improve
                        our Service and to offer personalized experiences. 4.2 We may combine this information with other personal information we have collected from you.
                    </p>
                    <h5>HOW LONG WE KEEP YOUR PERSONAL INFORMATION </h5>
                    <p>
                        5.1 We retain personal information as long as necessary for the purposes described in this policy, in line with our legal obligations and
                        legitimate business interests.
                    </p>
                    <h5>RECIPIENTS </h5>
                    <p> 6.1 We share your information with service providers, advisors, potential transaction partners, law enforcement, or as required by law.</p>
                    <h5>MARKETING AND ADVERTISING </h5>
                    <p>
                        7.1 We may use your information to send you marketing communications if you've consented. You can opt out of marketing communications but may
                        still receive Service-related messages.
                    </p>
                    <h5>COOKIES AND SIMILAR TECHNOLOGIES </h5>
                    <p> 8.1 We use cookies and similar technologies for functionality, analytics, and advertising. You can control cookie settings in your browser.</p>
                    <h5>STORING AND TRANSFERRING YOUR PERSONAL INFORMATION </h5>
                    <p> 9.1 We take steps to ensure your information is protected and may transfer it internationally with appropriate safeguards.</p>
                    <h5>YOUR RIGHTS IN RESPECT OF YOUR PERSONAL INFORMATION </h5>
                    <p>
                        10.1 You have rights to access, correct, erase, restrict, or transfer your personal information and can object to processing based on legitimate
                        interests. 10.2 Contact us to exercise your rights, and you can lodge a complaint with your local data protection authority.
                    </p>
                    <h5> LINKS TO THIRD PARTY SITES </h5>
                    <p> 11.1 We are not responsible for the privacy policies of third-party websites linked to our Service. </p>
                    <h5> OUR POLICY TOWARD CHILDREN </h5>
                    <p> 12.1 Our Service does not target children under 18, and we do not knowingly collect their information.</p>
                    <h5>CHANGES TO THIS POLICY </h5>
                    <p> 13.1 We may update this policy and will notify you of significant changes.</p>
                    <h5>NOTICE TO YOU </h5>
                    <p> 14.1 We will inform you of important information by email or website notice.</p>
                    <h5> CONTACT US </h5>
                    <p>
                        {' '}
                        15.1 Please reach out to <a href="mailto:support@joinbatch.com">support@joinbatch.com</a> with any questions or requests regarding this privacy
                        policy.
                    </p>
                </div>
            </div>
            <AuthScreen visible={visibleLogin} setVisible={setVisibleLogin} />
            <EmployerOnboarding visible={visible} setVisible={setVisible} />

            <FooterSection setPriceLogin={setPriceLogin} />
        </>
    );
};

export default Privacy;
