import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { languagesList } from './softwareOptions';

const CandidateOnboardingScreenFive = ({ candidateData, setCandidateData }) => {
    const [languageValue, setlanguageValue] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [suggestList, setSuggestList] = useState([]);

    const [selectedLanguages, setselectedLanguages] = useState([]);
    const [languages, setlanguages] = useState([
        'Spanish',
        'Vietnamese',
        'Tagalog',
        'Arabic',
        'Hindi',
        'Urdu',
        'Japanese',
        'French',
        'German',
        'Mandarin',
        'Italian',
        'Portuguese',
        'Dutch'
    ]);
    const search = (event) => {
        const filterData = languagesList.filter((aS) => aS.toLocaleLowerCase().match(event.query.toLocaleLowerCase()));
        setSuggestList(filterData);
    };
    const languagesHandler = (language) => {
        const updateLanguage = selectedLanguages.find((el) => el === language);
        if (updateLanguage) {
            const selectedLanguagefilter = selectedLanguages.filter((el) => el !== language);
            setselectedLanguages(selectedLanguagefilter);
            setCandidateData({ ...candidateData, languages: [...selectedLanguagefilter] });
        } else {
            setselectedLanguages([...selectedLanguages, language]);
            setCandidateData({ ...candidateData, languages: [...candidateData.languages, language] });
        }
    };

    const checkLanguages = (name) => {
        const indexOfEmployee = selectedLanguages.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    const addLanguageHandler = (value) => {
        if (value) {
            const existObject = languages.find((el) => el === value);
            if (!existObject) {
                setlanguages([...languages, value]);
                setlanguageValue('');
            }
        }
    };

    useEffect(() => {
        if (candidateData?.languages) {
            setlanguages((prevLang) => {
                const updatedLang = [...prevLang];
                for (let item of candidateData?.languages) {
                    if (!updatedLang.includes(item)) {
                        updatedLang.push(item);
                    }
                }
                setselectedLanguages(candidateData?.languages);
                return updatedLang;
            });
        }
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">Which languages other than English do you speak fluently?</p>

            <div className="flex flex-wrap align-item-center justify-content-between add-button-container mt-3 mb-2 w-full sm:w-10">
                <AutoComplete
                    value={searchValue}
                    suggestions={suggestList}
                    completeMethod={search}
                    onChange={(e) => setSearchValue(e.value)}
                    className="auto-complete-add-button-input w-10"
                    placeholder="Search Language"
                />

                <div className="flex align-item-center justify-content-center add-button-2 w-2 cursor-pointer w-max mr-2" onClick={() => addLanguageHandler(searchValue)}>
                    <div className="add-button-text-2">Add</div>
                </div>
            </div>

            <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                {languages?.map((language, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            checkLanguages(language) ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => languagesHandler(language)}
                    >
                        <div className={`${checkLanguages(language) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>{language}</div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenFive;
