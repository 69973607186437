import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenThree = ({ candidateData, setCandidateData }) => {
    const [selectedLikes, setselectedLikes] = useState([]);

    const employeeLikes = [
        'Work environment',
        'Career advancement',
        'Flexible work hours',
        'Diverse workforce',
        'Modern & updated office',
        'Empathetic Leadership',
        'Recognition & rewards',
        'Fun office environment',
        'Competitive Pay',
        'Casual office attire',
        'State-of-the-Art Equipment',
        'Office Pets',
        'Overtime Opportunities',
        'Large Staff',
        'Small Staff',
        'Benefits'
    ];

    const LikesHandler = (name) => {
        const filterLike = employeeLikes?.find((like) => like === name);
        const updateSelectedLikes = selectedLikes.find((like) => like === name);

        if (updateSelectedLikes) {
            const selectedLikesfilter = selectedLikes.filter((like) => like !== name);
            setselectedLikes(selectedLikesfilter);
            setCandidateData({ ...candidateData, matterMostToYou: selectedLikesfilter?.map((item) => item) });
        } else {
            if (selectedLikes?.length < 6) {
                setselectedLikes([...selectedLikes, filterLike]);
                setCandidateData({ ...candidateData, matterMostToYou: [...selectedLikes.map((item) => item), filterLike] });
            }
        }
    };

    const checkEmployeesLikes = (name) => {
        const indexOfEmployee = selectedLikes.findIndex((employee) => employee === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setselectedLikes([...candidateData.matterMostToYou]);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">Which of these matter most to you?</p>
            <h1 className="job-posting-select-all-text mt-2 mb-0 cursor-pointer">Choose up to 6</h1>
            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {employeeLikes?.map((likes, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center cursor-pointer gap-3 ${
                            checkEmployeesLikes(likes) ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => LikesHandler(likes)}
                    >
                        <div className={`${(checkEmployeesLikes(likes) && 'available-questions-options-select-text') || 'available-questions-options-text'}`}>
                            {likes}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenThree;
