import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import crossIcon from '../../../assets/images/cross_black_icon.svg';

const EditEmployeSkills = (props) => {
    const [benefits, setBenefitsData] = useState();
    const employeBenifits = [
        'PTO',
        'Employee Bonuses',
        'Medical',
        'Dental',
        'Education',
        'Flexible work hours',
        'Paid Holidays',
        'Life Insurance',
        'Free dental work for employees',
        'Profit-sharing program',
        'Short-term disability',
        'Gym membership',
        '401k'
    ];

    const [selectedBenefits, setSelectedBenefits] = useState();

    useEffect(() => {
        setBenefitsData(props?.myProfile);
    }, [props?.myProfile]);

    const handleItemRemove = (item) => {
        if (benefits?.includes(item)) {
            setBenefitsData(benefits?.filter((selectedItem) => selectedItem !== item));
        }
    };
    const handleItemAdded = () => {
        if (!benefits?.includes(selectedBenefits)) setBenefitsData([...benefits, selectedBenefits]);
    };

    return (
        <Dialog visible={props?.visible} position={'center'} className="dental-tools-edit" onHide={() => props?.setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Benefits</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => props?.setVisible(false)}
                    />
                </div>

                <h2 className="candidate-search-details-heading mt-5 mb-0">Please add or remove benefits</h2>

                <Dropdown
                    options={employeBenifits}
                    value={selectedBenefits}
                    onChange={(e) => setSelectedBenefits(e.value)}
                    placeholder="Select"
                    className="social-media-dropdown p-1 mt-5 mb-3 ml-3 md:ml-0"
                />

                <div className="flex flex-wrap justify-content-start align-items-center my-2 gap-3 mt-8">
                    {benefits?.map((el, index) => (
                        <div key={index} className={`flex justify-content-center align-items-center cursor-pointer available-questions-options-select`}>
                            <div className={`available-questions-options-select-text`}>{el}</div>
                            <i className="pi pi-times ml-1 selected-cross-icon" onClick={() => handleItemRemove(el)}></i>
                        </div>
                    ))}
                </div>

                <hr className="line-horizontal mt-5" />

                <div className="flex flex-wrap justify-content-start gap-3">
                    <Button type="button" className="onboarding-btn w-max px-6 mt-5" onClick={handleItemAdded}>
                        Add
                    </Button>

                    <Button
                        type="button"
                        className="onboarding-btn w-max px-6 mt-5"
                        onClick={() => {
                            let data = {
                                preferences: {
                                    employer: {
                                        employerOfferBenefits: benefits
                                    }
                                }
                            };
                            props?.updateProfile(data);
                            props?.setVisible(false);
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default EditEmployeSkills;
