import React, { useState, useEffect } from 'react';
import { Image } from 'primereact/image';

import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

import addIcon from '../../../assets/images/add_image.svg';
import deleteIcon from '../../../assets/images/delete_icon.svg';

import { Button } from 'primereact/button';

const CandidateOnboardingScreenSixteen = ({ candidateData, setCandidateData, setCurrentStep, currentStep }) => {
    const isDateValid = (startDate, endDate) => {
        if (!startDate && !endDate) return true;

        if (!startDate || !endDate) return true;

        return new Date(startDate) < new Date(endDate);
    };

    const handleNext = () => {
        const isAllDatesValid = candidateData.workHistory.every((historyItem) => {
            const { startDate, endDate } = historyItem.yearsOfEmployment;
            const { currrentlyWorkHere, employerName, jobTitle } = historyItem;

            if (!employerName) return false;
            if (!jobTitle) return false;

            if (currrentlyWorkHere) {
                if (!startDate) return false;

                return true;
            } else {
                if (!startDate || !endDate) return false;

                return new Date(startDate) < new Date(endDate);
            }
        });

        if (isAllDatesValid) setCurrentStep(currentStep + 1);
    };

    const handleDelete = (index) => {
        let updateWorkHistory = candidateData.workHistory;
        updateWorkHistory.splice(index, 1);

        setCandidateData({
            ...candidateData,
            workHistory: updateWorkHistory
        });
    };

    useEffect(() => {
        if (candidateData.workHistory.length) return;

        setCandidateData({
            ...candidateData,
            workHistory: [
                ...candidateData.workHistory,
                { employerName: '', jobTitle: '', currrentlyWorkHere: false, hideProfileCurrentEmployer: false, yearsOfEmployment: { startDate: '', endDate: '' } }
            ]
        });
    }, []);

    const currrentlyWorkHere = [true, false];
    const hideProfile = [true, false];

    const renderSingleForm = (workObj, index) => (
        <div key={`form-${index}`}>
            <div className="flex justify-content-between">
                <p className="employer-questions-heading mt-5">{index === 0 ? 'Who is your most recent employer?' : 'Add another employer'}</p>
                {index > 0 && (
                    <img
                        src={deleteIcon}
                        onClick={() => {
                            handleDelete(index);
                        }}
                        width="25"
                        height="25"
                        className="cursor-pointer mt-5"
                    />
                )}
            </div>

            <div className="flex flex-column mt-3">
                <label htmlFor="username" className="employee-signup-input-label mb-2">
                    Employer
                </label>
                <InputText
                    id="username"
                    value={workObj.employerName}
                    onChange={(e) => {
                        const [...rest] = candidateData.workHistory;
                        rest[index].employerName = e.target.value;

                        setCandidateData({
                            ...candidateData,
                            workHistory: rest
                        });
                    }}
                    className="employee-signup-input border-0 w-10"
                    placeholder="employer name"
                    aria-describedby="username-help"
                />
            </div>
            <div className="flex flex-column mt-3">
                <label htmlFor="title" className="employee-signup-input-label mb-2">
                    Job Title
                </label>
                <InputText
                    id="title"
                    value={workObj.jobTitle}
                    onChange={(e) => {
                        const [...rest] = candidateData.workHistory;
                        rest[index].jobTitle = e.target.value;

                        setCandidateData({
                            ...candidateData,
                            workHistory: rest
                        });
                    }}
                    className="employee-signup-input border-0 w-10"
                    placeholder="job title"
                    aria-describedby="username-help"
                />
            </div>

            <div className="flex flex-wrap justify-content-start align-items-center mt-4 mb-3 gap-3">
                <div className="candiate-onboarding-screen-recent-work-text">Do you currently work here?</div>

                {currrentlyWorkHere?.map((visible, i) => (
                    <div
                        key={i}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            workObj.currrentlyWorkHere === visible ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => {
                            const [...rest] = candidateData.workHistory;
                            rest[index].currrentlyWorkHere = visible;

                            if (visible) rest[index].yearsOfEmployment.endDate = '';
                            setCandidateData({
                                ...candidateData,
                                workHistory: rest
                            });
                        }}
                    >
                        <div className={`${workObj.currrentlyWorkHere === visible ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {visible ? 'Yes' : 'No'}
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex flex-wrap justify-content-start align-items-center mt-4 mb-3 gap-3">
                <div className="candiate-onboarding-screen-recent-work-text">Do you want to hide your profile from your current employer?</div>

                {hideProfile?.map((visible, i) => (
                    <div
                        key={i}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            workObj.hideProfileCurrentEmployer === visible ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => {
                            const [...rest] = candidateData.workHistory;
                            rest[index].hideProfileCurrentEmployer = visible;

                            setCandidateData({
                                ...candidateData,
                                workHistory: rest
                            });
                        }}
                    >
                        <div
                            className={`${
                                workObj.hideProfileCurrentEmployer === visible ? 'available-questions-options-select-text' : 'available-questions-options-text'
                            }`}
                        >
                            {visible ? 'Yes' : 'No'}
                        </div>
                    </div>
                ))}
            </div>

            <div className="employee-signup-input-label mt-2">Years Of Employment</div>

            <div className="grid mt-3">
                <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                    <label htmlFor="username" className="employee-signup-input-label mb-2">
                        Start Date
                    </label>
                    <Calendar
                        value={workObj.yearsOfEmployment.startDate}
                        className="employment-calender"
                        placeholder="February,22, 2022"
                        onChange={(e) => {
                            const [...rest] = candidateData.workHistory;
                            rest[index].yearsOfEmployment.startDate = e.value;

                            setCandidateData({ ...candidateData, workHistory: rest });
                        }}
                        showIcon
                    />
                </div>
                {!workObj.currrentlyWorkHere && (
                    <div className="col-12 md:col-6 lg:col-6 sm:col-12 flex flex-column">
                        <label htmlFor="password" className="employee-signup-input-label mb-2">
                            End Date
                        </label>
                        <Calendar
                            value={workObj.yearsOfEmployment.endDate}
                            className="employment-calender"
                            placeholder="January,22, 2023"
                            onChange={(e) => {
                                const [...rest] = candidateData.workHistory;
                                rest[index].yearsOfEmployment.endDate = e.value;

                                setCandidateData({
                                    ...candidateData,
                                    workHistory: rest
                                });
                            }}
                            showIcon
                        />
                    </div>
                )}
            </div>

            {!isDateValid(candidateData.workHistory[index].yearsOfEmployment.startDate, candidateData.workHistory[index].yearsOfEmployment.endDate) && (
                <div className="error-message mt-1">
                    <p className="error">Start date is greater than end date.</p>
                </div>
            )}

            <hr className="line-horizontal mt-5 " />
        </div>
    );

    return (
        <>
            {candidateData.workHistory?.length ? candidateData.workHistory.map((e, index) => renderSingleForm(e, index)) : <h1>No data added</h1>}

            <div
                className="flex justify-content-between add-button-2 cursor-pointer px-3 w-max mt-3"
                style={{ maxWidth: '200px' }}
                onClick={() => {
                    setCandidateData({
                        ...candidateData,
                        workHistory: [
                            ...candidateData.workHistory,
                            {
                                employerName: '',
                                jobTitle: '',
                                currrentlyWorkHere: false,
                                hideProfileCurrentEmployer: false,
                                yearsOfEmployment: { startDate: '', endDate: '' }
                            }
                        ]
                    });
                }}
            >
                <Image src={addIcon} alt="add" />
                <div className="add-button-text pl-3">Add employer</div>
            </div>
            <hr className="line-horizontal mt-5 " />

            <Button type="button" className="onboarding-btn w-max mt-3" onClick={handleNext}>
                Next
            </Button>
        </>
    );
};

export default CandidateOnboardingScreenSixteen;
