import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

import { loginSuccess } from '../../../redux/userSlice';

import CandidateOnboardingOne from './CandidateOnboardingOne';
import CandidateOnboardingScreenTwo from './CandidateOnboardingScreenTwo';
import CandidateOnboardingScreenThree from './CandidateOnboardingScreenThree';
import CandidateOnboardingScreenFour from './CandidateOnboardingScreenFour';
import CandidateOnboardingScreenFive from './CandidateOnboardingScreenFive';
import CandidateOnboardingScreenSix from './CandidateOnboardingScreenSix';
import CandidateOnboardingScreenSeven from './CandidateOnboardingScreenSeven';
import CandidateOnboardingScreenEight from './CandidateOnboardingScreenEight';
import CandidateOnboardingScreenNine from './CandidateOnboardingScreenNine';
import CandidateOnboardingScreenTen from './CandidateOnboardingScreenTen';
import CandidateOnboardingScreenEleven from './CandidateOnboardingScreenEleven';
import CandidateOnboardingScreenTwelve from './CandidateOnboardingScreenTwelve';
import CandidateOnboardingScreenThirteen from './CandidateOnboardingScreenThirteen';
import CandidateOnboardingScreenFourteen from './CandidateOnboardingScreenFourteen';
import CandidateOnboardingScreenFifteen from './CandidateOnboardingScreenFifteen';
import CandidateOnboardingScreenSixteen from './CandidateOnboardingScreenSixteen';
import CandidateOnboardingScreenSeventeen from './CandidateOnboardingScreenSeventeen';
import CandidateOnboardingScreenEighteen from './CandidateOnboardingScreenEighteen';

import CandidateOccupationScreenOne from './CandidateOccupationScreenOne';
import CandidateOccupationScreenTwo from './CandidateOccupationScreenTwo';
import CandidateOccupationScreenThree from './CandidateOccupationScreenThree';
import CandidateOccupationScreenFour from './CandidateOccupationScreenFour';
import CandidateOccupationScreenFive from './CandidateOccupationScreenFive';
import VerifyEmailScreen from '../login/VerifyEmailScreen';

import logo from '../../../assets/images/dental_batch_white_logo.png';

import crossIcon from '../../../assets/images/cross_icon.svg';
import backIcon from '../../../assets/images/onboarding_back_icon.svg';
import { percentageValue } from '../../../helpers/utils';
import { positionData } from '../../../helpers/constants';

const CandidateOnboarding = (props) => {
    const [candidateData, setCandidateData] = useState({
        user: '',
        availability: '',
        jobType: '',
        interestedIndustry: '',
        matterMostToYou: [],
        isWorkVisa: '',
        languages: [],
        jobLocation: [],
        preferWorkDays: [],
        currentJobOccupation: '',
        currentJob: '',
        totalDentalIndustryExperience: '',
        specialtyOfExperience: [],
        education: '',
        skills: [],
        certificates: [],
        softwares: [],
        softwareExperience: [],
        industryTools: [],
        industryToolsExperience: [],
        salaryExpectations: {},
        salaryShowOnYourProfile: true,
        otherInterestedRoles: [],
        linkedInProfileLink: '',
        workHistory: [],
        profileImage: {},
        candidateAddress: ''
    });
    const [signupBasicInfo, setSignupBasicInfo] = useState({
        email: '',
        password: '',
        mobileNumber: '',
        name: ''
    });

    const [selectedPostionSkill, setselectedPostionSkill] = useState([
        'Greet and welcome patients',
        'Assist patients with filling out forms',
        'Prepare patient charts and daily schedules',
        'Answer phones, photocopy, filing, faxing',
        'Insurance Billing/Verification',
        'Collect copayments',
        'Schedule appointments',
        'Check-In/Check out Patients',
        'Manage Patient Records',
        'Order Dental/Office Supplies',
        'Respond to Emails',
        'Manage Collections',
        'Sterilize and Disinfect Dental instruments',
        'Set Up Room and Break Down Patient Rooms'
    ]);

    const [positionSkills, setpositionSkills] = useState([]);
    const [positionSoftwares, setpositionSoftwares] = useState([]);
    const [positionIndustryTools, setpositionIndustryTools] = useState([]);

    const [currentStep, setCurrentStep] = useState(0);
    const [email, setEmail] = useState('');

    const validateStep = () => {
        let isValid = false;

        switch (currentStep) {
            case 0:
                if (candidateData.availability) isValid = true;
                break;
            case 1:
                if (candidateData.jobType !== '' && candidateData.interestedIndustry !== '') isValid = true;
                break;
            case 2:
                if (candidateData.matterMostToYou.length) isValid = true;
                break;
            case 3:
                if (candidateData.isWorkVisa !== '' || candidateData.isWorkVisa == '') isValid = true;
                break;

            case 5:
                if (candidateData.jobLocation.length) isValid = true;
                break;
            case 6:
                if (candidateData.preferWorkDays.length) isValid = true;
                break;
            case 7:
                if (candidateData.currentJobOccupation !== '' && candidateData.currentJob !== '') isValid = true;
                break;

            case 8:
                if (candidateData.skills.length) isValid = true;
                break;
            case 9:
                if (candidateData.totalDentalIndustryExperience !== '') isValid = true;
                break;
            case 10:
                if (candidateData.specialtyOfExperience.length) isValid = true;
                break;
            case 11:
                if (candidateData.education !== '') isValid = true;
                break;
            // case 12:
            //     if (candidateData.certificates.length) isValid = true;
            //     break;
            case 13:
                if (candidateData.softwares.length) isValid = true;
                break;
            case 14:
                if (candidateData.softwareExperience.length && candidateData.softwareExperience.every((software) => software.experience > 0)) isValid = true;
                break;
            // case 15:
            //     if (candidateData.industryTools.length) isValid = true;
            //     break;
            case 16:
                if (
                    candidateData.industryTools.length
                        ? candidateData.industryToolsExperience.length && candidateData.industryToolsExperience.every((tool) => tool.experience > 0)
                        : true
                )
                    isValid = true;
                break;
            case 18:
                if (candidateData.otherInterestedRoles.length) isValid = true;
                break;

            case 20:
                if (candidateData.workHistory.length) isValid = true;
                break;
            case 21:
                if (candidateData?.profileImage != null && candidateData?.profileImage != undefined && Object.entries(candidateData.profileImage).length > 0)
                    isValid = true;
                break;
            default:
                isValid = true;
                break;
        }

        return isValid;
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <CandidateOnboardingOne candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 1:
                return <CandidateOnboardingScreenTwo candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 2:
                return <CandidateOnboardingScreenThree candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 3:
                return <CandidateOnboardingScreenFour candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 4:
                return <CandidateOnboardingScreenFive candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 5:
                return <CandidateOnboardingScreenSix candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 6:
                return <CandidateOnboardingScreenSeven candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 7:
                return (
                    <CandidateOnboardingScreenEight
                        candidateData={candidateData}
                        setCandidateData={setCandidateData}
                        setselectedPostionSkill={setselectedPostionSkill}
                        positionData={positionData}
                        setpositionSkills={setpositionSkills}
                        setpositionSoftwares={setpositionSoftwares}
                        setpositionIndustryTools={setpositionIndustryTools}
                    />
                );
            case 8:
                return <CandidateOccupationScreenOne candidateData={candidateData} setCandidateData={setCandidateData} positionSkills={positionSkills} />;
            case 9:
                return <CandidateOnboardingScreenNine candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 10:
                return <CandidateOnboardingScreenTen candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 11:
                return <CandidateOnboardingScreenEleven candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 12:
                return <CandidateOnboardingScreenTwelve candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 13:
                return <CandidateOccupationScreenTwo candidateData={candidateData} setCandidateData={setCandidateData} positionSoftwares={positionSoftwares} />;

            case 14:
                return <CandidateOccupationScreenThree candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 15:
                return <CandidateOccupationScreenFour candidateData={candidateData} setCandidateData={setCandidateData} positionIndustryTools={positionIndustryTools} />;
            case 16:
                return <CandidateOccupationScreenFive candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 17:
                return (
                    <CandidateOnboardingScreenFifteen
                        candidateData={candidateData}
                        setCandidateData={setCandidateData}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                    />
                );
            case 18:
                return <CandidateOnboardingScreenThirteen candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 19:
                return <CandidateOnboardingScreenFourteen candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 20:
                return (
                    <CandidateOnboardingScreenSixteen
                        candidateData={candidateData}
                        setCandidateData={setCandidateData}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                    />
                );
            case 21:
                return <CandidateOnboardingScreenSeventeen candidateData={candidateData} setCandidateData={setCandidateData} />;
            case 22:
                return (
                    <CandidateOnboardingScreenEighteen
                        candidateData={candidateData}
                        setCandidateData={setCandidateData}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                        setEmail={setEmail}
                        signupBasicInfo={signupBasicInfo}
                        setSignupBasicInfo={setSignupBasicInfo}
                    />
                );
            case 23:
                return <VerifyEmailScreen email={email} from={'onboarding'} setVisible={props?.setVisible} setCurrentStep={setCurrentStep} />;
            default:
                return <CandidateOnboardingOne candidateData={candidateData} setCandidateData={setCandidateData} />;
        }
    };

    useEffect(() => {}, [currentStep]);

    return (
        <Dialog visible={props?.visible} className="dialog-box" blockScroll={true} onHide={() => props?.setVisible(false)}>
            <div className="flex dialog-container h-full overflow-hidden">
                <div
                    className="flex flex-column justify-content-between col-4 sm:col-6 md:col-4 lg:col-4 employer-onboarding-logo-container"
                    style={{ background: '#854dff' }}
                >
                    {' '}
                    <div className="flex justify-content-center align-items-center mt-5" style={{ height: '90%' }}>
                        <Image src={logo} alt="Logo" className="batch-logo" />
                    </div>
                    {currentStep !== 23 && (
                        <Image
                            src={backIcon}
                            alt="Logo"
                            className={`cursor-pointer batch-back-btn ${currentStep === 0 && ''}`}
                            onClick={() => {
                                if (candidateData.industryTools.length == 0 && currentStep === 17) {
                                    setCurrentStep(currentStep - 2);
                                } else setCurrentStep(currentStep - 1);
                            }}
                            style={{ visibility: currentStep === 0 ? 'hidden' : 'visible' }}
                        />
                    )}
                </div>

                <div className="col-8 sm:col-6 md:col-8 lg:col-8 employer-onboarding-content-container h-full overflow-x-hidden">
                    <div className="flex flex-column p-4">
                        <Image
                            src={backIcon}
                            alt="logo"
                            width="36px"
                            height="36px"
                            style={{ position: 'absolute', top: 5, left: 5 }}
                            onClick={() => {
                                setCurrentStep(currentStep - 1);
                            }}
                            className="back-image cursor-pointer"
                        />
                        <h2 className="progress-bar-text">{`${percentageValue(currentStep + 1, 24)}% Completed!`}</h2>
                        <ProgressBar value={percentageValue(currentStep + 1, 24)} displayValueTemplate={() => <></>} />
                        <Image
                            src={crossIcon}
                            alt="cross icon"
                            width="20px"
                            height="20px"
                            className="cursor-pointer"
                            style={{ position: 'absolute', top: 0, right: 0, padding: 10 }}
                            onClick={() => {
                                setCandidateData({
                                    user: '',
                                    availability: '',
                                    jobType: '',
                                    interestedIndustry: '',
                                    matterMostToYou: [],
                                    isWorkVisa: '',
                                    languages: [],
                                    jobLocation: '',
                                    preferWorkDays: [],
                                    currentJobOccupation: '',
                                    totalDentalIndustryExperience: '',
                                    specialtyOfExperience: [],
                                    education: '',
                                    skills: [],
                                    certificates: [],
                                    softwares: [],
                                    softwareExperience: [],
                                    industryTools: [],
                                    industryToolsExperience: [],
                                    salaryExpectations: {},
                                    salaryShowOnYourProfile: false,
                                    otherInterestedRoles: [],
                                    linkedInProfileLink: '',
                                    workHistory: [],
                                    profileImage: {}
                                });
                                setCurrentStep(0);
                                props?.setVisible(false);
                            }}
                        />

                        {renderStep()}
                        {![17, 20, 22, 23].includes(currentStep) && <hr className="line-horizontal mt-3" />}

                        <div className="flex flex-wrap gap-3 mt-4">
                            {[4, 19, 12, 15].includes(currentStep) && (
                                <Button
                                    type="button"
                                    className="onboarding-btn-2  w-max "
                                    onClick={() => {
                                        if (currentStep === 15) {
                                            setCurrentStep(currentStep + 2);
                                        } else setCurrentStep(currentStep + 1);
                                    }}
                                >
                                    Skip
                                </Button>
                            )}
                            {![17, 20, 22, 23].includes(currentStep) && (
                                <Button
                                    type="button"
                                    className="onboarding-btn  w-max"
                                    disabled={!validateStep()}
                                    onClick={() => {
                                        if (candidateData.industryTools.length == 0 && currentStep === 15) {
                                            setCurrentStep(currentStep + 2);
                                        } else setCurrentStep(currentStep + 1);
                                    }}
                                >
                                    Next
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default CandidateOnboarding;
