import React, { useContext, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ToastContext } from '../../../context/ToastContext';

import { sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';

const ForgetPasswordScreen = (props) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);

    const toast = useContext(ToastContext);

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
            return false;
        }
        setEmailError('');
        return true;
    };

    const handleNext = async () => {
        if (validateEmail()) {
            setLoading(true);

            const forgotPassword = await sendRequest(`${process.env.REACT_APP_API_URL}api/v1/user/forgetPassword`, { payload: { email } });

            if (forgotPassword.status === 200) {
                showSuccessToast(toast, 'Verification Code', forgotPassword?.data?.desc);
                props?.setEmail(email);
                props?.setCurrentStep(props?.currentStep + 1);
            } else {
                showErrorToast(toast, 'Verification Code', forgotPassword?.data?.desc);
            }

            setLoading(false);
        }
    };

    return (
        <>
            <h1 className="login-welcome-text">Forget Password</h1>
            <p className="employer-questions-heading mt-2">Enter your email to get verification code!</p>
            <div className="grid mt-5">
                <div className="col-12 md:col-12 lg:col-12 sm:col-12 flex flex-column">
                    <label htmlFor="email" className="employee-signup-input-label mb-2">
                        Email
                    </label>
                    <InputText
                        id="email"
                        value={email}
                        className="employee-signup-input border-0 w-full xl:w-10"
                        placeholder="sampl@gmail.com"
                        aria-describedby="email-help"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && <p className="error mt-2">{emailError}</p>}
                </div>
            </div>
            <hr className="line-horizontal mt-3 " />
            <Button type="button" className="onboarding-btn mt-4 w-max" onClick={handleNext}>
                Next
            </Button>
        </>
    );
};

export default ForgetPasswordScreen;
