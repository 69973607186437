import flexableWork from '../../assets/images/flexable_work_hours_image.svg';
import paidLeaves from '../../assets/images/paid_leaves_image.svg';
import dentalCare from '../../assets/images/dental_care_image.svg';
import businessFinance from '../../assets/images/business_and_finance_image.svg';
import disable from '../../assets/images/disabled_image.svg';
import dumbbell from '../../assets/images/dumbbell_image.svg';
import pto from '../../assets/images/dental/unlimited_pto.svg';
import bonus from '../../assets/images/dental/employee_bonus_icon.svg';
import rockingImage from '../../assets/images/rocking_image.svg';
import medical from '../../assets/images/dental/medical_insurance_icon.svg';
import education from '../../assets/images/dental/education_icon.svg';

import lifeInsurance from '../../assets/images/dental/life_insurance_icon.svg';

import tooth from '../../assets/images/dental/dental_care_icon.svg';
export const employeBenifits = [
    {
        name: 'PTO',
        image: pto
    },
    {
        name: 'Employee Bonuses',
        image: bonus
    },
    {
        name: 'Medical',
        image: medical
    },
    {
        name: 'Dental',
        image: tooth
    },
    {
        name: 'Education',
        image: education
    },
    {
        name: 'Flexible work hours',
        image: flexableWork
    },
    {
        name: 'Paid Holidays',
        image: paidLeaves
    },
    {
        name: 'Life Insurance',
        image: lifeInsurance
    },

    {
        name: 'Free dental work for employees',
        image: dentalCare
    },
    {
        name: 'Profit-sharing program',
        image: businessFinance
    },
    {
        name: 'Short-term disability',
        image: disable
    },
    {
        name: 'Gym membership',
        image: dumbbell
    },
    {
        name: '401k',
        image: rockingImage
    }
];
