import axios from 'axios';
import { showSuccessToast } from './toast-helper';
import moment from 'moment';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_UI_URL = process.env.REACT_APP_BASE_URL;
// export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export const isEmpty = (value) => {
    if (value === null) {
        return true;
    }
    if (typeof value !== 'number' && value === '') {
        return true;
    }
    if (value === 'undefined' || value === undefined) {
        return true;
    }
    if (value !== null && typeof value === 'object' && !Object.keys(value).length) {
        return true;
    }
    return false;
};

export const getLoggedUser = () => {
    const user = localStorage.getItem('userProfile');
    return user ? JSON.parse(user) : '';
};
export const weekDays = [
    { day: 'Monday', active: true },
    { day: 'Tuesday', active: true },
    { day: 'Wednesday', active: false },
    { day: 'Thursday', active: true },
    { day: 'Friday', active: false },
    { day: 'Saturday', active: false },
    { day: 'Sunday', active: false }
];

export const calculateLastActive = (time) => {
    const now = moment();
    const lastLogin = moment(time);
    const diffInMinutes = now.diff(lastLogin, 'minutes');
    let formattedTime;

    if (diffInMinutes < 60) {
        formattedTime = `Active ${diffInMinutes}m ago`;
    } else if (diffInMinutes < 1440) {
        // Less than 24 hours (60 minutes * 24)
        const diffInHours = Math.floor(diffInMinutes / 60);
        formattedTime = `Active ${diffInHours}h ago`;
    } else if (diffInMinutes < 43200) {
        // Less than 30 days (60 minutes * 24 hours * 30)
        const diffInDays = Math.floor(diffInMinutes / 1440);
        formattedTime = `Active ${diffInDays}d ago`;
    } else if (diffInMinutes < 525600) {
        // Less than 365 days (60 minutes * 24 hours * 365)
        const diffInMonths = Math.floor(diffInMinutes / 43200);
        formattedTime = `Active ${diffInMonths}mo ago`;
    } else {
        const diffInYears = Math.floor(diffInMinutes / 525600);
        formattedTime = `Active ${diffInYears}y ago`;
    }
    return formattedTime;
};

export const calculateJobPostTime = (time) => {
    const now = moment();
    const lastActive = moment(time);
    const diffInMinutes = now.diff(lastActive, 'minutes');
    let formattedTime;

    if (diffInMinutes < 60) {
        formattedTime = `${diffInMinutes} minutes ago`;
    } else if (diffInMinutes < 1440) {
        // Less than 24 hours (60 minutes * 24)
        const diffInHours = Math.floor(diffInMinutes / 60);
        formattedTime = `${diffInHours} hours ago`;
    } else if (diffInMinutes < 43200) {
        // Less than 30 days (60 minutes * 24 hours * 30)
        const diffInDays = Math.floor(diffInMinutes / 1440);
        formattedTime = `${diffInDays} days ago`;
    } else if (diffInMinutes < 525600) {
        // Less than 365 days (60 minutes * 24 hours * 365)
        const diffInMonths = Math.floor(diffInMinutes / 43200);
        formattedTime = `${diffInMonths} month ago`;
    } else {
        const diffInYears = Math.floor(diffInMinutes / 525600);
        formattedTime = `${diffInYears} year ago`;
    }
    return formattedTime;
};

export function convertMinutesToDaysAndHours(milliseconds) {
    // Convert milliseconds to seconds
    const seconds = Math.floor(milliseconds / 1000);

    // Calculate the corresponding values
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const remainingMinutes = minutes % 60;
    const remainingSeconds = seconds % 60;
    return `${days}D ${hours}H ${remainingMinutes}M ${remainingSeconds}S`;
}

export const isCurrentDateTimeBeforeGivenDateTime = (givenDateTime) => {
    const currentDateTime = moment(); // Get the current date and time
    const givenDateTimeMoment = moment(givenDateTime); // Parse the given date and time

    // Check if the current date and time are before the given date and time

    return currentDateTime.isBefore(givenDateTimeMoment, 'minute');
};
export const getUserToken = () => {
    const token = localStorage.getItem('token');
    return token ? token : {};
};

export const DateDifference = (dt1, dt2) => {
    const date1 = new Date(dt1);
    const date2 = new Date(dt2);

    // Calculate the year difference
    return Math?.abs(date1.getFullYear() - date2.getFullYear());
};

export const sendRequest = async (url, data) => {
    const method = data.method || 'post';
    const payload = data.payload || {};
    const headers = data.headers || {};
    const { token, ...rest } = payload;

    let axios_config = {
        method: method,
        url: url,
        headers: {
            'x-access-token': token,
            ...headers
        },
        validateStatus: () => true //Always resolve promise on every http status
    };
    if (method == 'get') {
        axios_config['params'] = rest;
    } else {
        axios_config['data'] = rest;
    }
    try {
        const response = await axios(axios_config);
        // debugger;
        if (response.status === 401) {
            console.log('Something went wrong Plz Refresh');
        }
        if (response.status === 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('userProfile');
            window.location.href = '/';
            localStorage.clear();
        }
        return response;
    } catch (error) {
        return error;
    }
};
export const imageDisplay = (path) => {
    return `${BASE_URL}${path}`;
};
export const copyToClipboard = (toast, text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast(toast, 'Copied');
};
export const successToast = (str) => {
    // toast.success(str);
};

export const errorToast = (str) => {
    const text = str.replace('-', '<br/>');
    const textHtml = <span dangerouslySetInnerHTML={{ __html: text }}></span>;
    // toast.error(textHtml);
};

export const percentageValue = (currentValue, components) => {
    const totalValue = 100;
    const per = Math.floor(totalValue / components);

    const e = per * currentValue;
    return currentValue === components ? 100 : e;
};
export function formatPrice(price) {
    if (isNaN(price)) {
        return 'Invalid Price';
    }
    let formattedPrice;
    formattedPrice = Number(price).toFixed(1);

    // Check if the price is a whole number (e.g., 10.0, 20.0, etc.)
    if (formattedPrice.endsWith('.0')) {
        formattedPrice = parseInt(price).toString(); // Remove .0 and convert to string
    }

    return formattedPrice.replace(/\B(?=(\d{3})+\b)/g, ',');
}
export const pillsColors = [
    { item: 'Work environment', color: '#8850ff' },
    { item: 'Career advancement', color: '#1a1a1a' },
    { item: 'Flexible work hours', color: '#55a4e6' },
    { item: 'Diverse workforce', color: '#FFC300' },
    { item: 'Modern & updated office', color: '#39c21e' },
    { item: 'Empathetic Leadership', color: '#e00000' },
    { item: 'Recognition & rewards', color: '#90e301' },
    { item: 'Fun office environment', color: '#ff1b99' },
    { item: 'Competitive Pay', color: '#ffa81b' },
    { item: 'Casual office attire', color: '#4a4a4a' },
    { item: 'State-of-the-Art Equipment', color: '#ca4d00' },
    { item: 'Office Pets', color: '#0003ca' },
    { item: 'Overtime Opportunities', color: '#55a4e6' },
    { item: 'Large Staff', color: '#00a3a3' },
    { item: 'Small Staff', color: '#a32e00' },
    { item: 'Benefits', color: '#a3005f' },
    { item: 'PTO', color: '#682a2a' },
    { item: 'Employee Bonuses', color: '#581cf1' },
    { item: 'Medical', color: '#6a00b6' },
    { item: 'Dental', color: '#3db600' },
    { item: 'Flexible work hours', color: '#b60000' },
    { item: 'Paid Holidays', color: '#373636' },
    { item: 'Life Insurance', color: '#363737' },
    { item: 'Free dental work for employees', color: '#004781' },
    { item: 'Profit-sharing program', color: '#9cb400' },
    { item: 'Short-term disability', color: '#0075b4' },
    { item: 'Gym membership', color: '#60b400' },
    { item: 'Education', color: '#ff5733' },
    { item: 'Unlimited PTO', color: '#4cff33' },
    { item: '401k', color: '#ffd438' }
];

const pillsColorsWithBgColor = pillsColors.map((item) => ({
    ...item,
    bgColor: `${item.color}20` // Add 'CC' for 80% opacity
}));

export function getColorForItem(itemName, bg) {
    let foundItem;
    if (bg) foundItem = pillsColorsWithBgColor.find((obj) => obj.item === itemName);
    else foundItem = pillsColorsWithBgColor.find((obj) => obj.item === itemName);
    if (bg && foundItem) {
        return foundItem.bgColor;
    }
    if (bg && !foundItem) {
        return '#19a50620';
    }
    if (foundItem) {
        return foundItem.color;
    } else {
        return '#19a506'; // Return null if the item is not found
    }
}
