import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenTwelve = ({ candidateData, setCandidateData }) => {
    const [selectedOfficeSpecialities, setselectedOfficeSpecialities] = useState([]);

    const officeSpecialities = [
        'Sealant Certified',
        'X-Ray Certified',
        'Nitrous Oxide Certified',
        'Local Anesthetic Certified',
        'CEREC Certified',
        'CPR/BLS Certified',
        'DANB Certified',
        'Certified Dental Technician',
        'Laser Certified',
        'CPR/BLS Certification',
        'Soft Tissue Curettage Certificate',
        'RDA',
        'CDA',
        'EFDA',
        'AAID',
        'Infection Control Certification',
        'Dental Practice Act'
    ];

    const specialitiesHandler = (speciality) => {
        const updateSelectedOfficeSpecialities = selectedOfficeSpecialities.find((el) => el === speciality);
        if (updateSelectedOfficeSpecialities) {
            const selectedOfficeSpecialitiesfilter = selectedOfficeSpecialities.filter((el) => el !== speciality);
            setselectedOfficeSpecialities(selectedOfficeSpecialitiesfilter);
            setCandidateData({ ...candidateData, certificates: selectedOfficeSpecialitiesfilter.map((el) => el) });
        } else {
            setselectedOfficeSpecialities([...selectedOfficeSpecialities, speciality]);
            setCandidateData({ ...candidateData, certificates: [...candidateData.certificates, speciality] });
        }
    };

    const checkSpecialities = (name) => {
        const indexOfEmployee = selectedOfficeSpecialities.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setselectedOfficeSpecialities([...candidateData?.certificates]);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">List your Licenses and certificates.</p>
            <h1 className="job-posting-select-all-text mt-2 mb-0 cursor-pointer">
                Select all that apply. Please be as honest as possible as we do verify your selections with the DCA and SRTA
            </h1>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {officeSpecialities?.map((speciality, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            checkSpecialities(speciality) ? 'available-questions-options-select' : 'available-questions-options'
                        }`}
                        onClick={() => specialitiesHandler(speciality)}
                    >
                        <div className={`${checkSpecialities(speciality) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {speciality}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenTwelve;
