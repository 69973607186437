import React, { useEffect, useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

import { ToastContext } from '../../../context/ToastContext';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';

import crossIcon from '../../../assets/images/cross_black_icon.svg';
import { dentalOptions } from '../candidate/softwareOptions';

const EditJobDentalTools = ({ visible, setVisible, jobData, setJobData, jobId, setJobDetail }) => {
    const [selectedTools, setselectedTools] = useState([]);
    const industryTools = ['Mouth Mirror', 'Dental Explorer', 'Dental Drill', 'Dental Syringe', 'Excavators', 'Intraoral Camera/Scanner', 'Dental Laser', 'NOMAD Pro'];

    const toolsHandler = (tool) => {
        const updateTools = selectedTools.find((el) => el === tool);
        if (updateTools) {
            const selectedToolsfilter = selectedTools.filter((el) => el !== tool);
            setselectedTools(selectedToolsfilter);
            const updateTools = selectedToolsfilter.map((el) => el);
            setJobData({ ...jobData, dentalToolsExperience: updateTools });
        } else {
            setselectedTools([...selectedTools, { tool }]);
            setJobData({ ...jobData, dentalToolsExperience: [...jobData?.dentalToolsExperience, tool] });
        }
    };

    const checkTools = (name) => {
        const indexOfEmployee = selectedTools.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    const toast = useContext(ToastContext);
    const userToken = getUserToken();

    const jobInfoUpdate = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/job/update/${jobId}`, {
            method: 'post',
            payload: jobData,
            headers: {
                'x-access-token': userToken
            }
        });
        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            setJobDetail(response.data.data || []);
            setVisible(false);
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    useEffect(() => {
        setselectedTools([...jobData.dentalToolsExperience]);
    }, [jobData.dentalToolsExperience]);

    return (
        <Dialog visible={visible} position={'center'} className="dental-tools-edit" onHide={() => setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Dental Tools</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => setVisible(false)}
                    />
                </div>

                <p className="employer-questions-heading mt-5">
                    Candidates “must-have” experience with these dental industry tools in order to meet the minimum qualifications for this job.
                </p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                    {dentalOptions?.map((tool, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center  gap-3 ${
                                checkTools(tool?.name) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => toolsHandler(tool?.name)}
                        >
                            <div className={`${checkTools(tool?.name) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {tool?.name}
                            </div>
                        </div>
                    ))}
                </div>

                <hr className="line-horizontal mt-5" />

                <Button type="button" className="onboarding-btn w-max px-6 mt-5" onClick={jobInfoUpdate}>
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditJobDentalTools;
