export const dentalOptions = [
    'Carestream',
    'Cerec Restorations',
    'DEXIS',
    'EagleDigital X-Rayssoft',
    'Easy Dental',
    'Kodak',
    'Instrumentarium',
    'Gendex',
    'Intraoral Camera/Scanner',
    'KaVo',
    'Manual Radiography/X-Rays',
    'NOMAD Pro',
    'Schick',
    'VELscope',
    'CBCT',
    'CT scanner',
    'SoftDent',
    'Vixwin',
    'Windent Software',
    'Ortho2',
    'Softdent',
    'Dentrix',
    'QuickBooks',
    'Ray America',
    'RECALLMAX',
    'Reveal Clear Aligners',
    'Salesforce',
    'Simpli5',
    'ScanX',
    'Smart Moves',
    'Sota image',
    'Spark Aligners',
    'Square for Dentists',
    'Tru-Line Clear Aligners',
    'Zendura FLX',
    'Zoho CRM',
    '3M Clarity Aligners',
    'adit',
    'Angelalign',
    'CADSmile',
    'Carestream Dental',
    'Clarity Aligners',
    'ClearCorrect',
    'ClearPath Aligners',
    'ClearX',
    'Dentalally',
    'Dolphin Imaging',
    'Dovetail',
    'Harmony Clear Aligners',
    'HIOSSEN IMPLANT',
    'Hubspot',
    'Lighthouse 360',
    'MTM Clear Aligners',
    'novaDONTICS',
    'OperaDDS',
    'OrthoSnap',
    'Pipedrive',
    'Planmecca',
    'CEREC',
    'CEREC CAD/CAM Software',
    'Exocad',
    'Dental Wings',
    '3Shape Dental System',
    'DentalDesigner',
    'OrthoFX',
    'Invisalign',
    'SureSmile',
    'DentalXChange',
    'DentalWriter',
    'Freshworks',
    'PROSITES',
    'Weave'
].map((name) => ({ name, experience: 1 }));

export const softwareOptions = [
    { name: 'Instrumentarium', experience: 1 },
    { name: 'Microsoft Office Suite', experience: 1 },
    { name: 'Macpractice', experience: 1 },
    { name: 'Orthotrac', experience: 1 },
    { name: 'Planmeca', experience: 1 },
    { name: 'PracticeWorks', experience: 1 },
    { name: 'Sirona', experience: 1 },
    { name: 'QSI Dental', experience: 1 },
    { name: 'SoftDent', experience: 1 },
    { name: 'Vixwin', experience: 1 },
    { name: 'Windent Software', experience: 1 },
    { name: 'Ortho2', experience: 1 },
    { name: 'Softdent', experience: 1 },
    { name: 'ABELDent', experience: 1 },
    { name: 'axiUm Dental Software', experience: 1 },
    { name: 'Carestream', experience: 1 },
    { name: 'CS SoftDent', experience: 1 },
    { name: 'Dentisoft Office Cloud', experience: 1 },
    { name: 'Dentrix Enterprise', experience: 1 },
    { name: 'G Suite', experience: 1 },
    { name: 'Henry Schein Dentrix Ascend', experience: 1 },
    { name: 'Henry Schein One', experience: 1 },
    { name: 'MacPractice DDS', experience: 1 },
    { name: 'MOGO', experience: 1 },
    { name: 'Planet DDS Denticon', experience: 1 },
    { name: 'Practice Management Software (PMS)', experience: 1 },
    { name: 'Practice Mojo', experience: 1 },
    { name: 'RevenueWell', experience: 1 },
    { name: 'RevenueXL', experience: 1 },
    { name: 'Sota cloud', experience: 1 },
    { name: 'XLDent', experience: 1 },
    { name: 'YAPI', experience: 1 },
    { name: 'AppointmentPlus', experience: 1 },
    { name: 'Dentrix', experience: 1 },
    { name: 'Curve Dental', experience: 1 },
    { name: 'Dentimax', experience: 1 },
    { name: 'Eaglesoft', experience: 1 },
    { name: 'Open Dental', experience: 1 },
    { name: 'CAD/CAM', experience: 1 },
    { name: 'CAESY', experience: 1 },
    { name: 'Dental Vision', experience: 1 },
    { name: 'Easy Dental', experience: 1 },
    { name: 'Kodak', experience: 1 }
];
export const languagesList = [
    'Mandarin',
    'Spanish',
    'English',
    'Hindi',
    'Arabic',
    'Portuguese',
    'Bengali',
    'Russian',
    'Japanese',
    'Punjabi',
    'German',
    'Javanese',
    'Wu (inc. Shanghainese)',
    'Malay/Indonesian',
    'Telugu',
    'Vietnamese',
    'Korean',
    'French',
    'Marathi',
    'Tamil',
    'Urdu',
    'Turkish',
    'Italian',
    'Cantonese',
    'Thai',
    'Gujarati',
    'Jin',
    'Southern Min',
    'Persian',
    'Polish',
    'Pashto',
    'Kannada',
    'Xiang (Hunnanese)',
    'Malayalam',
    'Sundanese',
    'Hausa',
    'Odia (Oriya)',
    'Burmese',
    'Hakka',
    'Ukrainian',
    'Bhojpuri',
    'Tagalog',
    'Yoruba',
    'Maithili',
    'Uzbek',
    'Sindhi',
    'Amharic',
    'Fula',
    'Romanian',
    'Oromo',
    'Igbo',
    'Azerbaijani',
    'Awadhi',
    'Gan Chinese',
    'Cebuano (Visayan)',
    'Dutch',
    'Kurdish',
    'Serbo-Croatian',
    'Malagasy',
    'Saraiki',
    'Nepali',
    'Sinhalese',
    'Chittagonian',
    'Zhuang',
    'Khmer',
    'Turkmen',
    'Assamese',
    'Madurese',
    'Somali',
    'Marwari',
    'Magahi',
    'Haryanvi',
    'Hungarian',
    'Chhattisgarhi',
    'Greek',
    'Chewa',
    'Deccan',
    'Akan',
    'Kazakh',
    'Northern Min',
    'Sylheti',
    'Zulu',
    'Czech',
    'Kinyarwanda',
    'Dhundhari',
    'Haitian Creole',
    'Eastern Min',
    'Ilocano',
    'Quechua',
    'Kirundi',
    'Swedish',
    'Hmong',
    'Shona',
    'Uyghur',
    'Hiligaynon',
    'Mossi',
    'Xhosa',
    'Belarusian',
    'Balochi',
    'Konkani'
];
