import React, { useContext, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';
import { ToastContext } from '../../../context/ToastContext';
import { sendRequest } from '../../../helpers/utils';
import axios from 'axios';

const CandidateOnboardingScreenEighteen = ({ candidateData, setCandidateData, setEmail, setCurrentStep, currentStep, signupBasicInfo, setSignupBasicInfo }) => {
    const toast = useContext(ToastContext);

    const schema = yup.object().shape({
        email: yup.string().email('Must be valid Email').required('Email is required!'),
        password: yup.string().required('Password is required!'),
        mobileNumber: yup.number().required('Mobile Number is required!'),
        name: yup.string().required('Name is required!'),
        address1: yup.string().required('Street address required!'),
        address2: yup.string(),
        city: yup.string().required('City Name is required!'),
        state: yup.string().required('State is required!'),
        zipCode: yup.string().required('Zip Code is required!'),
        country: yup.string().required('Country is required!')
    });

    // Form Intial Values - If its New Form
    const initialValues = {
        email: '',
        password: '',
        mobileNumber: '',
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
    };

    const handleSubmit = async (values) => {
        const { email, password, mobileNumber, name } = values;
        setEmail(email);

        try {
            const formDataToSend = new FormData();

            formDataToSend.append('files', candidateData.profileImage);

            // Make the Axios POST request
            const profileImageResponse = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/media/upload`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            delete candidateData.profileImage;

            const signupData = {
                name: name,
                email: email,
                phoneNumber: mobileNumber,
                password: password,
                role: 'candidate',
                profileImage: profileImageResponse?.data?.data[0]?.path,
                preferences: {
                    candidate: {
                        ...candidateData,
                        candidateAddress: `${values.address1}, ${values.address2}, ${values.city}, ${values.state}, ${values.zipCode}, ${values.country}`
                    }
                }
            };
            const signup = await sendRequest(`${process.env.REACT_APP_API_URL}api/v1/user/register`, {
                payload: signupData
            });

            if (signup.status === 201) {
                showSuccessToast(toast, 'Sign Up', signup?.data?.message);
                setCandidateData({
                    user: '',
                    availability: '',
                    jobType: '',
                    interestedIndustry: '',
                    matterMostToYou: [],
                    isWorkVisa: '',
                    languages: [],
                    jobLocation: '',
                    preferWorkDays: [],
                    currentJobOccupation: '',
                    totalDentalIndustryExperience: '',
                    specialtyOfExperience: [],
                    education: '',
                    skills: [],
                    certificates: [],
                    softwares: [],
                    softwareExperience: [],
                    industryTools: [],
                    industryToolsExperience: [],
                    salaryExpectations: {},
                    salaryShowOnYourProfile: false,
                    otherInterestedRoles: [],
                    linkedInProfileLink: '',
                    workHistory: [],
                    profileImage: {},
                    candidateAddress: ''
                });
                setCurrentStep(currentStep + 1);
            } else {
                showErrorToast(toast, 'Sign Up', signup?.data?.desc);
            }

            // Reset the form after successful submission (optional)
        } catch (error) {
            // Handle errors (e.g., server error or network issue)

            showErrorToast(toast, 'Sign Up', error);
        }
    };

    useEffect(() => {
        initialValues.email = signupBasicInfo.email;
        initialValues.password = signupBasicInfo.password;
        initialValues.mobileNumber = signupBasicInfo.mobileNumber;
        initialValues.name = signupBasicInfo.name;
    }, []);

    return (
        <>
            <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <p className="employer-questions-heading mt-5">Last step, Sign Up!</p>

                        <div className="flex flex-column mt-5">
                            <label htmlFor="name" className="employee-signup-input-label mb-2">
                                Full Name
                            </label>
                            <InputText
                                id="name"
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={(e) => {
                                    handleChange(e);
                                    setSignupBasicInfo({ ...signupBasicInfo, name: e.target.value });
                                }}
                                className="employee-signup-input border-0 w-10"
                                placeholder="name"
                                aria-describedby="username-help"
                            />
                            <div className="error-message mt-1">{touched.name && errors.name && <p className="error">{errors.name}</p>}</div>
                        </div>

                        <div className="flex flex-column mt-3">
                            <label htmlFor="mobileNumber" className="employee-signup-input-label mb-2">
                                Mobile Number
                            </label>
                            <InputText
                                id="mobileNumber"
                                type="text"
                                name="mobileNumber"
                                value={values.mobileNumber}
                                onChange={(e) => {
                                    handleChange(e);
                                    setSignupBasicInfo({ ...signupBasicInfo, mobileNumber: e.target.value });
                                }}
                                className="employee-signup-input border-0 w-10"
                                placeholder="ex: 4088882424"
                                aria-describedby="username-help"
                            />
                            <div className="error-message mt-1">{touched.mobileNumber && errors.mobileNumber && <p className="error">{errors.mobileNumber}</p>}</div>
                        </div>

                        <div className="flex flex-column mt-3">
                            <label htmlFor="email" className="employee-signup-input-label mb-2">
                                Email
                            </label>
                            <InputText
                                id="email"
                                name="email"
                                value={values.email}
                                onChange={(e) => {
                                    handleChange(e);
                                    setSignupBasicInfo({ ...signupBasicInfo, email: e.target.value });
                                }}
                                className="employee-signup-input border-0 w-10"
                                placeholder="sampl@gmail.com"
                                aria-describedby="username-help"
                            />
                            <div className="error-message mt-1">{touched.email && errors.email && <p className="error">{errors.email}</p>}</div>
                        </div>

                        <div className="flex flex-column mt-3 candidate-onboarding">
                            <label htmlFor="password" className="employee-signup-input-label mb-2">
                                Password
                            </label>
                            <Password
                                id="password"
                                type="password"
                                name="password"
                                value={values.password}
                                onChange={(e) => {
                                    handleChange(e);
                                    setSignupBasicInfo({ ...signupBasicInfo, password: e.target.value });
                                }}
                                placeholder="*********"
                                promptLabel="Choose a password"
                                weakLabel="Too simple"
                                mediumLabel="Average complexity"
                                strongLabel="Complex password"
                                inputClassName="employee-signup-input border-0 w-10"
                                toggleMask
                            />
                            <div className="error-message mt-1">{touched.password && errors.password && <p className="error">{errors.password}</p>}</div>
                        </div>

                        <div className="flex flex-column mt-3 candidate-onboarding">
                            <label htmlFor="address1" className="employee-signup-input-label mb-2">
                                Address 1
                            </label>
                            <InputText
                                id="address1"
                                type="text"
                                name="address1"
                                className="employee-signup-input border-0 w-10"
                                placeholder="street address"
                                value={values.address1}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                aria-describedby="username-help"
                            />
                            <div className="error-message mt-1">{touched.address1 && errors.address1 && <p className="error">{errors.address1}</p>}</div>
                        </div>
                        <div className="flex flex-column mt-3 candidate-onboarding">
                            <label htmlFor="address2" className="employee-signup-input-label mb-2">
                                Address 2
                            </label>
                            <InputText
                                id="address2"
                                type="text"
                                name="address2"
                                className="employee-signup-input border-0 w-10"
                                placeholder="Suite/Apt/Unit number"
                                value={values.address2}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                aria-describedby="username-help"
                            />
                        </div>
                        <div className="flex flex-column mt-3 candidate-onboarding">
                            <label htmlFor="city" className="employee-signup-input-label mb-2">
                                City
                            </label>
                            <InputText
                                id="city"
                                type="text"
                                name="city"
                                className="employee-signup-input border-0 w-10"
                                placeholder="City"
                                value={values.city}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                aria-describedby="username-help"
                            />
                            <div className="error-message mt-1">{touched.city && errors.city && <p className="error">{errors.city}</p>}</div>
                        </div>

                        <div className="flex flex-column mt-3 candidate-onboarding">
                            <label htmlFor="state" className="employee-signup-input-label mb-2">
                                State
                            </label>
                            <InputText
                                id="state"
                                type="text"
                                name="state"
                                className="employee-signup-input border-0 w-10"
                                placeholder="State"
                                value={values.state}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                aria-describedby="username-help"
                            />
                            <div className="error-message mt-1">{touched.state && errors.state && <p className="error">{errors.state}</p>}</div>
                        </div>

                        <div className="flex flex-column mt-3 candidate-onboarding">
                            <label htmlFor="zipCode" className="employee-signup-input-label mb-2">
                                Zip Code
                            </label>
                            <InputText
                                id="zipCode"
                                type="text"
                                name="zipCode"
                                className="employee-signup-input border-0 w-10"
                                placeholder="Zip code"
                                value={values.zipCode}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                aria-describedby="username-help"
                            />
                            <div className="error-message mt-1">{touched.zipCode && errors.zipCode && <p className="error">{errors.zipCode}</p>}</div>
                        </div>
                        <div className="flex flex-column mt-3 candidate-onboarding">
                            <label htmlFor="country" className="employee-signup-input-label mb-2">
                                Country
                            </label>
                            <InputText
                                id="country"
                                type="text"
                                name="country"
                                className="employee-signup-input border-0 w-10"
                                placeholder="Country"
                                value={values.country}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                aria-describedby="username-help"
                            />
                            <div className="error-message mt-1">{touched.country && errors.country && <p className="error">{errors.country}</p>}</div>
                        </div>

                        <hr className="line-horizontal mt-5 " />
                        <Button type="submit" className="onboarding-btn w-max px-6 mt-4">
                            Sign Up
                        </Button>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default CandidateOnboardingScreenEighteen;
