import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import { Timeline } from 'primereact/timeline';

import lifeInsurance from '../../assets/images/life_insurance_icon.svg';
import tooth from '../../assets/images/dental_insurance_icon.svg';
import { BASE_URL, calculateJobPostTime, formatPrice, getLoggedUser, getUserToken, imageDisplay, sendRequest } from '../../helpers/utils';
import { showErrorToast } from '../../helpers/toast-helper';
import { ToastContext } from '../../context/ToastContext';

import youtubIcon from '../../assets/images/youtub_icon_2.svg';
import twitterIcon from '../../assets/images/twitter.png';
import tiktokIcon from '../../assets/images/tiktok_icon_2.svg';
import instaIcon from '../../assets/images/insta_icon_2.svg';
import linkdinIcon from '../../assets/images/linkdin_icon_2.svg';
import yelp from '../../assets/images/yelp.png';
import linkedin from '../../assets/images/linkedin.png';
import snapchat from '../../assets/images/snapchat.png';
import google from '../../assets/images/google.png';
import EditJobInformation from '../../components/dialog/job_edit/EditJobInformation';
import EditJobCertificates from '../../components/dialog/job_edit/EditJobCertificates';
import EditJobSoftwares from '../../components/dialog/job_edit/EditJobSoftwares';
import EditJobDentalTools from '../../components/dialog/job_edit/EditJobDentalTools';
import EditJobWorkDays from '../../components/dialog/job_edit/EditJobWorkDays';
import EditJobInterviewProcess from '../../components/dialog/job_edit/EditJobInterviewProcess';
import EditJobSkills from '../../components/dialog/job_edit/EditJobSkills';
import EditJobLocation from '../../components/dialog/job_edit/EditJobLocation';

import locationIcon from '../../assets/images/location_icon_2.svg';
import fbIcon from '../../assets/images/fb_icon_2.svg';

import workEnv from '../../assets/images/work_envirment_image.svg';
import flexableHour from '../../assets/images/flexable_hour_image_2.svg';
import careerImage from '../../assets/images/career_advancement_image.svg';

import pto from '../../assets/images/unlimited_pto_icon.svg';
import bonus from '../../assets/images/employee_bonuses_icon.svg';
import medical from '../../assets/images/medical_insurance_icon.svg';
import educationIcon from '../../assets/images/education_asistance_icon.svg';
import editIcon from '../../assets/images/edit_icon_1.svg';
import employer from '../../assets/images/tooth.png';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Profile from '../profile/Profile';

const JobPostingContent = (props) => {
    const [jobInfoEdit, setJobInfoEdit] = useState(false);
    const [jobCertificatesEdit, setJobCertificatesEdit] = useState(false);
    const [jobSoftwaresEdit, setJobSoftwaresEdit] = useState(false);
    const [jobDentalToolsEdit, setJobDentalToolsEdit] = useState(false);
    const [jobWorkDaysEdit, setJobWorkDaysEdit] = useState(false);
    const [jobInterviewProcessEdit, setJobInterviewProcessEdit] = useState(false);
    const [jobSkillsEdit, setJobSkillsEdit] = useState(false);
    const [previewData, setPreveiwData] = useState(false);
    const [jobLocationEdit, setJobLocationEdit] = useState(false);
    const [previewEmployer, setPreveiwEmployer] = useState();
    const [jobDetails, setJobDetail] = useState('');
    const userProfile = getLoggedUser();

    const [jobId, setJobId] = useState();
    const [jobData, setJobData] = useState({
        position: '',
        skills: [],
        minimumEducation: '',
        licenseOrCertificates: {
            certificateRequired: true,
            certificates: []
        },
        minimumExperience: 0,
        jobType: '',
        softwaresExperience: [],
        dentalToolsExperience: [],
        jobLocation: '',
        workDays: [],
        sponserVisa: '',
        languages: {
            otherThenEnglish: true,
            languages: []
        },
        salaryRange: {
            type: 'Hourly',
            min: 0,
            max: 0
        },
        interviewProcess: []
    });

    const toast = useContext(ToastContext);
    const userToken = getUserToken();

    const activeEmployerData = (data) => {
        if (data) setPreveiwData(true);
        setPreveiwEmployer(data);
    };

    const jobDetail = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/job/${props?.jobID}`, {
            method: 'get',
            headers: {
                'x-access-token': userToken
            }
        });

        if (response.status === 200) {
            const job = response.data.data;
            setJobDetail(response.data.data || []);
            setJobData({
                position: job?.position,
                skills: job?.skills,
                minimumEducation: job?.minimumEducation,
                licenseOrCertificates: {
                    certificateRequired: job?.licenseOrCertificates.certificateRequired,
                    certificates: job?.licenseOrCertificates.certificates
                },
                minimumExperience: job?.minimumExperience,
                jobType: job?.jobType,
                softwaresExperience: job?.softwaresExperience,
                dentalToolsExperience: job?.dentalToolsExperience,
                jobLocation: job?.jobLocation,
                workDays: job?.workDays,
                sponserVisa: job?.sponserVisa,
                languages: {
                    otherThenEnglish: job?.languages.otherThenEnglish,
                    languages: job?.languages.languages
                },
                salaryRange: {
                    type: job?.salaryRange.type,
                    min: job?.salaryRange.min,
                    max: job?.salaryRange.max
                },
                interviewProcess: job?.interviewProcess
            });
            setJobId(job._id);
        } else showErrorToast(toast, 'Error', response?.data?.decs);
    };
    useEffect(() => {
        if (props?.employer) {
            setJobDetail(props.employer);
        } else {
            if (props?.jobID === '123') {
                setJobDetail(props?.jobData || []);
            } else {
                jobDetail();
            }
        }
    }, [props?.employer]);

    const images = [medical, tooth, educationIcon, pto, bonus, lifeInsurance];
    const mostValueImages = [workEnv, careerImage, flexableHour];

    const getImageForBenefit = (index) => {
        return images[index % images.length];
    };
    const getImageForMostValue = (index) => {
        return mostValueImages[index % mostValueImages.length];
    };

    const customizedMarker = (item, index) => {
        return (
            <span className="interview-process-marker flex justify-content-center align-items-center">
                <i className={item.icon}>{index + 1}</i>
            </span>
        );
    };
    const socialPath = jobDetails?.job_added_by?.preferences?.employer?.socialAccounts;
    let icons = [
        { icons: fbIcon, color: '#007cc8', profile: socialPath?.Facebook ? socialPath.Facebook : '' },
        {
            icons: twitterIcon,
            color: '#000',
            profile: socialPath?.Twitter ? socialPath?.Twitter : ''
        },
        {
            icons: linkdinIcon,
            color: '#005589',
            profile: socialPath?.Linkedin ? socialPath?.Linkedin : ''
        },
        {
            icons: youtubIcon,
            color: '#F80404',
            profile: socialPath?.google ? socialPath?.google : ''
        },
        {
            icons: instaIcon,
            color: 'linear-gradient(209.08deg, #EA0BAD 8.82%, #691FEE 38.02%, #F6225A 65.17%, #E9B900 87.79%)',
            profile: socialPath?.Instagram ? socialPath.Instagram : ''
        },
        {
            icons: tiktokIcon,
            color: '#000000',
            profile: socialPath?.TikTok ? socialPath?.TikTok : ''
        },
        {
            icons: yelp,
            color: '#bb0000',
            profile: socialPath?.Yelp ? socialPath?.Yelp : ''
        },
        {
            icons: linkedin,
            color: '#007ab9',
            profile: socialPath?.LinkedIn ? socialPath?.LinkedIn : ''
        },
        {
            icons: snapchat,
            color: '#ffc91b',
            profile: socialPath?.Snapchat ? socialPath?.Snapchat : ''
        },
        {
            icons: google,
            color: '#fff',
            profile: socialPath?.Google ? socialPath?.Google : ''
        }
    ];
    return (
        <>
            <div className="col-12 sm:col-12 md:col-4 lg:col-4 pl-0">
                <div className="grid mt-2">
                    <div className="col-4 sm:col-12 md:col-12 lg:col-3 xl:col-4">
                        <span onClick={() => activeEmployerData(jobDetails?.job_added_by)}>
                            <Image
                                src={
                                    props?.jobID
                                        ? userProfile?.role === 'employer'
                                            ? imageDisplay(userProfile?.preferences?.employer?.companyLogo) || employer
                                            : jobDetails?.job_added_by?.preferences?.employer?.companyLogo !== ''
                                            ? imageDisplay(jobDetails?.job_added_by?.preferences?.employer?.companyLogo)
                                            : employer
                                        : imageDisplay(jobDetails?.profileImage) || employer
                                }
                                alt="office logo"
                                className="logo-employee"
                            />
                        </span>
                    </div>
                    <div className="col-8 sm:col-12 md:col-12 lg:col-8 xl:col-8">
                        <h1 className="officeAddress" onClick={() => activeEmployerData(jobDetails?.job_added_by)}>
                            {jobDetails?.job_added_by?.name}
                        </h1>
                        <h1 className="candidate-view-profile-name my-2 pl-2 pr-3 relative" style={{ fontSize: '22px' }}>
                            {`${jobDetails?.position}`}
                            {props?.edit && userProfile?.role !== 'candidate' && (
                                <span className="editcustom">
                                    <Image
                                        src={editIcon}
                                        onClick={() => setJobLocationEdit(true)}
                                        alt="Logo"
                                        style={{ maxWidth: '10px' }}
                                        className="pt-2 ml-3 cursor-pointer ml-0 edit-blue-image"
                                    />
                                </span>
                            )}
                        </h1>

                        <div className="flex ">
                            <Image src={locationIcon} alt="Logo" className="p-0" />
                            <p className="job-card-title-heading my-0  px-3 py-0">{`${jobDetails?.jobLocation}`}</p>
                            {userProfile?.role == 'candidate' && window.location.pathname == '/candidate/batchMatch' && (
                                <p className="text-right pt-3 pr-3 mb-0">
                                    <a href={`/view/employer/profile/${jobDetails?._id}`} target="_blank" style={{ minWidth: '100px' }}>
                                        <Button className="border-0">View</Button>
                                    </a>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap gap-3 mt-2">
                    {icons
                        ?.filter((val) => val.profile !== '')
                        .map((val, i) => (
                            <div className="social-media-icon cursor-pointer" key={i} style={{ background: val?.color }}>
                                <a href={val?.profile} target="_blank">
                                    <Image src={val?.icons} alt="social media icon" />
                                </a>
                            </div>
                        ))}
                </div>
                <hr className="horizontal-line mt-3" />
                <h1 className="candidate-view-profile-name mt-2">
                    Information
                    {props?.edit && userProfile?.role !== 'candidate' && (
                        <span>
                            <Image src={editIcon} alt="Logo" className="pt-2 ml-3 cursor-pointer edit-blue-image" onClick={() => setJobInfoEdit(true)} />
                        </span>
                    )}
                </h1>

                <div className="grid mt-2">
                    {!props?.jobID === '123' && (
                        <>
                            <div className="col-6 office-info-heading">Job Posted:</div>
                            <div className="col-6 office-info-heading-data">{jobDetails?.createdAt ? calculateJobPostTime(jobDetails?.createdAt) : ``}</div>
                        </>
                    )}
                    <div className="col-6 office-info-heading">Education:</div>
                    <div className="col-6 office-info-heading-data">{`${
                        Array.isArray(jobDetails?.minimumEducation) ? jobDetails?.minimumEducation[0] : jobDetails?.minimumEducation || 'Abc'
                    }`}</div>
                    <div className="col-6 office-info-heading">Salary</div>
                    <div className="col-6 office-info-heading-data">{`$${formatPrice(jobDetails?.salaryRange?.min)} - $${formatPrice(jobDetails?.salaryRange?.max)}/${
                        jobDetails?.salaryRange?.type
                    }`}</div>
                    <div className="col-6 office-info-heading">Industry</div>
                    <div className="col-6 office-info-heading-data">{`${jobDetails?.position}`}</div>
                    <div className="col-6 office-info-heading">Industry Experience:</div>
                    <div className="col-6 office-info-heading-data">{`${jobDetails?.minimumExperience}`}</div>
                    <div className="col-6 office-info-heading">Looking for:</div>
                    <div className="col-6 office-info-heading-data">{`${jobDetails?.jobType}`}</div>
                    <div className="col-6 office-info-heading">Language Fluency:</div>
                    <div className="col-6 office-info-heading-data specialty">
                        {(jobDetails &&
                            jobDetails?.languages.languages?.map((val, index) => (
                                <p className="speclty" key={index}>
                                    {val} {index !== jobDetails?.languages.languages?.length - 1 && ' / '}
                                </p>
                            ))) ||
                            ''}
                    </div>

                    <div className="col-6 office-info-heading">Visa sponsorship:</div>
                    <div className="col-6 office-info-heading-data">{`${jobDetails?.sponserVisa}`}</div>
                </div>

                <hr className="horizontal-line mt-2" />
                <div onClick={() => activeEmployerData(jobDetails?.job_added_by)} className="cursor-pointer">
                    <h1 className="candidate-view-profile-name mt-2">Benefits </h1>
                    {jobDetails?.job_added_by?.preferences?.employer?.employerOfferBenefits?.length > 0
                        ? jobDetails?.job_added_by?.preferences?.employer?.employerOfferBenefits?.map((benefit, index) => (
                              <div key={index} className="flex justify-content-start align-items-center employee-love-container mt-2">
                                  <Image src={getImageForBenefit(index)} alt="Logo" className="px-2" />
                                  <h1 className="employee-love-container-txt m-0">{benefit}</h1>
                              </div>
                          ))
                        : userProfile?.preferences?.employer?.employerOfferBenefits?.map((benefit, index) => (
                              <div key={index} className="flex justify-content-start align-items-center employee-love-container mt-2">
                                  <Image src={getImageForBenefit(index)} alt="Logo" className="px-2" />
                                  <h1 className="employee-love-container-txt m-0">{benefit}</h1>
                              </div>
                          ))}

                    <hr className="horizontal-line mt-6 " />

                    <h1 className="candidate-view-profile-name mt-5">What Our Employees Love </h1>

                    {jobDetails?.job_added_by?.preferences?.employer?.employerLoveAbout?.length > 0
                        ? jobDetails?.job_added_by?.preferences?.employer?.employerLoveAbout?.map((most, index) => (
                              <div key={index} className="flex justify-content-start align-items-center employee-love-container mt-2">
                                  <Image src={getImageForMostValue(index)} alt="Logo" className="px-2" />
                                  <h1 className="employee-love-container-txt m-0 pl-3">{most}</h1>
                              </div>
                          ))
                        : userProfile.preferences?.employer?.employerLoveAbout?.map((most, index) => (
                              <div key={index} className="flex justify-content-start align-items-center employee-love-container mt-2">
                                  <Image src={getImageForMostValue(index)} alt="Logo" className="px-2" />
                                  <h1 className="employee-love-container-txt m-0 pl-3">{most}</h1>
                              </div>
                          ))}
                </div>
            </div>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 flex flex-wrap candidate-view-profile-right-container mt-2">
                <div className="w-full mt-3 mx-2" style={{ background: '#F7FAFC', borderRadius: '10px' }}>
                    <div className="heading-container-employee-2 mt-1">
                        <h1 className="heading-container-text-2 my-3">Required Skills</h1>
                        {props?.edit && userProfile?.role !== 'candidate' && (
                            <Image
                                src={editIcon}
                                onClick={() => {
                                    setJobSkillsEdit(true);
                                }}
                                alt="Logo"
                                className="pt-2 mr-3 cursor-pointer"
                            />
                        )}
                    </div>
                    <div className="work-history">
                        <div className="flex flex-wrap justify-content-start gap-5 m-3">
                            {jobDetails?.skills?.map((skill, index) => (
                                <h3 key={index} className="skill-container m-0">
                                    {skill}
                                </h3>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-full my-3 mx-2" style={{ background: '#F7FAFC', borderRadius: '10px' }}>
                    <div className="heading-container-employee-2 mt-5">
                        <h1 className="heading-container-text-2 my-3">Licenses and Certificates</h1>
                        {props?.edit && userProfile?.role !== 'candidate' && (
                            <Image
                                src={editIcon}
                                onClick={() => {
                                    setJobCertificatesEdit(true);
                                }}
                                alt="Logo"
                                className="pt-2 mr-3 cursor-pointer"
                            />
                        )}
                    </div>
                    <div className="work-history">
                        <div className="flex flex-wrap justify-content-start gap-5 m-3">
                            {jobDetails?.licenseOrCertificates?.certificates?.map((licence, index) => (
                                <h3 key={index} className="skill-container m-0">
                                    {licence}
                                </h3>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="w-full my-3 mx-2" style={{ background: '#F7FAFC', borderRadius: '10px' }}>
                    <div className="heading-container-employee-2 mt-5">
                        <h1 className="heading-container-text-2 my-3">Required Dental Tools</h1>
                        {props?.edit && userProfile?.role !== 'candidate' && (
                            <Image
                                src={editIcon}
                                onClick={() => {
                                    setJobDentalToolsEdit(true);
                                }}
                                alt="Logo"
                                className="pt-2 mr-3 cursor-pointer"
                            />
                        )}
                    </div>
                    <div className="work-history">
                        <div className="flex flex-wrap justify-content-start gap-5 m-3">
                            {jobDetails?.dentalToolsExperience?.map((tool, index) => (
                                <h3 key={index} className="skill-container m-0">
                                    {tool}
                                </h3>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="w-full my-3 mx-2" style={{ background: '#F7FAFC', borderRadius: '10px' }}>
                    <div className="heading-container-employee-2 mt-5">
                        <h1 className="heading-container-text-2 my-3">Required Computer Software</h1>
                        {props?.edit && userProfile?.role !== 'candidate' && (
                            <Image
                                src={editIcon}
                                onClick={() => {
                                    setJobSoftwaresEdit(true);
                                }}
                                alt="Logo"
                                className="pt-2 mr-3 cursor-pointer"
                            />
                        )}
                    </div>
                    <div className="work-history">
                        <div className="flex flex-wrap justify-content-start gap-5 m-3">
                            {jobDetails?.softwaresExperience?.map((software, index) => (
                                <h3 key={index} className="skill-container m-0">
                                    {software}
                                </h3>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-full my-3 mx-2" style={{ background: '#F7FAFC', borderRadius: '10px' }}>
                    <div className="heading-container-employee-2 mt-5">
                        <h1 className="heading-container-text-2 my-3">Interview Process</h1>
                        {props?.edit && userProfile?.role !== 'candidate' && (
                            <Image
                                src={editIcon}
                                onClick={() => {
                                    setJobInterviewProcessEdit(true);
                                }}
                                alt="Logo"
                                className="pt-2 mr-3 cursor-pointer"
                            />
                        )}
                    </div>
                    <div className="flex flex-wrap justify-content-start mx-3">
                        <Timeline
                            className="customized-timeline"
                            value={jobDetails?.interviewProcess}
                            marker={customizedMarker}
                            layout="horizontal"
                            align="top"
                            content={(item) => item}
                        />
                    </div>
                </div>

                <div className="w-full my-3 mx-2" style={{ background: '#F7FAFC', borderRadius: '10px' }}>
                    <div className="heading-container-employee-2 mt-5">
                        <h1 className="heading-container-text-2 my-3">Preferred Work Days</h1>
                        {props?.edit && userProfile?.role !== 'candidate' && (
                            <Image
                                src={editIcon}
                                onClick={() => {
                                    setJobWorkDaysEdit(true);
                                }}
                                alt="Logo"
                                className="pt-2 mr-3 cursor-pointer"
                            />
                        )}
                    </div>
                    <div className="flex flex-wrap justify-content-start gap-2 m-3">
                        {jobDetails?.workDays?.map((week, index) => (
                            <h3 key={index} className={`${week.active ? 'weekday-container-active m-0' : 'weekday-container m-0'}`}>
                                {week}
                            </h3>
                        ))}
                    </div>
                </div>
            </div>
            {jobInfoEdit && (
                <EditJobInformation
                    visible={jobInfoEdit}
                    setVisible={setJobInfoEdit}
                    jobData={jobData}
                    setJobData={setJobData}
                    jobId={jobId}
                    setJobDetail={setJobDetail}
                />
            )}
            {jobCertificatesEdit && (
                <EditJobCertificates
                    visible={jobCertificatesEdit}
                    setVisible={setJobCertificatesEdit}
                    jobData={jobData}
                    setJobData={setJobData}
                    jobId={jobId}
                    setJobDetail={setJobDetail}
                />
            )}
            {jobSoftwaresEdit && (
                <EditJobSoftwares
                    visible={jobSoftwaresEdit}
                    setVisible={setJobSoftwaresEdit}
                    jobData={jobData}
                    setJobData={setJobData}
                    jobId={jobId}
                    setJobDetail={setJobDetail}
                />
            )}
            {jobDentalToolsEdit && (
                <EditJobDentalTools
                    visible={jobDentalToolsEdit}
                    setVisible={setJobDentalToolsEdit}
                    jobData={jobData}
                    setJobData={setJobData}
                    jobId={jobId}
                    setJobDetail={setJobDetail}
                />
            )}
            {jobWorkDaysEdit && (
                <EditJobWorkDays
                    visible={jobWorkDaysEdit}
                    setVisible={setJobWorkDaysEdit}
                    jobData={jobData}
                    setJobData={setJobData}
                    jobId={jobId}
                    setJobDetail={setJobDetail}
                />
            )}
            {jobLocationEdit && (
                <EditJobLocation
                    visible={jobLocationEdit}
                    setVisible={setJobLocationEdit}
                    jobData={jobData}
                    setJobData={setJobData}
                    jobId={jobId}
                    setJobDetail={setJobDetail}
                />
            )}
            {jobInterviewProcessEdit && (
                <EditJobInterviewProcess
                    visible={jobInterviewProcessEdit}
                    setVisible={setJobInterviewProcessEdit}
                    jobData={jobData}
                    setJobData={setJobData}
                    jobId={jobId}
                    setJobDetail={setJobDetail}
                />
            )}
            {jobSkillsEdit && (
                <EditJobSkills
                    visible={jobSkillsEdit}
                    setVisible={setJobSkillsEdit}
                    jobData={jobData}
                    setJobData={setJobData}
                    jobId={jobId}
                    setJobDetail={setJobDetail}
                />
            )}

            <Dialog visible={previewData} style={{ width: '90vw' }} maximizable modal={false} className="dialog-box signup-view" onHide={() => setPreveiwData(false)}>
                <Profile signupData={previewEmployer} />
            </Dialog>
        </>
    );
};

export default JobPostingContent;
