import React, { useState } from 'react';

import JobPostScreenTwelve from '../../components/dialog/job_posting/JobPostScreenTwelve';
import JobPostingContent from './JobPostingContent';

const JobPostingPreview = (props) => {
    const [visibleScreenTwelve, setVisibleScreenTwelve] = useState(false);
    const [jobData, setJobData] = useState({
        position: '',
        skills: [],
        minimumEducation: '',
        licenseOrCertificates: {
            certificateRequired: true,
            certificates: []
        },
        minimumExperience: 0,
        jobType: '',
        softwaresExperience: [],
        dentalToolsExperience: [],
        jobLocation: '',
        workDays: [],
        sponserVisa: '',
        languages: {
            otherThenEnglish: true,
            languages: []
        },
        salaryRange: {
            type: 'Hourly',
            min: 0,
            max: 0
        },
        interviewProcess: []
    });

    // -------------------------------------
    return (
        <div className="grid mx-3 my-3 md:my-3 lg:ml-3 lg:my-6">
            <JobPostingContent
                jobID={props?.jobID}
                companylogo={props?.companyAbout}
                employer={props?.employer}
                isShortListed={props?.isShortListed}
                edit={props?.edit}
                jobData={props?.jobData}
            />
            {!props?.jobID && !props?.edit && <JobPostScreenTwelve visible={visibleScreenTwelve} setJobData={setJobData} setVisible={setVisibleScreenTwelve} />}{' '}
        </div>
    );
};

export default JobPostingPreview;
