import ReactGA from 'react-ga4';

const TRACKING_ID = process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID || 'G-FX0RWFZC40'; // your Measurement ID

export const initializeAnalytics = () => {
    if (!window.GA_INITIALIZED) {
        ReactGA.initialize(TRACKING_ID);
        window.GA_INITIALIZED = true;
    }
};

export const sendPageView = (page, title) => {
    ReactGA.send({
        hitType: 'pageview',
        page: page,
        title: title
    });
};
