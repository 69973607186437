import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenEleven = ({ candidateData, setCandidateData }) => {
    const [selectedEducationRequirments, setselectedEducationRequirments] = useState();

    const educationReq = ['High School Diploma / GED', 'Vocational Certificate', 'Some College', 'AA Degree', 'BS/BA Degree', 'MS/MBA Degree', 'Doctorate'];

    useEffect(() => {
        setselectedEducationRequirments(candidateData?.education);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">What is your highest level of education?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {educationReq?.map((requirement, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 ${
                            selectedEducationRequirments === requirement
                                ? 'available-questions-options-select cursor-pointer'
                                : 'available-questions-options cursor-pointer'
                        }`}
                        onClick={() => {
                            setselectedEducationRequirments(requirement);
                            setCandidateData({ ...candidateData, education: requirement });
                        }}
                    >
                        <div
                            className={`${selectedEducationRequirments === requirement ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}
                        >
                            {requirement}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenEleven;
