import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenSix = ({ candidateData, setCandidateData }) => {
    const [selectedJobLocated, setselectedJobLocated] = useState([]);

    const jobLocated = ['San Francisco Bay Area', 'Greater Sacramento Area', 'Greater Los Angeles Area', 'Greater San Diego Area', 'Greater Central Valley Area'];

    const jobLocationHandler = (location) => {
        const updateLocation = selectedJobLocated?.find((el) => el === location);
        if (updateLocation) {
            const selectedLocationfilter = selectedJobLocated.filter((el) => el !== location);
            setselectedJobLocated(selectedLocationfilter);
            setCandidateData({ ...candidateData, jobLocation: selectedLocationfilter });
        } else {
            setselectedJobLocated([...selectedJobLocated, location]);
            setCandidateData({ ...candidateData, jobLocation: [...candidateData.jobLocation, location] });
        }
    };

    const checkLocation = (location) => {
        const indexOfEmployee = selectedJobLocated?.findIndex((el) => el === location);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (candidateData?.jobLocation.length) setselectedJobLocated(candidateData?.jobLocation);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">Where do you want to work?</p>
            <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                {jobLocated?.map((location, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            checkLocation(location) ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => {
                            jobLocationHandler(location);
                        }}
                    >
                        <div className={`${checkLocation(location) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>{location}</div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenSix;
