import React from 'react';
import { Image } from 'primereact/image';

const ReviewCard = (props) => {
    return (
        <div className="flex flex-column p-2 w-full">
            <div className="review-card pl-4 pr-3 py-3" style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: '12px', right: '20px' }}>
                    <Image src={props?.bgImage} width="32px" height="54px" className="review-bg-image" />
                    <Image src={props?.bgImage} width="32px" height="54px" className="review-bg-image ml-2" />
                </div>
                <div className="grid mt-2 w-full review-pic-title">
                    <div className="col-6 lg:col-3 md:col-2 sm:col-2">
                        <Image
                            src={props?.profileImage}
                            className="job-card-profile-image"
                            width="85px"
                            height="85px"
                            alt="Logo"
                            imageStyle={{ borderRadius: '100px' }}
                        />
                    </div>

                    <div className="col-6 lg:col-9 md:col-10 sm:col-10">
                        <div className="job-card-city-heading">{props?.name}</div>
                        <div className="job-card-title-heading mt-2">{props?.jobIndustry}</div>
                    </div>
                </div>
                <p className="review mt-3">{props?.review}</p>
            </div>
        </div>
    );
};

export default ReviewCard;
