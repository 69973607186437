import { Image } from 'primereact/image';

import logo from '../../../assets/images/logo_app_login.svg';
import image1 from '../../../assets/images/login_screen_image_1.png';

import backIcon from '../../../assets/images/onboarding_back_icon.svg';

const LoginLeftSection = (props) => {
    return (
        <div
            className="col-6 sm:col-6 md:col-5 lg:col-5 xl:col-5 overflow-hidden login-left-section"
            style={{
                backgroundImage: `url(${image1})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                padding: 0
            }}
        >
            <Image src={logo} alt="logo" style={{ position: 'absolute', top: 15, left: 15 }} />
            {props?.currentStep !== 0 && (
                <Image
                    src={backIcon}
                    width="36px"
                    height="36px"
                    alt="logo"
                    style={{ position: 'absolute', bottom: 15, right: 15 }}
                    onClick={() => {
                        props?.setCurrentStep(props.currentStep - 1);
                    }}
                    className="cursor-pointer"
                />
            )}
        </div>
    );
};

export default LoginLeftSection;
