import { BrowserRouter } from 'react-router-dom';
import { normalRoutes, protectedRoutes } from './routes/ApplicationRoutes';
import { Suspense, useRef } from 'react';
import { ToastContext } from './context/ToastContext';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Loader from './components/layout/loader/Loader';
import PageNotFound from './pages/page_not_found/PageNotFound';
import { ProtectedRoute } from './routes/ProtectedRoute';
import DashboardContainer from './components/layout/dashboard_container/DashboardContainer';
import HomePage from './components/auths/landing_page/LandingPage';
import Privacy from './components/auths/landing_page/Privacy';
import Terms from './components/auths/landing_page/Terms';
import GoogleAnalytics from './GoogleAnalytics';
import PageViewTracker from './PageViewTracker';

function App() {
    const toast = useRef(null);
    const { isAuthenticate } = useSelector((state) => state.user);

    const renderProtectedRoutes = (routesList) => {
        return (
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <DashboardContainer />
                        </ProtectedRoute>
                    }
                >
                    {routesList.map((e, index) => (
                        <Route key={`route-${index}`} path={e.path} element={<Suspense fallback={<Loader />}>{e.Component}</Suspense>} />
                    ))}
                </Route>

                <Route path="/home" element={<HomePage />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        );
    };

    const renderNormalRoutes = (routesList) => {
        return (
            <Routes>
                {routesList.map((e, index) => (
                    <Route key={`route-${index}`} path={e.path} element={<Suspense fallback={<Loader />}>{e.Component}</Suspense>} />
                ))}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        );
    };

    return (
        <ToastContext.Provider value={toast}>
            <Toast ref={toast} />
            <BrowserRouter>
                <GoogleAnalytics />
                <PageViewTracker />
                {isAuthenticate ? renderProtectedRoutes(protectedRoutes) : renderNormalRoutes(normalRoutes)}
            </BrowserRouter>
        </ToastContext.Provider>
    );
}

export default App;
