import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenSeven = ({ candidateData, setCandidateData }) => {
    const [selectedPreferDays, setselectedPreferDays] = useState([]);

    const preferDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Any'];

    const preferDaysHandler = (day) => {
        const updateDays = selectedPreferDays.find((el) => el === day);
        if (updateDays) {
            const selectedPreferDaysfilter = selectedPreferDays.filter((el) => el !== day);
            setselectedPreferDays(selectedPreferDaysfilter);
            setCandidateData({ ...candidateData, preferWorkDays: selectedPreferDaysfilter.map((el) => el) });
        } else {
            setselectedPreferDays([...selectedPreferDays, day]);
            setCandidateData({ ...candidateData, preferWorkDays: [...candidateData.preferWorkDays, day] });
        }
    };

    const checkPreferDays = (name) => {
        const indexOfEmployee = selectedPreferDays.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setselectedPreferDays([...candidateData?.preferWorkDays]);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">What days do you prefer to work?</p>
            <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                {preferDays?.map((day, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            checkPreferDays(day) ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => preferDaysHandler(day)}
                    >
                        <div className={`${checkPreferDays(day) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>{day}</div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenSeven;
