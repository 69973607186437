import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './loader.css';

const Loader = () => {
    return (
        <div className="loader-container">
            <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="3" animationDuration="1s" />
        </div>
    );
};

export default Loader;
