import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenTen = ({ candidateData, setCandidateData }) => {
    const [selectedOfficeSpecialities, setselectedOfficeSpecialities] = useState([]);

    const officeSpecialities = ['General', 'Pediatric', 'Orthodontic', 'Cosmetic', 'Periodontal', 'Endodontic', 'Oral & Maxillofacial Surgery', 'Prosthodontic', 'None'];

    const specialitiesHandler = (speciality) => {
        const updateSelectedOfficeSpecialities = selectedOfficeSpecialities.find((el) => el === speciality);
        if (updateSelectedOfficeSpecialities) {
            const selectedOfficeSpecialitiesfilter = selectedOfficeSpecialities.filter((el) => el !== speciality);
            setselectedOfficeSpecialities(selectedOfficeSpecialitiesfilter);
            setCandidateData({ ...candidateData, specialtyOfExperience: selectedOfficeSpecialitiesfilter.map((el) => el) });
        } else {
            setselectedOfficeSpecialities([...selectedOfficeSpecialities, speciality]);
            setCandidateData({ ...candidateData, specialtyOfExperience: [...candidateData.specialtyOfExperience, speciality] });
        }
    };

    const checkSpecialities = (name) => {
        const indexOfEmployee = selectedOfficeSpecialities.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setselectedOfficeSpecialities([...candidateData?.specialtyOfExperience]);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">Which dental specialty do you have experience working in?</p>

            <h1 className="job-posting-select-all-text mt-2 mb-0 cursor-pointer">Select all that apply</h1>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {officeSpecialities?.map((speciality, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center  gap-3 cursor-pointer ${
                            checkSpecialities(speciality) ? 'available-questions-options-select' : 'available-questions-options'
                        }`}
                        onClick={() => specialitiesHandler(speciality)}
                    >
                        <div className={`${checkSpecialities(speciality) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {speciality}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenTen;
