import React from 'react';
import { Image } from 'primereact/image';
import location from '../../assets/images/location_icon.svg';
import { getLoggedUser } from '../../helpers/utils';

const RecentCandidateCard = (props) => {
    const userProfile = getLoggedUser();

    return (
        <div className="flex flex-column p-4 my-2 w-full h-full justify-content-between candidate-card">
            <Image
                src={props?.candidateImage}
                className="flex align-items-center justify-content-center recent-candidate-card-profile"
                alt="Logo"
                id={!userProfile && 'CandidateProfileImage'}
            />
            <h1 className="job-card-city-heading text-center mt-3 mb-0">{props?.designation}</h1>

            <div className="flex align-items-center justify-content-center">
                <h2 className="job-card-title-heading text-center">
                    <span className="mr-2">
                        <Image src={location} alt="Logo" width="13px" height="20px" className="candidate-location" />
                    </span>
                    {props?.location?.slice(0, 3).map((val, index) => (
                        <span>
                            {val} {index !== props?.location?.length - 1 && '/ '}
                        </span>
                    ))}
                </h2>
            </div>

            <div className="flex justify-content-between mt-3">
                <div className="job-card-last-online ">{props?.lastActive}</div>
                <div className="job-card-response ">{props?.response}</div>
            </div>
        </div>
    );
};

export default RecentCandidateCard;
