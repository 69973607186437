import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';

import LoginLeftSection from './LoginLeftSection';
import LoginScreen from './LoginScreen';
import ForgetPasswordScreen from './ForgetPasswordScreen';
import VerifyEmailScreen from './VerifyEmailScreen';
import ResetPasswordScreen from './ResetPasswordScreen';

import crossIcon from '../../../assets/images/cross_icon_image.png';
import backIcon from '../../../assets/images/onboarding_back_icon.svg';

const AuthScreen = (props) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <LoginScreen currentStep={currentStep} setCurrentStep={setCurrentStep} />;
            case 1:
                return <ForgetPasswordScreen currentStep={currentStep} setCurrentStep={setCurrentStep} setEmail={setEmail} />;
            case 2:
                return <VerifyEmailScreen currentStep={currentStep} setCurrentStep={setCurrentStep} email={email} setToken={setToken} />;
            case 3:
                return <ResetPasswordScreen currentStep={currentStep} setCurrentStep={setCurrentStep} token={token} />;
            default:
                return <LoginScreen />;
        }
    };

    useEffect(() => {}, [currentStep]);

    return (
        <Dialog visible={props?.visible} className="dialog-box" onHide={() => props?.setVisible(false)}>
            <div className="flex dialog-container h-full">
                <LoginLeftSection setVisible={props?.setVisible} login={true} currentStep={currentStep} setCurrentStep={setCurrentStep} />
                <div className="col-6 sm:col-6 md:col-7 lg:col-7 xl:col-7 employer-onboarding-content-container h-full overflow-x-hidden">
                    <div className="flex flex-column p-4 m-4">
                        <Image
                            src={crossIcon}
                            alt="cross icon"
                            width="20px"
                            height="20px"
                            style={{ position: 'absolute', top: 0, right: 0, padding: 10 }}
                            onClick={() => props?.setVisible(false)}
                        />
                        {currentStep !== 0 && (
                            <Image
                                src={backIcon}
                                alt="logo"
                                width="36px"
                                height="36px"
                                style={{ position: 'absolute', top: 5, left: 5 }}
                                onClick={() => {
                                    setCurrentStep(currentStep - 1);
                                }}
                                className="back-image cursor-pointer"
                            />
                        )}

                        {renderStep()}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AuthScreen;
