import React, { useContext, useState } from 'react';

import { Button } from 'primereact/button';
import { Password } from 'primereact/password';

import * as yup from 'yup';
import { Formik } from 'formik';

import { ToastContext } from '../../../context/ToastContext';

import { sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';

const ResetPasswordScreen = (props) => {
    const [loading, setLoading] = useState(false);

    const toast = useContext(ToastContext);

    //Yup Form Schema Validitions
    const schema = yup.object().shape({
        newPassword: yup.string().required('New Password is required!'),
        confirmPassword: yup
            .string()
            .required('Confirm Password is required!')
            .oneOf([yup.ref('newPassword')], 'Passwords must match')
    });

    // Form Intial Values - If its New Form
    const initialValues = {
        newPassword: '',
        confirmPassword: ''
    };

    const handleSubmit = async (values) => {
        setLoading(true);

        const { newPassword } = values;

        const resetPassword = await sendRequest(`${process.env.REACT_APP_API_URL}api/v1/user/resetPassword`, { payload: { token: props.token, password: newPassword } });

        if (resetPassword.status === 200) {
            showSuccessToast(toast, 'Reset Password', resetPassword?.data?.desc);
            props?.setCurrentStep(0);
        } else {
            showErrorToast(toast, 'Reset Password', resetPassword?.data?.desc);
        }
        setLoading(false);
    };

    return (
        <>
            <h1 className="login-welcome-text">Change your password</h1>
            <p className="employer-questions-heading mt-2">Enter your new password and get started!</p>

            <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className="grid mt-5">
                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 flex flex-column">
                                <label htmlFor="newPassword" className="employee-signup-input-label mb-2">
                                    New Password
                                </label>
                                <Password
                                    id="newPassword"
                                    type="password"
                                    name="newPassword"
                                    onChange={handleChange}
                                    value={values.newPassword}
                                    placeholder="*********"
                                    promptLabel="Choose a password"
                                    weakLabel="Too simple"
                                    mediumLabel="Average complexity"
                                    strongLabel="Complex password"
                                    inputClassName="employee-signup-input border-0 w-full xl:w-10"
                                />
                                <div className="error-message mt-1">{touched.newPassword && errors.newPassword && <p className="error">{errors.newPassword}</p>}</div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-12 sm:col-12 flex flex-column">
                                <label htmlFor="confirmPassword" className="employee-signup-input-label mb-2">
                                    Confirm Password
                                </label>
                                <Password
                                    id="confirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    placeholder="*********"
                                    promptLabel="Choose a password"
                                    weakLabel="Too simple"
                                    mediumLabel="Average complexity"
                                    strongLabel="Complex password"
                                    inputClassName="employee-signup-input border-0 w-full xl:w-10"
                                />
                                <div className="error-message mt-1">
                                    {touched.confirmPassword && errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
                                </div>
                            </div>
                        </div>
                        <hr className="line-horizontal mt-3 " />

                        <Button type="submit" className="onboarding-btn mt-4 w-max">
                            Login
                        </Button>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ResetPasswordScreen;
