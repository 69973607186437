import React, { useState, useEffect } from 'react';

const EmployerOnboardingScreenFour = ({ employerData, setEmployerData }) => {
    const [selectedEmployeeCount, setselectedEmployeeCount] = useState({});
    const [selectedOfficeCount, setselectedOfficeCount] = useState({});

    const employeCount = ['1-10', '11-20', '21-50', '51-100', '101-500', '500+'];
    const officesCount = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'];

    useEffect(() => {
        if (employerData.noOfEmployees) {
            setselectedEmployeeCount({ count: employerData.noOfEmployees });
            setselectedOfficeCount({ office: employerData.noOfOffices });
        }
    }, []);

    return (
        <div>
            <p className="employer-questions-heading mt-5">How many employees are in your company?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {employeCount?.map((count, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3  ${
                            selectedEmployeeCount.count === count ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                        }`}
                        onClick={() => {
                            setselectedEmployeeCount({ count });
                            setEmployerData({ ...employerData, noOfEmployees: count });
                        }}
                    >
                        <div className={`${selectedEmployeeCount.count === count ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {count}
                        </div>
                    </div>
                ))}
            </div>

            <p className="employer-questions-heading mt-5">How many offices do you have?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {officesCount?.map((office, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 ${
                            selectedOfficeCount.office === office ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                        }`}
                        onClick={() => {
                            setselectedOfficeCount({ office });
                            setEmployerData({ ...employerData, noOfOffices: office });
                        }}
                    >
                        <div className={`${selectedOfficeCount.office === office ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {office}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmployerOnboardingScreenFour;
