export const positionData = [
    {
        name: 'Front Desk',
        skills: [
            'Greet and welcome patients',
            'Assist patients with filling out forms',
            'Prepare patient charts and daily schedules',
            'Answer phones, photocopy, filing, faxing',
            'Insurance Billing/Verification',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Order Dental/Office Supplies',
            'Respond to Emails',
            'Manage Collections',
            'Sterilize and Disinfect Dental instruments',
            'Set Up Room and Break Down Patient Rooms',
            'Periocharting',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Administrative Assistant',
        skills: [
            'Greet and welcome patients',
            'Assist patients with filling out forms',
            'Prepare patient charts and daily schedules',
            'Answer phones, photocopy, filing, faxing',
            'Insurance Billing/Verification',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Order Dental/Office Supplies',
            'Respond to Emails',
            'Manage Collections',
            'Sterilize and Disinfect Dental instruments',
            'Set Up Room and Break Down Patient Rooms',
            'Periocharting',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Treatment Coordinator',
        skills: [
            'Greet and welcome patients',
            'Assist patients with filling out forms',
            'Prepare patient charts and daily schedules',
            'Answer phones, photocopy, filing, faxing',
            'Insurance Billing/Verification',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Order Dental/Office Supplies',
            'Respond to Emails',
            'Manage Collections',
            'Sterilize and Disinfect Dental instruments',
            'Set Up Room and Break Down Patient Rooms',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'CAD/CAM',
            'CAESY',
            'Dental Vision',
            'Eaglesoft',
            'Easy Dental',
            'Instrumentarium',
            'Microsoft Office Suite',
            'Kodak',
            'Macpractice',
            'Open Dental',
            'Orthotrac',
            'Planmecca',
            'PracticeWorks',
            'Sirona',
            'QSI Dental',
            'SoftDent',
            'Vixwin',
            'Windent Software',
            'Ortho2',
            'Softdent',
            'Dentrix'
        ],
        industryTools: [
            'Carestream',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'CT scanner'
        ]
    },
    {
        name: 'Receptionist',
        skills: [
            'Greet and welcome patients',
            'Assist patients with filling out forms',
            'Prepare patient charts and daily schedules',
            'Answer phones, photocopy, filing, faxing',
            'Insurance Billing/Verification',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Manage Patient Records',
            'Order Dental/Office Supplies',
            'Respond to Emails',
            'Manage Collections',
            'Sterilize and Disinfect Dental instruments',
            'Set Up Room and Break Down Patient Rooms',
            'Periocharting',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Billing Coordinator',
        skills: [
            'Insurance Billing/Verification',
            'Collect Copayments',
            'Schedule Appointments',
            'Check-In/Check Out Patients',
            'Manage Patient Records',
            'Respond to Patient Emails',
            'Follow Up on Non-Paid Insurance Claims',
            'Sterilize and Disinfect Dental Instruments',
            'Set Up Room and Break Down Room Patient Rooms',
            'Explain Insurance Benefits to Patients',
            'Post Payments Made by Patients',
            'Manage Late Payments and Overdue Accounts',
            'Process Insurance Payments and Refunds',
            'Upload Completed Insurance Verification Forms',
            'Answer Phones, Photocopy, Filing, Faxing',
            'Periocharting',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota Cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Office Manager',
        skills: [
            'Insurance Billing/Verification',
            'Manage Office Payroll',
            'General Marketing',
            'Collect Copayments',
            'Schedule Appointments',
            'Check-In/Check Out Patients',
            'Manage Patient Records',
            'Order Dental/Office Supplies',
            'Respond to Emails',
            'Follow Up on Non-Paid Insurance Claims',
            'Sterilize and Disinfect Dental Instruments',
            'Set Up and Break Down Patient Rooms',
            'Explain Insurance Benefits to Patients',
            'Generate Reports for All Activities',
            'Post Payments Made by Patients',
            'Manage Late Payments and Overdue Accounts',
            'Process Insurance Payments and Refunds',
            'Upload Completed Insurance Verification Forms',
            'Staff Employees',
            'Recruit and Interview Potential Candidates',
            'Manage All Internal Office Procedures and Practices',
            'Maintain Staff Schedules',
            'Maintain General Ledger',
            'Maintain Billing System',
            'Respond to Clinical and Operational Issues',
            'Create and Implement Office Budget',
            'Supervise Office Employees',
            'Train New Office Employees',
            'Answer Phones, Photocopy, Filing, Faxing',
            'Periocharting',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota Cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Marketing Coordinator',
        skills: [
            'Manage Marketing Campaigns',
            'Perform Competitive Analysis and Market Research to Identify Industry Trends',
            'Create Marketing Materials and Content',
            'Drive Social Media Strategy',
            'Determine and Manage Advertising Budget',
            'Ensure Brand Compliance Across Internal and External Stakeholders',
            'Familiarity with HubSpot or Similar Marketing Software',
            'Schedule and Attend Relevant Community Events and Trade Shows',
            'Grow the Patient Base',
            'Create Digital Marketing Ads',
            'Familiar with SEO/SEM',
            'Track Marketing Budget Monthly',
            'Track Marketing Pieces and Effectiveness',
            'Website Management',
            'A/B Testing Various Marketing Campaigns',
            'Manage Marketing Staff',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Marketing Manager',
        skills: [
            'Manage Marketing Campaigns',
            'Perform Competitive Analysis and Market Research to Identify Industry Trends',
            'Create Marketing Materials and Content',
            'Drive Social Media Strategy',
            'Determine and Manage Advertising Budget',
            'Ensure Brand Compliance Across Internal and External Stakeholders',
            'Familiarity with HubSpot or Similar Marketing Software',
            'Schedule and Attend Relevant Community Events and Trade Shows',
            'Grow the Patient Base',
            'Create Digital Marketing Ads',
            'Familiar with SEO/SEM',
            'Track Marketing Budget Monthly',
            'Track Marketing Pieces and Effectiveness',
            'Website Management',
            'A/B Testing Various Marketing Campaigns',
            'Manage Marketing Staff',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Marketing Specialist',
        skills: [
            'Perform competitive analysis and market research to identify industry trends',
            'Create marketing materials and content',
            'Drive social media strategy',
            'Determine and manage advertising budget',
            'Manage marketing campaigns',
            'Ensure brand compliance across internal and external stakeholders',
            'Familiarity with hubspot or similar marketing softwares',
            'Schedule and attend relevant community events and trade shows',
            'Grow the patient base',
            'Create digital marketing ads',
            'Familiar with SEO/SEM',
            'Track Marketing Budget monthly',
            'Track marketing pieces and effectiveness',
            'Website Managment',
            'A/B testing various marketing campaigns',
            'Manage marketing staff',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Orthodontic Assistant',
        skills: [
            'Administer X-Rays',
            'Photograph patients’ teeth',
            'Take Dental molds of patients’ teeth for fabrication of customized mouthguards and retainers',
            'Remove and replace wires, coil springs, donuts, and elastic bands',
            'Record treatment information in patient records',
            'Administer X-Rays',
            'Insurance Billing/Verification',
            'Chairside Assistance',
            'Collect copayments',
            'Schedule appointments',
            'Check-In/Check out Patients',
            'Order Dental/Office Supplies',
            'Photograph patients’ teeth',
            'Respond to patient emails',
            'Manage collections',
            'Sterilize and Disinfect Dental instruments',
            'Coronal Polishing',
            'Set Up and Break Down Patient Rooms',
            'Patient Education',
            'Ultrasonic Scaling',
            'Dental Prophylaxis',
            'Four-Handed Dentistry',
            'Periocharting',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Dental Assistant',
        skills: [
            'Prepare Patients for Dental Work',
            'Set Up Instrument Trays, Preparing Materials, and Assist with Dental Procedures',
            'Record Treatment Information in Patient Records',
            'Assist Dental Hygienists with Procedures',
            'Administer X-Rays',
            'Collecting and Recording Medical and Dental Histories and Patient Vital Signs',
            'Provide Postoperative Instructions as Directed by the Dentist',
            'Form Preliminary Impressions for Study Casts and Occlusal Registrations for Mounting Study Casts',
            'Fabricate Temporary Restorations and Custom Impressions from Preliminary Impressions',
            'Insurance Billing/Verification',
            'Chairside Assistance',
            'Collect Copayments',
            'Schedule Appointments',
            'Check-In/Check Out Patients',
            'Order Dental/Office Supplies',
            'Respond to Patient Emails',
            'Manage Collections',
            'Coronal Polishing',
            'Set Up and Break Down Patient Rooms',
            'Patient Education',
            'Sterilize and Disinfect Dental Instruments',
            'Photograph Patients’ Teeth',
            'Ultrasonic Scaling',
            'Dental Prophylaxis',
            'Four-Handed Dentistry',
            'Periocharting',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Registered Dental Assistant',
        skills: [
            'Prepare Patients for Dental Work',
            'Set Up Instrument Trays, Prepare Materials, and Assist with Dental Procedures',
            'Record Treatment Information in Patient Records',
            'Assist Dental Hygienists with Procedures',
            'Administer X-Rays',
            'Collect and Record Medical and Dental Histories and Patient Vital Signs',
            'Provide Postoperative Instructions as Directed by the Dentist',
            'Form Preliminary Impressions for Study Casts and Occlusal Registrations for Mounting Study Casts',
            'Fabricate Temporary Restorations and Custom Impressions from Preliminary Impressions',
            'Insurance Billing/Verification',
            'Chairside Assistance',
            'Collect Copayments',
            'Schedule Appointments',
            'Check-In/Check Out Patients',
            'Order Dental/Office Supplies',
            'Photograph Patients’ Teeth',
            'Respond to Patient Emails',
            'Manage Collections',
            'Sterilize and Disinfect Dental Instruments',
            'Coronal Polishing',
            'Set Up and Break Down Patient Rooms',
            'Patient Education',
            'Ultrasonic Scaling',
            'Dental Prophylaxis',
            'Four-Handed Dentistry',
            'Periocharting',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT Scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmeca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'Adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'Weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    },
    {
        name: 'Dental Hygienist',
        skills: [
            'Clean accretions, calcareous deposits, and stains from teeth and beneath margins of gums',
            'Look for indications of oral cancer',
            'Note and review patient medical history',
            'Examine and locate periodontal recessed gums and other signs of gum disease',
            'Record dental decay and disease for diagnosis and treatment by dentists',
            'Administer X-Rays',
            'Examine gums for signs of disease, sores, and other dental health issues',
            'Maintain a dental hygiene program for patients',
            'Sterilize and Disinfect Dental instruments',
            'Apply fluorides and other dental decay prevention agents',
            'Root Planning',
            'Prepare fillings',
            'Patient Education',
            'Set up and Break down patient rooms',
            'Photograph patients’ teeth',
            'Insurance Billing/Verification',
            'Ultrasonic Scaling',
            'Dental Prophylaxis',
            'Four-Handed Dentistry',
            'Periocharting',
            'Present and sell treatment plans',
            'Collect and post payments'
        ],
        softwares: [
            'ABELDent',
            'axiUm Dental Software',
            'CAD/CAM',
            'CAESY',
            'Carestream',
            'CS SoftDent',
            'Curve Dental',
            'Dental Vision',
            'Dentimax',
            'Dentisoft Office Cloud',
            'Dentrix',
            'Dentrix Enterprise',
            'Eaglesoft',
            'Easy Dental',
            'G Suite',
            'Henry Schein Dentrix Ascend',
            'Henry Schein One',
            'Instrumentarium',
            'Kodak',
            'MacPractice DDS',
            'Macpractice',
            'Microsoft Office Suite',
            'MOGO',
            'Open Dental',
            'Ortho2',
            'Orthotrac',
            'Planet DDS Denticon',
            'Practice Management Software (PMS)',
            'Practice Mojo',
            'PracticeWorks',
            'QSI Dental',
            'RevenueWell',
            'RevenueXL',
            'Sirona',
            'SoftDent',
            'Sota cloud',
            'Vixwin',
            'Windent Software',
            'XLDent',
            'YAPI',
            'Marketing Software'
        ],
        industryTools: [
            'CT scanner',
            'Cerec Restorations',
            'DEXIS',
            'Digital X-Rays',
            'Gendex',
            'Intraoral Camera/Scanner',
            'KaVo',
            'Manual Radiography/X-Rays',
            'NOMAD Pro',
            'ScanX',
            'Schick',
            'VELscope',
            'CBCT',
            'CEREC',
            'Planmecca',
            '3M Clarity Aligners',
            '3Shape Dental System',
            'adit',
            'Angelalign',
            'CADSmile',
            'Carestream Dental',
            'CEREC CAD/CAM Software',
            'Clarity Aligners',
            'ClearCorrect',
            'ClearPath Aligners',
            'ClearX',
            'Dental Wings',
            'DentalDesigner',
            'Dentally',
            'DentalWriter',
            'DentalXChange',
            'Dolphin Imaging',
            'Dovetail',
            'Exocad',
            'Freshworks',
            'Harmony Clear Aligners',
            'HIOSSEN IMPLANT',
            'Hubspot',
            'Invisalign',
            'Lighthouse 360',
            'MTM Clear Aligners',
            'novaDONTICS',
            'OperaDDS',
            'OrthoFX',
            'OrthoSnap',
            'Pipedrive',
            'PROSITES',
            'QuickBooks',
            'Ray America',
            'RECALLMAX',
            'Reveal Clear Aligners',
            'Salesforce',
            'Simpli5',
            'Smart Moves',
            'Sota image',
            'Spark Aligners',
            'Square for Dentists',
            'SureSmile',
            'Tru-Line Clear Aligners',
            'weave',
            'Zendura FLX',
            'Zoho CRM'
        ]
    }
];
