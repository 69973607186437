import React, { useRef, useState, useEffect } from 'react';
import { Image } from 'primereact/image';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import officeAddIcon from '../../../assets/images/office_images_add_icon.svg';

const EmployerOnboardingScreenEleven = ({ employerData, setEmployerData }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const inputRef = useRef(null);

    const handleFileSelect = (e) => {
        setSelectedFiles([...selectedFiles, ...e.target.files]);
        setEmployerData({ ...employerData, officeImages: [...selectedFiles, ...e.target.files] });
    };

    const deleteImage = (index) => {
        selectedFiles.splice(index, 1);
        setSelectedFiles([...selectedFiles]);
        setEmployerData({ ...employerData, officeImages: [...selectedFiles] });
    };

    useEffect(() => {
        if (employerData.officeImages) {
            setSelectedFiles(employerData.officeImages);
        }
    }, []);

    return (
        <div>
            <p className="employer-questions-heading mt-5">Show off your office! You can take photos now, or upload some later</p>

            <div className="flex flex-wrap align-item-center justify-content-start mt-5 mb-2 gap-3">
                {selectedFiles &&
                    selectedFiles?.map((file, index) => (
                        <div
                            style={{
                                backgroundImage: `url(${URL.createObjectURL(file)})`
                            }}
                            key={index}
                            className="employee-office-images"
                        >
                            <Image
                                src={deleteIcon}
                                alt="delete image"
                                width="20px"
                                height="20px"
                                style={{ position: 'absolute', top: 0, right: 0, padding: 10, cursor: 'pointer' }}
                                onClick={() => deleteImage(index)}
                            />
                        </div>
                    ))}

                <div
                    className="employee-office-images-add-container cursor-pointer"
                    onClick={() => {
                        inputRef.current.click();
                    }}
                >
                    <Image src={officeAddIcon} alt="add office image icon" />
                </div>
            </div>

            <input className="hidden" ref={inputRef} accept="image/*" type="file" multiple onChange={handleFileSelect} />
        </div>
    );
};

export default EmployerOnboardingScreenEleven;
