import React, { useContext, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import crossIcon from '../../../assets/images/cross_black_icon.svg';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import officeAddIcon from '../../../assets/images/office_images_add_icon.svg';
import { ToastContext } from '../../../context/ToastContext';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';
import axios from 'axios';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';
import { useLocation } from 'react-router-dom';

const EditEmployerOfficeGallery = (props) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const inputRef = useRef(null);
    const toast = useContext(ToastContext);
    const locations = useLocation();
    const userToken = getUserToken();
    const handleFileSelect = (e) => {
        setSelectedFiles([...selectedFiles, ...e.target.files]);
    };
    const deleteImage = (index) => {
        selectedFiles.splice(index, 1);
        setSelectedFiles([...selectedFiles]);
    };

    const updateProfile = async () => {
        const formDataToSend = new FormData();

        if (selectedFiles.length) {
            selectedFiles.forEach((e) => formDataToSend.append('files', e));
        }
        // Make the Axios POST request
        const profileImageResponse = await axios.post(`${BASE_URL}api/v1/media/upload`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const updatedImages = profileImageResponse.data.data.map((e) => e.path);
        const data = {
            preferences: {
                employer: {
                    officeImages: [...props.myProfile.preferences.employer.officeImages, ...updatedImages]
                }
            },
            ...(locations?.state?.employerId ? { updateUser: locations?.state?.employerId } : {})
        };

        let response = await sendRequest(`${BASE_URL}api/v1/user/updateProfile`, {
            method: 'post',
            payload: data,
            headers: {
                'x-access-token': userToken
            }
        });
        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            props?.userProfile();
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    return (
        <Dialog visible={props?.visible} position={'center'} className="dental-tools-edit" onHide={() => props?.setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Gallery</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => props?.setVisible(false)}
                    />
                </div>

                <p className="employer-questions-heading mt-5">Show off your office! You can take photos now, or upload some later</p>

                <div className="flex flex-wrap align-item-center justify-content-start mt-5 mb-2 gap-3">
                    {selectedFiles &&
                        selectedFiles?.map((file, index) => (
                            <div
                                style={{
                                    backgroundImage: `url(${URL.createObjectURL(file)})`
                                }}
                                key={index}
                                className="employee-office-images"
                            >
                                <Image
                                    src={deleteIcon}
                                    alt="delete image"
                                    width="20px"
                                    height="20px"
                                    style={{ position: 'absolute', top: 0, right: 0, padding: 10, cursor: 'pointer' }}
                                    onClick={() => deleteImage(index)}
                                />
                            </div>
                        ))}

                    <div
                        className="employee-office-images-add-container cursor-pointer"
                        onClick={() => {
                            inputRef.current.click();
                        }}
                    >
                        <Image src={officeAddIcon} alt="add office image icon" />
                    </div>
                </div>

                <hr className="line-horizontal mt-5" />

                <Button
                    type="button"
                    className="onboarding-btn w-max px-6 mt-5"
                    onClick={() => {
                        updateProfile();
                        props?.setVisible(false);
                    }}
                >
                    Save
                </Button>
            </div>
            <input className="hidden" ref={inputRef} type="file" multiple onChange={handleFileSelect} />
        </Dialog>
    );
};

export default EditEmployerOfficeGallery;
