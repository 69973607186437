import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';

import defaultLogo from '../../../assets/images/emp_default_logo.svg';

const CandidateOnboardingScreenSeventeen = ({ candidateData, setCandidateData }) => {
    const [file, setFile] = useState(null);
    const [logoImage, setlogoImage] = useState();
    const [fileName, setFileName] = useState('');

    const onFileUpload = (event) => {
        setFile(event.files[0]);
        setFileName(event.files[0].name);
        setCandidateData({ ...candidateData, profileImage: event.files[0] });
    };

    useEffect(() => {
        if (candidateData.profileImage) {
            setlogoImage(candidateData?.profileImage.objectURL);
        }
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5 mb-1">Upload your profile photo</p>
            <p style={{ color: '#55baf8', maxWidth: '430px' }}>
                <i>Candidates who upload a photo of themselves are 75% more likely to receive a response from an employer</i>
            </p>

            <div className="flex align-item-center justify-content-start flex-column mt-3 mb-2">
                <Image src={logoImage ? logoImage : defaultLogo} alt="Logo" className="employee-company-logo" />

                <FileUpload
                    name="demo"
                    mode="basic"
                    accept="image/*"
                    chooseLabel="Upload Photo"
                    uploadLabel="Upload"
                    cancelLabel="Cancel"
                    className={classNames({ 'p-mr-2 mt-3': true })}
                    onUpload={onFileUpload}
                    onSelect={(e) => {
                        setlogoImage(e.files[0].objectURL);
                        setCandidateData({ ...candidateData, profileImage: e.files[0] });
                    }}
                >
                    {fileName && <span className="p-d-block p-mt-2">Selected file: {fileName}</span>}
                </FileUpload>
            </div>
        </>
    );
};

export default CandidateOnboardingScreenSeventeen;
