import React, { useState, useEffect } from 'react';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import deleteIcon from '../../../assets/images/delete_icon.svg';

import addIcon from '../../../assets/images/add_image.svg';

const EmployerOnboardingScreenSeven = ({ employerData, setEmployerData }) => {
    const [selectedSocialMedia, setSelectedSocialMedia] = useState({ name: 'Facebook', url: 'https://facebook.com/' });
    const [url, seturl] = useState('');
    const [socials, setsocials] = useState([]);

    const socialMedias = [
        { name: 'Facebook', url: 'https://facebook.com/' },
        { name: 'X (Formally Twitter)', url: 'https://twitter.com/' },
        { name: 'Instagram', url: 'https://instagram.com/' },
        { name: 'TikTok', url: 'https://tikTok.com/' },
        { name: 'Snapchat', url: 'https://snapchat.com/' },
        { name: 'LinkedIn', url: 'https://linkedin.com/' },
        { name: 'Google', url: 'https://google.com/' },
        { name: 'Yelp', url: 'https://yelp.com/' }
    ];

    const addSocial = (selectedSocialMedia) => {
        const existObject = socials.find((el) => el.name === selectedSocialMedia.name);

        if (!existObject) {
            setsocials([...socials, selectedSocialMedia]);
            setEmployerData({
                ...employerData,
                socialAccounts: {
                    ...employerData.socialAccounts,
                    [selectedSocialMedia.name === 'X (Formally Twitter)' ? 'Twitter' : selectedSocialMedia.name]: selectedSocialMedia.url
                }
            });
        }
        // seturl('');
    };

    useEffect(() => {
        if (employerData.socialAccounts) {
            const arrayObjects = Object.entries(employerData.socialAccounts).map(([name, url]) => ({ name, url }));
            setsocials(arrayObjects);
        }
    }, [employerData.socialAccounts]);

    const deleteSocial = (name) => {
        const updatedSocialAccounts = { ...employerData.socialAccounts };
        delete updatedSocialAccounts[name];
        setEmployerData({
            ...employerData,
            socialAccounts: updatedSocialAccounts
        });
    };

    return (
        <div>
            <p className="employer-questions-heading mt-5">Enter the links to your public company pages and social media accounts</p>

            <div className="flex align-item-center justify-content-start flex-wrap md:flex-nowrap mt-3 mb-2">
                <Dropdown
                    value={selectedSocialMedia}
                    onChange={(e) => {
                        setSelectedSocialMedia(e.value);
                        seturl('');
                    }}
                    options={socialMedias}
                    optionLabel="name"
                    placeholder={selectedSocialMedia?.name}
                    className="social-media-dropdown"
                />

                <div className="flex align-item-center justify-content-between add-button-container w-full md:ml-4">
                    <InputText
                        placeholder={selectedSocialMedia?.url}
                        value={url}
                        onChange={(e) => {
                            seturl(e.target.value);
                            setSelectedSocialMedia({ ...selectedSocialMedia, url: e.target.value });
                        }}
                        className="border-0 add-button-container-input w-9"
                    />

                    <div
                        className="flex align-item-center cursor-pointer justify-content-between add-button w-2 cursor-pointer mr-1 small-add-button"
                        onClick={() => {
                            if (url !== '') {
                                addSocial(selectedSocialMedia);
                            }
                        }}
                        id={url === '' && 'notallowed-add'}
                    >
                        <Image src={addIcon} alt="add" />
                        <div className={`add-button-text w-max`}>Add</div>
                    </div>
                </div>
            </div>

            {socials?.map((social, index) => (
                <div key={index} className="flex flex-wrap align-items-center justify-content-start relative add-button-container xl:w-10 xl:w-9 mt-3 gap-3">
                    <Button type="button" className="onboarding-btn px-6" style={{ maxWidth: '93px' }}>
                        {social?.name}
                    </Button>
                    <p className="social-active-link ">{social?.url}</p>
                    <p style={{ right: '0' }} className="absolute">
                        <img alt="social links" src={deleteIcon} width="25" height="25" className="cursor-pointer" onClick={() => deleteSocial(social?.name)} />
                    </p>
                </div>
            ))}
        </div>
    );
};

export default EmployerOnboardingScreenSeven;
