import React, { useEffect, useState } from 'react';

import RecentJobsCard from '../../cards/RecentJobsCard';

import job1 from '../../../assets/images/job_image_1.svg';
import profile1 from '../../../assets/images/profile_image_1.svg';
import employer from '../../../assets/images/tooth.png';
import { BASE_URL, calculateLastActive, formatPrice, imageDisplay, sendRequest } from '../../../helpers/utils';
import { useNavigate } from 'react-router-dom';

const RecentJobSection = (props) => {
    const [recentJob, setRecentJobs] = useState('');
    const navigate = useNavigate();

    const recentJobsOpenings = async () => {
        let response;

        response = await sendRequest(`${BASE_URL}api/v1/job/recent`, {
            method: 'get'
        });

        if (response.status === 200) {
            setRecentJobs(response.data.data || []);
        }
    };

    const recentJobs = {
        officeImage: job1,
        profileImage: profile1,
        address: 'Pleasanton, CA',
        jobIndustry: 'Dental Hygienist',
        salary: '$1,000-$7,100/year',
        lastActive: 'Active 6m ago',
        response: '100% Responsive'
    };

    useEffect(() => {
        recentJobsOpenings();
    }, []);

    return (
        <div className="w-full flex flex-column mt-6" style={{ background: '#F7FAFC' }}>
            <div className="secondary-heading mt-2">Recent Job Openings</div>
            <div className="grid mt-5 mx-0 mb-0">
                {recentJob?.length > 0 &&
                    recentJob?.slice(0, 6).map((job, index) => (
                        <div
                            key={index}
                            className="col-12 lg:col-4 md:col-6"
                            onClick={() =>
                                props?.isLogin &&
                                navigate('/view/employer/profile', {
                                    state: { from: 'candidate', jobID: job?._id, applied: false }
                                })
                            }
                        >
                            <RecentJobsCard
                                key={index}
                                officeImage={job?.employer.officeImages?.length > 0 ? imageDisplay(job?.employer.officeImages[0]) : recentJobs.officeImage}
                                profileImage={job?.employer?.companyLogo ? imageDisplay(job?.employer?.companyLogo) : employer}
                                address={job?.jobLocation || recentJobs.address}
                                jobIndustry={job?.position || recentJobs.jobIndustry}
                                salary={
                                    `$${formatPrice(job?.salaryRange?.min) + '-' + '$' + formatPrice(job?.salaryRange?.max) + '/' + job?.salaryRange?.type}` ||
                                    recentJobs.salary
                                }
                                lastActive={job?.employer?.lastLogin ? calculateLastActive(job?.employer?.lastLogin) : recentJobs.lastActive}
                                response={job?.responseRate + '%' + ' Responsive' || recentJobs.response}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default RecentJobSection;
