import React, { useEffect, useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

import { ToastContext } from '../../../context/ToastContext';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';

import crossIcon from '../../../assets/images/cross_black_icon.svg';
import { Dropdown } from 'primereact/dropdown';

const EditJobLocation = ({ visible, setVisible, jobData, setJobData, jobId, setJobDetail }) => {
    const [selectedJobLocated, setselectedJobLocated] = useState();
    const [selectedJobTitle, setselectedJobTitle] = useState(jobData?.position);

    const jobLocated = ['San Francisco Bay Area', 'Greater Sacramento Area', 'Greater Los Angeles Area', 'Greater San Diego Area', 'Greater Central Valley Area'];

    const toast = useContext(ToastContext);
    const userToken = getUserToken();

    const positions = [
        'Front Desk',
        'Administrative Assistant',
        'Treatment Coordinator',
        'Receptionist',
        'Billing Coordinator',
        'Office Manager',
        'Marketing Coordinator',
        'Marketing Manager',
        'Marketing Specialist',
        'Orthodontic Assistant',
        'Dental Assistant',
        'Registered Dental Assistant',
        'Dental Hygienist'
    ];

    const jobInfoUpdate = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/job/update/${jobId}`, {
            method: 'post',
            payload: jobData,
            headers: {
                'x-access-token': userToken
            }
        });
        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            setJobDetail(response.data.data || []);
            setVisible(false);
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    useEffect(() => {
        setselectedJobLocated(jobData.jobLocation);
    }, [jobData?.jobLocation]);

    return (
        <Dialog visible={visible} position={'center'} className="dental-tools-edit" onHide={() => setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Job Details</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => setVisible(false)}
                    />
                </div>
                <div className="col-12 flex flex-column">
                    <p className="employer-questions-heading mb-3"> Job Title</p>
                    <Dropdown
                        options={positions}
                        value={selectedJobTitle}
                        onChange={(e) => {
                            setselectedJobTitle(e.target.value);
                            setJobData({
                                ...jobData,
                                position: e.target.value
                            });
                        }}
                        optionLabel=""
                        placeholder="job Title"
                        className={`circular-slider-dropdown border-0 h-max pl-4 pr-1`}
                        panelClassName="circular-slider-panel"
                    />
                </div>

                <p className="employer-questions-heading mt-5">Where is this position located?</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                    {jobLocated?.map((location, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                selectedJobLocated === location ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => {
                                setselectedJobLocated(location);
                                setJobData({
                                    ...jobData,
                                    jobLocation: location
                                });
                            }}
                        >
                            <div className={`${selectedJobLocated === location ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {location}
                            </div>
                        </div>
                    ))}
                </div>

                <hr className="line-horizontal mt-5" />

                <Button type="button" className="onboarding-btn w-max px-6 mt-5" onClick={jobInfoUpdate}>
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditJobLocation;
