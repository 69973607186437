import React, { useEffect, useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

import { ToastContext } from '../../../context/ToastContext';
import { BASE_URL, getUserToken, sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';

import crossIcon from '../../../assets/images/cross_black_icon.svg';

const EditJobWorkDays = ({ visible, setVisible, jobData, setJobData, jobId, setJobDetail }) => {
    const [selectedWorkDays, setselectedWorkDays] = useState([]);

    const requiredWorkDay = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Flexible'];

    const workDaysHandler = (workDay) => {
        const updateWorkDay = selectedWorkDays.find((el) => el === workDay);
        if (updateWorkDay) {
            const selectedWorkDayfilter = selectedWorkDays.filter((el) => el !== workDay);
            setselectedWorkDays(selectedWorkDayfilter);
            const updateWorkDay = selectedWorkDayfilter.map((el) => el);
            setJobData({ ...jobData, workDays: updateWorkDay });
        } else {
            setselectedWorkDays([...selectedWorkDays, workDay]);
            setJobData({ ...jobData, workDays: [...jobData?.workDays, workDay] });
        }
    };

    const checkWorkDays = (name) => {
        const indexOfEmployee = selectedWorkDays.findIndex((el) => el === name);

        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };

    const toast = useContext(ToastContext);
    const userToken = getUserToken();

    const jobInfoUpdate = async () => {
        let response = await sendRequest(`${BASE_URL}api/v1/job/update/${jobId}`, {
            method: 'post',
            payload: jobData,
            headers: {
                'x-access-token': userToken
            }
        });
        if (response.status === 200) {
            showSuccessToast(toast, 'Success', response?.data?.desc);
            setJobDetail(response.data.data || []);
            setVisible(false);
        } else showErrorToast(toast, 'Error', response?.data?.desc);
    };

    useEffect(() => {
        setselectedWorkDays([...jobData.workDays]);
    }, [jobData.workDays]);

    return (
        <Dialog visible={visible} position={'center'} className="dental-tools-edit" onHide={() => setVisible(false)}>
            <div className="mx-6 my-4">
                <div className="flex justify-content-between align-items-center">
                    <h1 className="batch-match-request-interview-main-heading mt-2">Edit Preferred Work Days</h1>
                    <Image
                        src={crossIcon}
                        alt="cross icon"
                        width="20px"
                        height="20px"
                        className="cursor-pointer flex justify-content-end"
                        style={{ padding: '10px' }}
                        onClick={() => setVisible(false)}
                    />
                </div>

                <p className="employer-questions-heading mt-5">Which days is this person required to work?</p>

                <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                    {requiredWorkDay?.map((workDay, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 ${
                                checkWorkDays(workDay) ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                            }`}
                            onClick={() => workDaysHandler(workDay)}
                        >
                            <div className={`${checkWorkDays(workDay) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>{workDay}</div>
                        </div>
                    ))}
                </div>

                <hr className="line-horizontal mt-5" />

                <Button type="button" className="onboarding-btn w-max px-6 mt-5" onClick={jobInfoUpdate}>
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default EditJobWorkDays;
