import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenFour = ({ candidateData, setCandidateData }) => {
    const [selectedOpinion, setselectedOpinion] = useState('No');
    const sponsorOpinion = ['Yes', 'No'];

    useEffect(() => {
        setselectedOpinion(candidateData.isWorkVisa === true ? 'Yes' : 'No');
    }, []);

    useEffect(() => {
        setCandidateData({ ...candidateData, isWorkVisa: false });
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">Do you require a Visa to work in the US?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                {sponsorOpinion?.map((opinion, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            selectedOpinion === opinion ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => {
                            setselectedOpinion(opinion);
                            setCandidateData({ ...candidateData, isWorkVisa: opinion === 'Yes' ? true : false });
                        }}
                    >
                        <div className={`${selectedOpinion === opinion ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>{opinion}</div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenFour;
