import React, { useState } from 'react';
import { Button } from 'primereact/button';
import OtpInput from 'react-otp-input';

import { useDispatch } from 'react-redux';

import { loginSuccess } from '../../../redux/userSlice';

import { ToastContext } from '../../../context/ToastContext';

import { sendRequest } from '../../../helpers/utils';
import { showErrorToast, showSuccessToast } from '../../../helpers/toast-helper';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

const VerifyEmailScreen = (props) => {
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);

    const toast = useContext(ToastContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNext = async () => {
        setLoading(true);
        setTimeout(async () => {
            if (otp.length === 5) {
                setLoading(true);

                const verifyEmail = await sendRequest(`${process.env.REACT_APP_API_URL}api/v1/user/verifyOtp`, { payload: { email: props?.email, code: otp } });
                if (props?.from === 'onboarding') {
                    if (verifyEmail.status === 200) {
                        const { data: userDetails, token } = verifyEmail.data;
                        showSuccessToast(toast, 'Verify Email', verifyEmail?.data?.desc);
                        props.setVisible(false);
                        props.setCurrentStep(0);
                        dispatch(loginSuccess(userDetails));
                        localStorage.setItem('token', token);
                        localStorage.setItem('userProfile', JSON.stringify(userDetails));
                        if (userDetails.role === 'candidate') {
                            navigate('/search/jobs');
                        } else if (['employer', 'recruiter'].includes(userDetails.role)) {
                            navigate('/');
                        }
                    } else {
                        showErrorToast(toast, 'Verify Email', verifyEmail?.data?.desc);
                    }
                } else {
                    if (verifyEmail.status === 200) {
                        showSuccessToast(toast, 'Verify Email', verifyEmail?.data?.desc);
                        props?.setToken(verifyEmail?.data?.token);
                        props?.setCurrentStep(props?.currentStep + 1);
                    } else {
                        showErrorToast(toast, 'Verify Email', verifyEmail?.data?.desc);
                    }
                }

                setLoading(false);
            }
        }, 7000);
    };

    return (
        <>
            <h1 className="login-welcome-text">Verify Email</h1>
            <p className="employer-questions-heading mt-2">Enter verification code here, sent on your email.</p>
            <div className="grid mt-5 opt-screen">
                <div className="col-12 md:col-12 lg:col-12 sm:col-12 flex flex-column">
                    <label htmlFor="username" className="employee-signup-input-label mb-2">
                        Verification code
                    </label>

                    <OtpInput
                        value={otp}
                        inputStyle="input-code mr-2"
                        onChange={(e) => {
                            setOtp(e);
                        }}
                        style={{ width: '100%' }}
                        numInputs={5}
                        isInputNum={true}
                        hasErrored={true}
                        shouldAutoFocus={true}
                        placeholder="-----"
                        className="otp-input-text"
                    />
                </div>
            </div>
            <hr className="line-horizontal mt-3 " />
            <Button type="button" className="onboarding-btn mt-4 w-max" onClick={handleNext}>
                Next{' '}
                {loading && (
                    <ProgressSpinner style={{ width: '20px', height: '20px' }} className="ml-2" strokeWidth="6" fill="var(--surface-ground)" animationDuration=".5s" />
                )}
            </Button>
        </>
    );
};

export default VerifyEmailScreen;
