import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';

import PaymentPlain from '../job_posting/PaymentPlain';
import crossIcon from '../../../assets/images/cross_icon.svg';
import { BASE_URL, getLoggedUser, isCurrentDateTimeBeforeGivenDateTime, getUserToken, sendRequest } from '../../../helpers/utils';
import { useLocation, useNavigate } from 'react-router-dom';

const Pricing = (props) => {
    const [selectedStage, setselectedStage] = useState({ name: 'monthly' });
    const [selectedPlain, setselectedPlain] = useState();
    const [userData, setUserData] = useState();
    const [isValid, setIsValid] = useState(false);
    const [plans, setPlans] = useState([]);
    const [submit, setsubmit] = useState(false);

    const userProfile = getLoggedUser();
    const userToken = getUserToken();
    const navigate = useNavigate();
    const location = useLocation();

    const paymentPlans = async () => {
        let response;

        response = await sendRequest(`${BASE_URL}api/v1/plan/get`, {
            method: 'get'
        });

        if (response.status === 200) {
            setPlans(response.data.data || []);
        }
    };

    const { setPriceLogin, setVisible } = props;

    const getuserData = async () => {
        let response;
        if (userProfile?.role == 'recruiter') {
            response = await sendRequest(`${BASE_URL}api/v1/user/${userProfile?.addedByEmployer}`, {
                method: 'get',
                headers: {
                    'x-access-token': userToken
                }
            });
        } else {
            response = await sendRequest(`${BASE_URL}api/v1/user`, {
                method: 'get',
                headers: {
                    'x-access-token': userToken
                }
            });
        }

        if (response.status === 200) {
            const myUserData = response.data.data?.subscription;

            setUserData(myUserData);
            if (myUserData?.isSubscribed && isCurrentDateTimeBeforeGivenDateTime(myUserData?.subscriptionExpire)) {
                setIsValid(true);
            }
        }
    };

    useEffect(() => {
        paymentPlans();
        if (userToken && userProfile) getuserData();
    }, []);

    const interviewStage = [
        {
            name: 'monthly'
        },
        {
            name: 'yearly'
        }
    ];

    return (
        <Dialog
            visible={props?.visible}
            className="dialog-box-3"
            modal={true}
            onHide={() => {
                setVisible(false);
                setPriceLogin(false);
            }}
        >
            <div className="flex dialog-container h-full overflow-hidden">
                <Image
                    src={crossIcon}
                    alt="cross icon"
                    width="20px"
                    height="20px"
                    className="cursor-pointer"
                    style={{ position: 'absolute', top: 0, right: 0, padding: 10 }}
                    onClick={() => {
                        if (location?.state?.draft) {
                            navigate(-1);
                        } else setVisible(false);
                        setPriceLogin(false);
                    }}
                />
                <div className="w-full employer-onboarding-content-container h-full overflow-x-hidden">
                    <div className="flex flex-column p-2 m-2">
                        {!submit && (
                            <>
                                <div className="flex flex-wrap justify-content-center">
                                    <div
                                        className="flex flex-wrap justify-content-between align-items-center my-3 cursor-pointer"
                                        style={{ background: '#fff', borderRadius: '20px', border: '1px solid #c1c1c1' }}
                                    >
                                        {interviewStage?.map((stage, index) => (
                                            <div
                                                key={index}
                                                className={`${
                                                    (selectedStage?.name === stage.name && 'onboarding-btn w-max') || 'interview-tracker-in-active-phase-2 w-max'
                                                }`}
                                                onClick={() => setselectedStage({ name: stage.name })}
                                                style={{ borderRadius: '20px' }}
                                            >
                                                {stage.name} Offers
                                                {stage.name === 'yearly' && <div className="discount-div p-1 ml-2">20% OFF</div>}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <h3 className="payment-plain-text-2 my-2 text-center">No Added Hiring Fees. Payments auto-renew monthly or yearly. Cancel at Anytime!</h3>

                                <div className="grid mt-5" id={isValid && 'userSubscribed'}>
                                    {plans
                                        ?.filter((plan) => plan?.category === selectedStage?.name)
                                        ?.map((data, index) => (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    if (userProfile) {
                                                        setselectedPlain(data._id);
                                                        setIsValid(false);
                                                    } else {
                                                        setPriceLogin(true);
                                                        setVisible(false);
                                                    }
                                                }}
                                                className={`col-12 sm-col-6 md:col-4 lg:col-4 plan-card`}
                                            >
                                                <PaymentPlain
                                                    selectedStage={selectedStage.name}
                                                    data={data}
                                                    plans={plans?.filter((plan) => plan?.category === 'monthly')?.map((plan) => plan)}
                                                    setselectedPlain={setselectedPlain}
                                                    setJobData={setselectedPlain}
                                                    selectedPlain={selectedPlain}
                                                    from={'home'}
                                                    index={index}
                                                    setUserData={setUserData}
                                                    userData={userData}
                                                    setPriceLogin={setPriceLogin}
                                                    setVisible={setVisible}
                                                    getuserData={getuserData}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default Pricing;
