import React from 'react';
import { Carousel } from 'primereact/carousel';
import ReviewCard from '../../cards/ReviewCard';
import profile1 from '../../../assets/images/review_profile_1.svg';
import sophianguyen from '../../../assets/images/sophianguyen.jpg';
import rebecca from '../../../assets/images/rebecca.jpg';
import emily from '../../../assets/images/emily.jpg';
import kevin from '../../../assets/images/kevin.jpg';
import sarah from '../../../assets/images/sarah.jpg';
import jessica from '../../../assets/images/jessica.jpg';
import daniel from '../../../assets/images/daniel.jpg';
import amanda from '../../../assets/images/amanda.jpg';
import mia from '../../../assets/images/mia.jpg';
import reviewBgImg from '../../../assets/images/review_bg_img.svg';

const ReviewsSection = () => {
    const responsiveOptions = [
        {
            breakpoint: '1236px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '850px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const recentJobs = [
        {
            profileImage: sophianguyen,
            bgImage: reviewBgImg,
            name: 'Dr. Sophia Nguyen',
            jobIndustry: 'Orthodontist in San Francisco',
            review: `Batch made my hiring process a breeze! I found a highly skilled Ortho Assistant for my practice quickly, and the fact that there's no need for resumes is a game-changer.`
        },
        {
            profileImage: rebecca,
            bgImage: reviewBgImg,
            name: 'Rebecca Martinez,',
            jobIndustry: 'Dental Recruiter in Sacramento',
            review: `Batch has transformed our recruitment efforts. It's efficient and reliable, helping us discover top talent effortlessly for our large dental group.`
        },
        {
            profileImage: emily,
            bgImage: reviewBgImg,
            name: 'Emily Chen',
            jobIndustry: 'Dental Assistant in San Jose',
            review: `Thanks to Batch, I landed my dream dental assistant job swiftly. The platform is user-friendly, and I didn't need a resume to get hired.`
        },
        {
            profileImage: kevin,
            bgImage: reviewBgImg,
            name: 'Kevin Jackson',
            jobIndustry: 'Dental Hygienist in Oakland',
            review: `Batch is amazing! I found a fantastic dental hygiene job within days of joining. No fake listings, just real opportunities`
        },
        {
            profileImage: sarah,
            bgImage: reviewBgImg,
            name: 'Sarah Wilson',
            jobIndustry: 'Office Manager in Pleasanton',
            review: `Batch helped me hire the perfect candidate quickly. The simple profile creation and job posting features saved me time, and it's all genuine.`
        },
        {
            profileImage: jessica,
            bgImage: reviewBgImg,
            name: 'Jessica Turner',
            jobIndustry: 'Treatment Coordinator in Santa Rosa',
            review: `Batch is a job-seeker's dream. I found my ideal role effortlessly, and the quick setup made the process a breeze.`
        },
        {
            profileImage: daniel,
            bgImage: reviewBgImg,
            name: 'Daniel Diaz-Miller',
            jobIndustry: 'Office Manager in Los Altos',
            review: `Batch is a trustworthy platform. I secured a great job in no time, and the absence of fake listings and resumes is a huge plus.`
        },
        {
            profileImage: amanda,
            bgImage: reviewBgImg,
            name: 'Amanda Lewis',
            jobIndustry: 'Retail-to-Dental Transitioner in Modesto',
            review: `Switching careers was a breeze with Batch. I now love working in dental, and the platform's simplicity made the transition smooth.`
        },
        {
            profileImage: mia,
            bgImage: reviewBgImg,
            name: 'Mia Patel',
            jobIndustry: 'Billing Specialist in Palo Alto',
            review: `Batch is a game-changer! I landed a fantastic billing specialist job through the platform, and it's all real, no resumes needed.`
        }
    ];
    return (
        <div className="review-section mt-8">
            <div className="reviews-container">
                <div className="w-full flex flex-column mt-5 pb-8 ">
                    <h2 className="secondary-heading pt-4 pb-3 sm:pt-8 sm:pb-6" style={{ color: '#fff' }}>
                        What People Are Saying
                    </h2>
                    <div className="grid sm:mt-5 sm:mb-5">
                        <div className="col-12 review-section-carousel">
                            <Carousel
                                indicatorsContentClassName="review-section-indicator"
                                value={recentJobs}
                                numVisible={3}
                                numScroll={1}
                                responsiveOptions={responsiveOptions}
                                itemTemplate={ReviewCard}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewsSection;
