import React, { useEffect } from 'react';
import './headerLanding.css';
import { Image } from 'primereact/image';
import logo from '../../../assets/images/dental_batch_white_logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useState } from 'react';
import Pricing from '../../dialog/pricing/Pricing';
import { getLoggedUser } from '../../../helpers/utils';

const LandingPageHeader = (props) => {
    const [visiblePricing, setVisiblePricing] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 768); // Adjust the breakpoint as needed
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (location?.state?.draft) setVisiblePricing(true);
    }, [location?.state?.draft]);

    const userProfile = getLoggedUser();
    const navigate = useNavigate();

    return (
        <header className="layout-topbar-landing">
            <div className="flex justify-content-between wrapper" style={{ background: '#854dff' }}>
                <Link className="layout-topbar-landing-logo" to="/">
                    <Image src={logo} alt="Logo" />
                </Link>
                <div className="flex justify-content-start gap-2 header-btns">
                    <Button
                        type="button"
                        className="primary-btn-a px-1 sm:px-1 text-center"
                        onClick={() => {
                            setVisiblePricing(true);
                            if (props?.setPriceLogin) props?.setPriceLogin(false);
                        }}
                        style={mobile ? { background: '#854dff' } : { background: '#854dff', minWidth: '230px' }}
                    >
                        {mobile ? 'Pricing' : 'Pricing for Job Postings'}
                    </Button>
                    <Button
                        type="button"
                        className="primary-btn-a px-4 sm:px-6"
                        onClick={() => {
                            if (!Object.keys(userProfile).length > 0) {
                                props?.setVisible(true);
                            } else if (userProfile.role === 'candidate') {
                                navigate('/search/jobs');
                            } else {
                                navigate('/');
                            }
                        }}
                        style={{ background: '#854dff' }}
                    >
                        {!Object.keys(userProfile).length > 0 ? 'Login' : 'Dashboard'}
                    </Button>
                </div>
            </div>
            <Pricing visible={visiblePricing} setVisible={setVisiblePricing} setPriceLogin={props?.setPriceLogin} />
        </header>
    );
};
export default LandingPageHeader;
