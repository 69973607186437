import React, { Suspense } from 'react';
import JobPostingShare from '../pages/job_post/JobPostingShare';
import Success from '../pages/stripe/Success';
import { getLoggedUser } from '../helpers/utils';
import Privacy from '../components/auths/landing_page/Privacy';
import Terms from '../components/auths/landing_page/Terms';

const DashboardPage = React.lazy(() => import('../pages/dashboard_page/DashboardPage'));
const AdminDashboardPage = React.lazy(() => import('../pages/admin/AdminDashboardPage'));

const HomePage = React.lazy(() => import('../components/auths/landing_page/LandingPage'));
const EmployeeBatchMatch = React.lazy(() => import('../pages/batch_match/EmployeeBatchMatch'));
const CandidateBatchMatch = React.lazy(() => import('../pages/batch_match_candidate/CandidateBatchMatch'));

const EmployeeMessages = React.lazy(() => import('../pages/messages/EmployeeMessages'));
const CandidateProfile = React.lazy(() => import('../pages/search/profile/CandidateProfile'));
const CandidateViewProfile = React.lazy(() => import('../pages/search/profile/CandidateViewProfile'));
const Candidates = React.lazy(() => import('../pages/search/Candidates'));
const Employers = React.lazy(() => import('../pages/search/Employers'));
const Settings = React.lazy(() => import('../pages/settings/Settings'));

const OnboardingPreviewScreen = React.lazy(() => import('../pages/employer/preview_screen/OnboardingPreviewScreen'));
const InterviewTracker = React.lazy(() => import('../pages/interview_tracker/InterviewTracker'));
const InterviewTrackerCandidate = React.lazy(() => import('../pages/interview_tracker/InterviewTrackerCandidate'));

const JobPost = React.lazy(() => import('../pages/job_post/JobPost'));
const PendingCandidates = React.lazy(() => import('../pages/pending_candidates/PendingCandidates'));
const PendingJobs = React.lazy(() => import('../pages/pending_jobs/PendingJobs'));

const AllPayements = React.lazy(() => import('../pages/admin/AllPayements'));
const AllEmployers = React.lazy(() => import('../pages/admin/AllEmployers'));
const AllCandidates = React.lazy(() => import('../pages/admin/AllCandidates'));
const OfficeLocation = React.lazy(() => import('../pages/office_location/OfficeLocation'));
const Recruiters = React.lazy(() => import('../pages/recruiters/Recruiters'));
const Profile = React.lazy(() => import('../pages/profile/Profile'));
const CandidateProfileComponent = React.lazy(() => import('../pages/profile/CandidateProfileComponent'));
const JobPostingPreview = React.lazy(() => import('../pages/job_post/JobPostingPreview'));

const EmployerProfile = React.lazy(() => import('../pages/search/profile/EmployerProfile'));
const userProfile = getLoggedUser();

const Logout = React.lazy(() => import('../pages/logout/Logout'));
export const normalRoutes = [
    {
        path: '/home',
        Component: <HomePage />
    },
    {
        path: '/privacy',
        Component: <Privacy />
    },
    {
        path: '/terms',
        Component: <Terms />
    },
    {
        path: '/',
        Component: <HomePage />
    }
];

export const protectedRoutes = [
    {
        path: '/',
        Component:
            userProfile?.role && userProfile?.role === 'admin' ? (
                <AdminDashboardPage />
            ) : userProfile?.role && (userProfile?.role === 'employer' || userProfile?.role === 'recruiter') ? (
                <DashboardPage />
            ) : (
                <DashboardPage />
            )
    },
    {
        path: '/success',
        Component: <Success />
    },

    {
        path: '/preview/employer',
        Component: <OnboardingPreviewScreen />
    },
    {
        path: '/preview/jobPosting',
        Component: <JobPostingPreview />
    },
    {
        path: '/view/employer/profile',
        Component: <EmployerProfile />
    },

    {
        path: '/user/settings',
        Component: <Settings />
    },
    {
        path: '/search/candidates',
        Component: <Candidates />
    },
    {
        path: '/view/employer/profile/:id',
        Component: <EmployerProfile />
    },
    {
        path: '/search/jobs',
        Component: <Employers />
    },
    {
        path: '/employer/messages',
        Component: <EmployeeMessages />
    },
    {
        path: '/employer/batchMatch',
        Component: <EmployeeBatchMatch />
    },
    {
        path: '/candidate/batchMatch',
        Component: <CandidateBatchMatch />
    },
    {
        path: '/employer/interviewTrackers',
        Component: <InterviewTracker />
    },
    {
        path: '/candidate/interviewTrackers',
        Component: <InterviewTrackerCandidate />
    },
    {
        path: '/employer/jobPost',
        Component: <JobPost />
    },
    {
        path: '/employer/pendingCandidates',
        Component: <PendingCandidates />
    },
    {
        path: '/candidate/pendingJobs',
        Component: <PendingJobs />
    },
    {
        path: '/employer/officeLocations',
        Component: <OfficeLocation />
    },
    {
        path: '/employer/recruiters',
        Component: <Recruiters />
    },
    {
        path: '/employer/profile',
        Component: <Profile />
    },
    {
        path: '/candidate/profile',
        Component: <CandidateProfileComponent />
    },
    {
        path: '/employer/logout',
        Component: <Logout />
    },
    {
        path: '/search/candidates/profile/:id',
        Component: <CandidateProfile />
    },
    {
        path: '/search/candidates/view/profile/:id',
        Component: <CandidateViewProfile />
    },
    // admin routes

    {
        path: '/admin/candidates',
        Component: <AllCandidates />
    },
    {
        path: '/admin/employers',
        Component: <AllEmployers />
    },
    {
        path: '/admin/payments',
        Component: <AllPayements />
    }
];
