import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenNine = ({ candidateData, setCandidateData }) => {
    const [selectedOfficeCount, setselectedOfficeCount] = useState();

    const officesCount = ['0-6 Months', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'];

    useEffect(() => {
        setselectedOfficeCount(candidateData?.totalDentalIndustryExperience);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">How many years of experience do you have in the Dental industry?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {officesCount?.map((office, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            selectedOfficeCount === office ? 'available-questions-options-select' : 'available-questions-options'
                        }`}
                        onClick={() => {
                            setselectedOfficeCount(office);
                            setCandidateData({ ...candidateData, totalDentalIndustryExperience: office });
                        }}
                    >
                        <div className={`${selectedOfficeCount === office ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                            {office}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CandidateOnboardingScreenNine;
