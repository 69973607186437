import { useEffect, useState } from 'react';
import Header from '../../layout/header/LandingPageHeader';

import FooterSection from './FooterSection';
import AuthScreen from '../../dialog/login/AuthScreen';
import EmployerOnboarding from '../../dialog/employer/EmployerOnboarding';

const Terms = () => {
    const [visible, setVisible] = useState(false);

    const [visibleLogin, setVisibleLogin] = useState(false);

    const [priceLogin, setPriceLogin] = useState(false);

    useEffect(() => {
        if (priceLogin) setVisible(true);
    }, [priceLogin]);
    return (
        <>
            <Header setVisible={setVisibleLogin} priceLogin={priceLogin} setPriceLogin={setPriceLogin} />

            <div className="bg-white">
                <div className="wrapper">
                    <h2 className="text-center my-5 py-5">BATCH WEBSITE TERMS OF USE</h2>
                </div>
                <div className="wrapper terms mb-5">
                    {/* <p>Last modified: November 8th, 2023 1.</p> */}
                    <h5> Welcome</h5>
                    <p>
                        Batch Technologies USA INC. is a company registered in the United States with its registered address at 6754 Bernal Ave Ste 740-295, Pleasanton,
                        CA 94566 ("batch", "we", "our", or "us").
                    </p>
                    <p>
                        batch operates the{' '}
                        <a href="https://www.joinbatch.com/" target="_blank">
                            www.joinbatch.com
                        </a>{' '}
                        website, which provides information about our products and services and allows users to contact us and sign up for information and updates about
                        our products and services and to sign up for, or book a demo of, our products and services (the "batch Website").
                    </p>
                    <h5> Your relationship with us </h5>
                    <p>
                        This document and any documents referred to within it (collectively, the "Terms of Use") set out the terms of your relationship with us when you
                        access and use the batch Website. It is important that you read and understand the Terms of Use before using the batch Website.
                    </p>
                    ,
                    <p>
                        By using and accessing the batch Website, you agree to these Terms of Use. If you do not agree to these Terms of Use, please do not use the batch
                        Website.
                    </p>
                    <p>
                        If you sign up for our products or services, we will enter into a separate agreement with you which will set out the terms and conditions upon
                        which you may access and use such products or services.
                    </p>
                    <h5>Information about you</h5>
                    <p>Your privacy is important to us. Please read our Privacy Policy to understand how we collect, use, and share information about you.</p>
                    <h5>Your rights to use the batch Website</h5>
                    <p>
                        The batch Website is intended for business-to-business and professional use and is not suitable for use by individuals in their personal
                        capacities.
                    </p>
                    <p>
                        The materials and content comprising the batch Website belong to us or our third-party licensors, and we hereby give you permission to access,
                        view, and use these materials and content for the sole purpose of using the batch Website in accordance with these Terms of Use.
                    </p>
                    <p>
                        Your right to use the batch Website is personal to you and you are not allowed to give this right to any other person. Your right to use the batch
                        Website does not stop us from giving other people the right to use the batch Website.
                    </p>
                    <ul className="ml-3">
                        Unless allowed by these Terms of Use and as permitted by the functionality of the batch Website, you agree:
                        <ul className="ml-3">
                            <li>not to copy, or attempt to copy any portion of the batch Website;</li>
                            <li>
                                not to give or sell or otherwise make available any portion of the batch Website to anybody else;
                                <li>not to change, or attempt to change any portion of the batch Website in any way; and</li>
                                <li>
                                    not to look for or access the code of any portion of the batch Website that we have not expressly published publicly for general use.
                                </li>
                            </li>
                        </ul>
                    </ul>
                    <p>
                        You agree that all confidential information, copyright, and other intellectual property rights in the batch Website belong to us or the people who
                        have licensed those rights to us.
                    </p>
                    <p>
                        Other than as set out in these Terms of Use, we do not give you any other rights in the batch Website. You agree that you have no rights in or to
                        any portion of the batch Website other than the right to use it in accordance with these Terms of Use. 5.
                    </p>
                    <h5> Your content</h5>
                    <p>
                        You confirm that any images, text, or information that you upload to the batch Website, including any feedback or comments that you submit to us
                        (collectively, your "User Content") will not breach the Rules of Acceptable Use.
                    </p>
                    <p>
                        Our right to use your User Content does not in any way affect your privacy rights. Please see our Privacy Policy which provides information on how
                        we use your personal information.
                    </p>
                    <p>
                        When you upload or post content to the batch Website, you grant us a perpetual, worldwide, non-exclusive, royalty-free, transferable license to
                        use, reproduce, distribute, prepare derivative works of, and display such user-generated content in connection with our products and services.
                    </p>
                    <p>
                        We do not monitor, approve, or take responsibility for content uploaded to the batch Website by other users, including prospective employers and
                        candidates. If you wish to make a complaint about any user content on the batch Website, please contact us at{' '}
                        <a href="mailto:support@joinbatch.com">support@joinbatch.com</a>
                    </p>
                    <h5> Rules of Acceptable Use</h5>
                    <p>
                        In addition to the other requirements within these Terms of Service, section 6 describes specific rules that apply to your use of the batch
                        Website (the "Rules of Acceptable Use").
                    </p>
                    When using the batch Website, you must not:
                    <ul className="ml-5">
                        <li>aggregate, copy, reproduce, distribute, or re-sell any content on the batch Website;</li>
                        <li>give any false or misleading information, impersonate any person, or hide or attempt to hide your identity;</li>
                        <li>
                            act in any way that breaches any applicable local, national, or international law or regulation nor advocate, promote, or engage in any
                            illegal, fraudulent, harmful, indecent, or unlawful conduct (including in your user-generated content), including any criminal activity,
                            abusive, discriminatory, threatening, invasive or offensive behavior, use of harmful, threatening or menacing language or behavior;
                        </li>
                        <li> engage in any behavior that is harmful, defamatory, libelous, obscene, infringing, harassing, or racially or ethnically offensive;</li>
                        <li> transmit any unsolicited advertising such as spam or junk mail. </li>
                    </ul>
                    <h5>Termination</h5>
                    <p>
                        We may end your rights to use the batch Website at any time by contacting you if you have broken these Terms of Use in a serious way. If what you
                        have done can be put right, we will give you a reasonable opportunity to do so.
                    </p>
                    If we end your rights to use the batch Website:
                    <ul className="ml-5">
                        <li>You must stop all activities authorized by these Terms of Use, including your use of the batch Website.</li>
                        <li>
                            You must delete or remove the batch Website from all devices in your possession and immediately destroy all copies of the batch Website that
                            you have and confirm to us that you have done this.
                        </li>
                        <li> We may remotely access your devices remove the batch Website from them and cease providing you with access to the batch Website. </li>
                    </ul>
                    <h5> Disclaimers</h5>
                    <p>
                        The batch Website is provided on an "as is" and "as available" basis without any promises or representations, express or implied. In particular,
                        we do not warrant that the functionality of the batch Website will be uninterrupted or error-free, that defects will be corrected, or that the
                        batch Website or the server that makes it available is free of viruses or other harmful components.
                    </p>
                    <p>
                        To the extent permitted by law, we hereby disclaim all warranties, whether express or implied, including without limitation, warranties of title,
                        fitness for a particular purpose, non-infringement, compatibility, security, and accuracy.
                    </p>
                    <h5> Limitation of Liability</h5>
                    <p>
                        Nothing in these Terms of Use shall exclude or limit our liability for death or personal injury caused by our negligence or for fraud or
                        fraudulent misrepresentation.
                    </p>
                    <p>
                        Subject to the above, we shall not be liable for any indirect or consequential loss or damage, or for any loss of data, profit, revenue, or
                        business, whether direct or indirect, even if we have been advised of the possibility of such damages. In the event that any limitation or
                        exclusion of liability in these Terms of Use is not enforceable, our total liability to you shall not exceed the amount you have paid us to use
                        the batch Website.
                    </p>
                    <h5> General</h5>
                    <p>
                        If any of these Terms of Use are determined to be illegal, invalid, or otherwise unenforceable by reason of the laws of any state or country in
                        which these Terms of Use are intended to be effective, then to the extent and within the jurisdiction in which that term is illegal, invalid, or
                        unenforceable, it shall be severed and deleted from these terms and the remaining terms shall survive, remain in full force and effect and
                        continue to be binding and enforceable.
                    </p>
                    <p>
                        These Terms of Use are governed by the laws of the State of California and the parties agree to submit to the exclusive jurisdiction of the courts
                        of California. For any further information or to request the use of any content, please email{' '}
                        <a href="mailto:support@joinbatch.com">support@joinbatch.com</a>
                    </p>
                </div>
            </div>
            <AuthScreen visible={visibleLogin} setVisible={setVisibleLogin} />
            <EmployerOnboarding visible={visible} setVisible={setVisible} />

            <FooterSection setPriceLogin={setPriceLogin} />
        </>
    );
};

export default Terms;
