import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import Header from '../../layout/header/LandingPageHeader';

import RecentJobSection from './RecentJobSection';
import RecentCandidateSection from './RecentCandidateSection';
import ActiveEmployerSection from './ActiveEmployerSection';
import ReviewsSection from './ReviewsSection';
import FooterSection from './FooterSection';
import EmployerOnboarding from '../../dialog/employer/EmployerOnboarding';
import AuthScreen from '../../dialog/login/AuthScreen';
import CandidateOnboarding from '../../dialog/candidate/CandidateOnboarding';
import Pricing from '../../dialog/pricing/Pricing';
import { getLoggedUser } from '../../../helpers/utils';
import { initializeAnalytics, sendPageView } from '../../../G4A-Package';

const LandingPage = () => {
    const [visible, setVisible] = useState(false);
    const [visibleCandidate, setVisibleCandidate] = useState(false);
    const [visiblePricing, setVisiblePricing] = useState(false);
    const [visibleLogin, setVisibleLogin] = useState(false);
    const [priceLogin, setPriceLogin] = useState(false);

    const userProfile = getLoggedUser();
    useEffect(() => {
        if (priceLogin) setVisible(true);
    }, [priceLogin]);

    useEffect(() => {
        initializeAnalytics();
        sendPageView(window.location.pathname, 'Landing Page');
    }, []);

    return (
        <>
            <Header setVisible={setVisibleLogin} priceLogin={priceLogin} setPriceLogin={setPriceLogin} />
            <div className="bg-white">
                <div className="wrapper">
                    <div className="primary-heading2" style={{ background: '#fff' }}>
                        <h1 className="primary-heading mt-8">
                            A job search <br /> platform for dental industry professionals
                        </h1>
                        {!Object.keys(userProfile).length > 0 && (
                            <div className="flex flex-wrap justify-content-center align-items-center gap-3 sm:mb-4">
                                <Button type="button" className="secondary-btn" onClick={() => setVisibleCandidate(true)}>
                                    I am a Candidate
                                </Button>
                                <Button type="button" className="secondary-btn-a" onClick={() => setVisible(true)}>
                                    I am an Employer
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <RecentJobSection isLogin={Object?.keys(userProfile)?.length > 0} />
            </div>
            <Pricing setPriceLogin={setPriceLogin} visible={visiblePricing} setVisible={setVisiblePricing} />
            <div className="bg-white pb-8">
                <div className="wrapper pb-4">
                    <RecentCandidateSection />
                </div>
            </div>

            <div className="wrapper">
                <ActiveEmployerSection />
            </div>
            <ReviewsSection />
            <FooterSection setPriceLogin={setPriceLogin} />

            <EmployerOnboarding visible={visible} setVisible={setVisible} />
            <AuthScreen visible={visibleLogin} setVisible={setVisibleLogin} />
            <CandidateOnboarding visible={visibleCandidate} setVisible={setVisibleCandidate} />
        </>
    );
};

export default LandingPage;
