import React, { useState, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';

const EmployerOnboardingScreenTen = ({ employerData, setEmployerData }) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (employerData.companyAbout) {
            setValue(employerData.companyAbout);
        }
    }, []);

    return (
        <div>
            <p className="employer-questions-heading mt-5">Add your company’s tagline or mission statement</p>

            <InputTextarea
                className="employe-textarea mt-3 border-0 w-full"
                placeholder="our best life begins with a smile."
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    setEmployerData({ ...employerData, companyAbout: e.target.value });
                }}
                rows={5}
                cols={30}
            />
        </div>
    );
};

export default EmployerOnboardingScreenTen;
