import { createSlice } from '@reduxjs/toolkit';
const myJobData = {
    position: '',
    skills: [],
    minimumEducation: '',
    licenseOrCertificates: {
        certificateRequired: true,
        certificates: []
    },
    minimumExperience: 0,
    jobType: '',
    softwaresExperience: [],
    dentalToolsExperience: [],
    jobLocation: '',
    workDays: [],
    sponserVisa: '',
    languages: {
        otherThenEnglish: true,
        languages: []
    },
    salaryRange: {
        type: 'Hourly',
        min: 0,
        max: 0
    },
    interviewProcess: []
};
const initialState = {
    currentUser: {},
    isAuthenticate: false,
    jobData: myJobData
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.currentUser = action.payload;
            state.isAuthenticate = true;
        },
        logout: (state) => {
            state.currentUser = {};
            state.isAuthenticate = false;
        },
        updateJobData: (state, action) => {
            state.jobData = action.payload;
        },
        resetJobData: (state) => {
            state.jobData = {
                ...myJobData
            };
        }
    }
});

export const { loginSuccess, logout, updateJobData, resetJobData } = userSlice.actions;

export default userSlice.reducer;
