import React, { useState, useEffect } from 'react';
import { Image } from 'primereact/image';

import jobSeeker from '../../../assets/images/job_seeker_image.svg';
import employerImage from '../../../assets/images/employer_image_2.svg';
import tick from '../../../assets/images/tick_icon.svg';
import tooth from '../../../assets/images/tooth_image.svg';
import optometry from '../../../assets/images/optometry_image.svg';
import chiropractic from '../../../assets/images/chiropractic_image.svg';
import { initializeAnalytics, sendPageView } from '../../../G4A-Package';

const EmployerOnboardingOne = ({ employerData, setEmployerData }) => {
    const [selectedIndustry, setselectedIndustry] = useState({});
    const [selectedUser, setselectedUser] = useState({ name: 'Employer', image: employerImage });

    const industries = [
        {
            name: 'Dental',
            image: tooth
        },
        {
            name: 'Optometry',
            image: optometry
        },
        {
            name: 'Chiropractic',
            image: chiropractic
        }
    ];

    const onboardingUser = [
        {
            name: 'Job Seeker',
            image: jobSeeker
        },
        {
            name: 'Employer',
            image: employerImage
        }
    ];
    useEffect(() => {
        setselectedIndustry({ name: employerData.interestedIndustry });
    }, []);

    useEffect(() => {
        initializeAnalytics();
        sendPageView(window.location.pathname, 'Employer Onboarding');
    }, []);

    return (
        <div>
            <div className="flex flex-wrap mt-7 gap-3">
                {onboardingUser?.map((user, index) => (
                    <div
                        key={index}
                        className={`${
                            (selectedUser?.name == user.name &&
                                'flex justify-content-center align-items-center user-selection-container-active cursor-pointer gap-2 w-max mt-2') ||
                            'flex justify-content-center align-items-center user-selection-container-in-active gap-2 w-max mt-2'
                        } `}
                    >
                        <Image src={tick} className={`${(selectedUser?.name == user.name && 'tick-image') || 'hidden'} `} alt="Logo" />

                        <Image src={user.image} alt="Logo" />
                        <h2 className={`${(selectedUser?.name == user.name && 'user-selection-container-active-text') || 'user-selection-container-in-active-text'} `}>
                            {user.name}
                        </h2>
                    </div>
                ))}
            </div>

            <p className="employer-questions-heading mt-5">Which industry are you interested in?</p>

            <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                {industries?.map((industry, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 ${
                            selectedIndustry?.name === industry.name ? 'available-questions-options-select cursor-pointer' : 'available-questions-options cursor-pointer'
                        }`}
                        onClick={() => {
                            if (industry.name === 'Dental') {
                                setselectedIndustry({ name: industry.name });
                                setEmployerData({ ...employerData, interestedIndustry: industry.name, user: selectedUser?.name });
                            }
                        }}
                    >
                        <Image src={industry.image} alt="Logo" className={`${(selectedIndustry?.name === industry.name && 'benefit-image-select') || 'benefit-image'}`} />
                        <div
                            className={`${(selectedIndustry.name === industry.name && 'available-questions-options-select-text') || 'available-questions-options-text'}`}
                        >
                            {industry.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmployerOnboardingOne;
