import React, { useEffect, useState } from 'react';

const CandidateOnboardingScreenThirteen = ({ candidateData, setCandidateData }) => {
    const [selectedOfficeSpecialities, setselectedOfficeSpecialities] = useState([]);
    const [selectedOpinion, setselectedOpinion] = useState('No');

    const officeSpecialities = [
        'Front Desk',
        'Administrative Assistant',
        'Treatment Coordinator',
        'Receptionist',
        'Billing Coordinator',
        'Office Manager',
        'Marketing Coordinator',
        'Marketing Manager',
        'Marketing Specialist',
        'Orthodontic Assistant',
        'Dental Assistant',
        'Registered Dental Assistant',
        'Dental Hygienist'
    ];

    const specialitiesHandler = (speciality) => {
        const updateSelectedOfficeSpecialities = selectedOfficeSpecialities.find((el) => el === speciality);

        if (selectedOpinion === 'No') {
            setselectedOfficeSpecialities([]);
            setCandidateData({ ...candidateData, otherInterestedRoles: ['No'] });
            return; // Exit the function
        }

        if (updateSelectedOfficeSpecialities) {
            const selectedOfficeSpecialitiesfilter = selectedOfficeSpecialities.filter((el) => el !== speciality);
            setselectedOfficeSpecialities(selectedOfficeSpecialitiesfilter);
            setCandidateData({ ...candidateData, otherInterestedRoles: selectedOfficeSpecialitiesfilter.map((el) => el) });
        } else {
            setselectedOfficeSpecialities([...selectedOfficeSpecialities, speciality]);
            setCandidateData({ ...candidateData, otherInterestedRoles: [...candidateData.otherInterestedRoles, speciality] });
        }
    };

    const checkSpecialities = (name) => {
        const indexOfEmployee = selectedOfficeSpecialities.findIndex((el) => el === name);
        if (indexOfEmployee === -1) {
            return false;
        }
        return true;
    };
    useEffect(() => {
        specialitiesHandler('Front Desk');
    }, [selectedOpinion]);

    useEffect(() => {
        setselectedOfficeSpecialities([...candidateData?.otherInterestedRoles]);
    }, []);

    return (
        <>
            <p className="employer-questions-heading mt-5">Are there any other roles you would be interested in?</p>
            <h1 className="job-posting-select-all-text mt-2 mb-0 cursor-pointer">Select all that apply</h1>
            <div className="flex flex-wrap justify-content-start align-items-center mt-3 mb-3 gap-3">
                {['Yes', 'No']?.map((visible, index) => (
                    <div
                        key={index}
                        className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                            selectedOpinion === visible ? 'available-questions-options-select ' : 'available-questions-options'
                        }`}
                        onClick={() => {
                            setselectedOpinion(visible);
                        }}
                    >
                        <div className={`${selectedOpinion === visible ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>{visible}</div>
                    </div>
                ))}
            </div>
            {selectedOpinion == 'Yes' && (
                <div className="flex flex-wrap justify-content-start align-items-center mt-5 mb-3 gap-3">
                    {officeSpecialities?.map((speciality, index) => (
                        <div
                            key={index}
                            className={`flex justify-content-center align-items-center gap-3 cursor-pointer ${
                                checkSpecialities(speciality) ? 'available-questions-options-select' : 'available-questions-options'
                            }`}
                            onClick={() => specialitiesHandler(speciality)}
                        >
                            <div className={`${checkSpecialities(speciality) ? 'available-questions-options-select-text' : 'available-questions-options-text'}`}>
                                {speciality}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default CandidateOnboardingScreenThirteen;
